import React, { useEffect, useMemo, useState } from "react";
import { Grid, Table } from "semantic-ui-react";
import {
  CustomButton,
  CommonTable,
  InputText,
  CommonModal,
} from "../../../components";
import { useForm, SubmitHandler, useFieldArray } from "react-hook-form";

import { SipCol } from "../../../config/constants";

const SipContentModal = ({
  visibleModal,
  setVisibleModal,
  processCIPData,
  getSipData,
}: any) => {
  const values = useMemo(() => {
    return {
      cipData: processCIPData,
    };
  }, [visibleModal]);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: "all",
    defaultValues: values,
    values: values,
  });

  const { fields: cipDetails } = useFieldArray({
    control,
    name: "cipData",
  });
  
  const onSubmit = (data: any) => {
    let dataView = data.cipData.reduce(function (acc: any, obj: any) {
      acc[obj.typeName] = obj.value;
      return acc;
    }, {});
    getSipData([dataView]);
    setVisibleModal(false);
  };

  const loadSipableData = () => {
    return cipDetails.map((data: any, index: any) => {
      return (
        <Table.Row className="tbleR" key={index}>
          <Table.Cell>
            <p>{data.name}</p>
          </Table.Cell>
          <Table.Cell>
            <InputText
              register={register}
              placeholder={data.value}
              name={`cipData.${index}.value`}
              type={(data.name === "Model Number" || data.name === "Manufacturer") ? "text" : "number"}
              valueAsNumber={(data.name === "Model Number" || data.name === "Manufacturer") ? false : true}
            />
          </Table.Cell>
          <Table.Cell>
            <p>{data.unit}</p>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  return (
    <div>
      <CommonModal
        modalOpenState={visibleModal}
        modalCloseState={() => {
          setVisibleModal(false);
          reset();
        }}
        centered={true}
        customDimmer="customDimmerProduct"
        title="Machine: CIP (Cleaning in Place) / SIP (Sterilization in Place)"
      >
        <Grid>
          <Grid.Column computer={16} tablet={16} mobile={16}>
            <CommonTable tableHeaderData={SipCol}>
              {loadSipableData()}
            </CommonTable>
          </Grid.Column>
        </Grid>
        <div className="sipButtonContent">
          <div className="sipCancel">
            <CustomButton
              onClick={() => setVisibleModal(false)}
              title="Cancel"
              theme="green"
              buttonOutLine={true}
            />
          </div>
          <div className="sipAdd">
            <CustomButton
              onClick={handleSubmit(onSubmit)}
              title="Save"
              theme="green"
            />
          </div>
        </div>
      </CommonModal>
    </div>
  );
};

export default SipContentModal;
