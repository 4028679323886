import { CommonModal } from "../../components";
import { useCallback, useContext, useMemo } from "react";
import { DrawingForIngredientContext } from "./DrawingToolForIngredient";
import { nodeType } from "../../config/drawingConstants";
import { InternalTransportBase } from "./UpdateNodes/FarmGate/Transport/InternalTransportBase";
import { TransportBase } from "./UpdateNodes/TransportBase";
import { InternalStorageBase } from "./UpdateNodes/FarmGate/Storage/InternalStorageBase";
import { FarmGateProcessBase } from "./UpdateNodes/FarmGate/Processing/FarmGateProcessBase";
import { FarmGatePackagingBase } from "./UpdateNodes/FarmGate/Packaging/FarmGatePackagingBase";
import { WarehouseBase } from "./UpdateNodes/WarehouseBase";
import { ClientBase } from "./UpdateNodes/ClientBase";
import { CultivarBase } from "./UpdateNodes/Cultivation/CultivarBase";
import { SupplierRawMaterial } from "./UpdateNodes/SupplierRawMaterial";
import { useReactFlow } from "reactflow";
import useWindowDimensions from "../../hooks/windowsSize";
import { ProcessInternalTransportBase } from "./UpdateNodes/ProcessGate/Transport/InternalTransportBase";
import { ProcessInternalStorageBase } from "./UpdateNodes/ProcessGate/Storage/InternalStorageBase";
import { ProcessGateProcessingBase } from "./UpdateNodes/ProcessGate/Processing/ProcessGateProcessBase";
import { ProcessGatePackagingBase } from "./UpdateNodes/ProcessGate/Packaging/ProcessGatePackagingBase";
import { checkNodeModalValidation } from "./utils";
import { errorView } from "../../helpers/ErrorHandler";
import { DISABLE_CLOSE_MODEL_ID } from "../../config/constants";
import {
  getNewDaringItemStatus,
  removeNewDrawingItemStatus,
} from "../../utils/cacheStorage";
import { isEmpty } from "lodash";

interface UpdateSupplierNodeModalProps {}

const UpdateSupplierNodeModal = (props: UpdateSupplierNodeModalProps) => {
  const {
    nodeItemId,
    chartNodes,
    chartEdges,
    supplierIngredientId,
    setNodeItem,
  } = useContext(DrawingForIngredientContext);
  // get chart node item from node id
  const reactFlow = useReactFlow();

  const nodeItem = useMemo(() => {
    const getIconId = nodeItemId?.data?.icon || 0;
    const checkDrawingIsNew = getNewDaringItemStatus();
    if (
      (nodeItemId?.id &&
        DISABLE_CLOSE_MODEL_ID.some((e) => e === getIconId) &&
        !isEmpty(checkDrawingIsNew)) ||
      false
    ) {
      return reactFlow.getNode(nodeItemId?.id || "");
    } else {
      return reactFlow.getNode(nodeItemId || "");
    }
  }, [nodeItemId, reactFlow]);

  const height = useWindowDimensions();

  // Filter the Modal content base on node type
  const nodeTypeContent = useCallback(() => {
    let { status, data, errorMessage } = checkNodeModalValidation(
      nodeItem,
      chartNodes,
      chartEdges,
      supplierIngredientId
    );
    if (!status) {
      setNodeItem(null);
      errorView(errorMessage);
      return;
    }
    switch (nodeItem?.data.icon) {
      case nodeType.Ingredients:
        return <SupplierRawMaterial />;
      case nodeType.Client:
        return <ClientBase modalData={data} />;
      case nodeType.FarmGateInternalTransport:
        return <InternalTransportBase modalData={data} />;
      case nodeType.Transportation:
        return <TransportBase modalData={data} />;
      case nodeType.FarmGateInternalStorage:
        return <InternalStorageBase modalData={data} />;
      case nodeType.FarmGateProcess:
        return <FarmGateProcessBase modalData={data} />;
      case nodeType.FarmGatePacking:
        return <FarmGatePackagingBase modalData={data} />;
      case nodeType.Warehouse:
        return <WarehouseBase />;
      case nodeType.Cultivar:
        return <CultivarBase modalData={data} />;
      case nodeType.ProcessGateInternalTransport:
        return <ProcessInternalTransportBase modalData={data} />;
      case nodeType.ProcessGateInternalStorage:
        return <ProcessInternalStorageBase modalData={data} />;
      case nodeType.ProcessGatePacking:
        return <ProcessGatePackagingBase modalData={data} />;
      case nodeType.ProcessGateProcessing:
        return <ProcessGateProcessingBase modalData={data} />;
      default:
        return null;
    }
  }, [nodeItem]);

  // Filter the Modal header
  const nodeTypeHeader = useMemo(() => {
    switch (nodeItem?.data.icon) {
      case nodeType.Ingredients:
        return "Ingredients";
      case nodeType.FarmGateInternalTransport:
        return "Internal Transportation";
      case nodeType.Transportation:
        return "External Transportation";
      case nodeType.FarmGateInternalStorage:
        return "Internal Storage";
      case nodeType.FarmGateProcess:
        return "Farm Gate Process";
      case nodeType.Warehouse:
        return "External Warehouse";
      case nodeType.Client:
        return "Client";
      case nodeType.FarmGatePacking:
        return "Farm Gate Packaging";
      case nodeType.Cultivar:
        return "Cultivar";
      case nodeType.ProcessGateInternalTransport:
        return "Process Internal Transportation";
      case nodeType.ProcessGateInternalStorage:
        return "Process Internal Storage";
      case nodeType.ProcessGatePacking:
        return "Process Packaging";
      case nodeType.ProcessGateProcessing:
        return "Process Processing";

      default:
        return "";
    }
  }, [nodeItem]);

  return (
    <CommonModal
      modalOpenState={!!nodeItem}
      modalCloseState={() => {
        removeNewDrawingItemStatus();
        setNodeItem(null);
      }}
      centered={true}
      title={`Update The ${nodeTypeHeader}`}
      customDimmer={"custom-dimmer-modal"}
      style={height}
    >
      {nodeTypeContent()}
    </CommonModal>
  );
};

export default UpdateSupplierNodeModal;
