import React, { useState, useEffect, useMemo } from "react";
import { Checkbox, Dimmer, Grid, Loader, Table } from "semantic-ui-react";
import { useForm } from "react-hook-form";

import {
  InputText,
  CustomButton,
  CommonModal,
  TitleView,
} from "../../../components";
import useWindowDimensions from "../../../hooks/windowsSize";

import "./companyDetails.scss";
import { InputNumber } from "../../../components/InputText/InputNumber";
import { useGetCompanyById } from "../../../api/company";

const CompanyDetailsModal = ({ visibleModal, setVisibleModal, id }: any) => {
  const { height, width } = useWindowDimensions();

  //company data load by id
  const {
    data: companyDetails,
    isLoading: companyLoading,
    mutate,
    reset: companyReset,
  } = useGetCompanyById();

  //handle company data input data
  const values = useMemo(() => {
    return {
      name: companyDetails?.name || "",
      address: companyDetails?.address || "",
      companyEmail: companyDetails?.companyEmail || "",
      noOfEmployees: companyDetails?.noOfEmployees || "",
      phone: companyDetails?.phone || "",
      noOfProducts: companyDetails?.noOfProducts || "",
      registrationNumber: companyDetails?.registrationNumber || "",
      website: companyDetails?.website || "",
    };
  }, [companyDetails]);

  //custom hook company visible data
  useEffect(() => {
    if (visibleModal) {
      mutate(id);
    }
  }, [visibleModal]);

  //custom hook company reset
  useEffect(() => {
    return () => {
      reset();
      companyReset();
    };
  }, [visibleModal]);

  const {
    register,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: values,
    values: values,
  });

  //handle loading
  if (companyLoading) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  return (
    <>
      <CommonModal
        modalOpenState={visibleModal}
        modalCloseState={() => {
          setVisibleModal(false);
          reset();
        }}
        centered={true}
        customDimmer="customDimmerProduct"
        title="Company Details"
      >
        <TitleView
          title="Company info"
          CustomTitleViewMain="productRegisterTitle"
        />
        <Grid>
          <Grid.Column computer={16} tablet={16} mobile={16}>
            <>
              <form>
                <div
                  style={{
                    height: height * 0.9 - 180,
                    overflowX: "hidden",
                  }}
                >
                  <Grid>
                    <Grid.Column computer={16} tablet={16} mobile={16}>
                      <Grid>
                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveBottom"
                        >
                          <InputText
                            register={register}
                            errors={errors.name}
                            labelName={"Company Name"}
                            placeholder="Company Name"
                            name="name"
                            required={true}
                            errorMessage={"Please enter user name"}
                            disabled={true}
                          />
                        </Grid.Column>
                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveBottom"
                        >
                          <InputText
                            register={register}
                            errors={errors.address}
                            labelName={"Address"}
                            placeholder="Address"
                            name="address"
                            required={true}
                            errorMessage={"Please enter user name"}
                            disabled={true}
                          />
                        </Grid.Column>

                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveTop"
                        >
                          <InputText
                            register={register}
                            errors={errors.companyEmail}
                            labelName={"Company Email"}
                            placeholder="Company Email"
                            name="companyEmail"
                            required={true}
                            disabled={true}
                          />
                        </Grid.Column>
                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveTop"
                        >
                          <InputText
                            register={register}
                            errors={errors.phone}
                            labelName={"phone"}
                            placeholder="phone"
                            name="phone"
                            required={false}
                            errorMessage={"Please enter name"}
                            disabled={true}
                          />
                        </Grid.Column>
                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveTop"
                        >
                          <InputText
                            register={register}
                            errors={errors.phone}
                            labelName={"Registration Number"}
                            placeholder="Registration Number"
                            name="registrationNumber"
                            required={false}
                            errorMessage={"Please enter name"}
                            disabled={true}
                          />
                        </Grid.Column>
                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveTop"
                        >
                          <InputText
                            register={register}
                            errors={errors.phone}
                            labelName={"Website"}
                            placeholder="Website"
                            name="website"
                            required={false}
                            errorMessage={"Please enter name"}
                            disabled={true}
                          />
                        </Grid.Column>

                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveTop"
                        >
                          <InputNumber
                            transform={{
                              input: (value: any) =>
                                isNaN(value) || value === 0
                                  ? ""
                                  : value.toString(),
                              output: (e: any) => {
                                return e;
                              },
                            }}
                            control={control}
                            errors={errors.noOfEmployees}
                            name="noOfEmployees"
                            labelName={"No Of Employees"}
                            disabled={true}
                          />
                        </Grid.Column>

                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveTop"
                        >
                          <InputNumber
                            transform={{
                              input: (value: any) =>
                                isNaN(value) || value === 0
                                  ? ""
                                  : value.toString(),
                              output: (e: any) => {
                                return e;
                              },
                            }}
                            control={control}
                            errors={errors.noOfProducts}
                            name="noOfProducts"
                            labelName={"No Of Products"}
                            disabled={true}
                          />
                        </Grid.Column>
                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveTop"
                        >
                          <InputText
                            register={register}
                            errors={errors.phone}
                            labelName={"Industry"}
                            placeholder="Industry"
                            name="industry"
                            disabled={true}
                          />
                        </Grid.Column>
                      </Grid>
                    </Grid.Column>
                  </Grid>
                </div>

                <div
                  style={{
                    height: height * 0.1,
                  }}
                  className="modalRegisterButtonMain"
                >
                  <div className="modalRegisterButtonLeft">
                    <CustomButton
                      theme="green"
                      title={"Cancel"}
                      customColumnStyle={"cardInsertSaveOther"}
                      buttonOutLine={true}
                      onClick={() => {
                        setVisibleModal(false);
                        reset();
                      }}
                    />
                  </div>
                </div>
              </form>
            </>
          </Grid.Column>
        </Grid>
      </CommonModal>
    </>
  );
};

export default CompanyDetailsModal;
