import React, { useEffect, useState, useRef } from "react";
import { Accordion, AccordionContent, AccordionTitle, Dimmer, Grid, GridRow, Loader, Table } from "semantic-ui-react";
import { useForm } from "react-hook-form";

import {
  InputText,
  CustomButton,
  CommonTable,
  DropDown,
  TitleView,
  AccordionTitleView,
} from "../../../../../components";
import { MdDeleteOutline, MdModeEditOutline } from "react-icons/md";
import { CultivationPackagingView } from "../../../../../config/constants";
import ConfirmModal from "../../../../../components/confirmViewModal/ConfirmModal";
import {
  useCreatePackaging,
  useDeletePackaging,
  useUpdatePackaging,
} from "../../../../../api/farmGate/supplierPackaging";
import { InputNumber } from "../../../../../components/InputText/InputNumber";
import { successMessage } from "../../../../../helpers/ErrorHandler";

import "./packaging.scss";
import { valueGraterThanZero } from "../../../../../utils/utils";
import _ from "lodash";
import { removeNewDrawingItemStatus, saveNewDrawingItem } from "../../../../../utils/cacheStorage";

const Packaging = ({
  packagingData,
  refetchData,
  packagingType,
  farmGateId,
  packagingId,
  savePackingNodeData,
  packagingTypeData,
  totalWeightOfItem = 0,
  unitSizes,
  palletLength,
  palletWidth,
  changeUnits,
  setIsSaveBtnClicked,
  isSaveBtnClicked,
  setClickedPackagingUnit,
  clickedPackagingUnit,
  packagingName
}: any) => {
  const [enableSizeEditStatus, setEnableSizeEditStatus] = useState<any>(false);
  const [defaultType, setDefaultType] = useState<any>(0);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState<any>({});
  const [packageId, setPackageId] = useState(null);
  const [packageUnitValue, setPackageUnitValue] = useState<any>();
  const [palletSizeWidth, setPalletSizeWidth] = useState<any>();
  const [palletSizeLength, setpalletSizeLength] = useState<any>();
  const [accordionEnabled, setAccordionEnabled] = useState<boolean>(false);

  const lastEditedTextboxRef = useRef<any>(null);

  const {
    register: packaging,
    formState: { errors: errorsUnit },
    handleSubmit: handleSubmitSingleUnit,
    control,
    reset,
    setValue,
  } = useForm();

  useEffect(() => {
    if (getValuesByPackagingType()?.name) {
      if (
        isSaveBtnClicked &&
        getValuesByPackagingType()?.name === clickedPackagingUnit
      ) {
        lastEditedTextboxRef.current = getValuesByPackagingType()?.name;
        scrollToLastEditedTextbox();
        setIsSaveBtnClicked(false);
        setClickedPackagingUnit("baseUnit");
      }
      setValue(`${getValuesByPackagingType()?.name}`, unitSizes);
      if (
        packagingType === "transport-unit" ||
        clickedPackagingUnit === "numCases"
      ) {
        setValue(`palletWidth`, palletWidth);
        setValue(`palletLength`, palletLength);
      }
    }
  }, [unitSizes, palletWidth, palletLength]);
  //custom hook create packaging
  const { isLoading, mutate } = useCreatePackaging();

  //custom hook update packaging
  const { isLoading: isLoadingUpdate, mutate: updatePackaging } =
    useUpdatePackaging();

  //custom hook delete packaging
  const { isLoading: isDeletePackaging, mutate: deletePackage } =
    useDeletePackaging();

  const setUnitSizeUpdate = (data: any) => {
    setValue("packageName", data.packageName);
    setValue("materialWeight", data.materialWeight);
    setValue("materialCost", data.materialCost);
    setDefaultType(data?.packageMaterial || 0);
  };

  //delete package data
  const deletePackageData = (data: any) => {
    const deletePackagingData = {
      packagingType,
      farmGateId,
      packagingId,
      subPackagingId: data._id,
    };
    deletePackage(deletePackagingData, {
      onSuccess() {
        refetchData();
        successMessage("Package deleted successfully");
      },
    });
  };

  const commentResetValue = (statusMessage: any) => {
    reset();
    setDefaultType(0);
    setEnableSizeEditStatus(false);
    successMessage(statusMessage);
    setPackageId(null);
  };

  //api call add to package data
  const addPackageData = (packageData: any) => {
    mutate(packageData, {
      onSuccess: (data) => {
        saveNewDrawingItem("created")
        commentResetValue("Package created successfully");
        if (packagingId) {
          refetchData(packagingId);
          return;
        }
        savePackingNodeData(data.packagingId);
      },
    });
  };

  //api call edit to package data
  const editPackageData = (packageData: any) => {
    updatePackaging(packageData, {
      onSuccess: () => {
        removeNewDrawingItemStatus()
        commentResetValue("Package updated successfully");
        refetchData();
      },
    });
  };

  //handle package data view
  const packageDataInsert = (data: any, status: any) => {
    data.packageMaterial = defaultType;
    data.contentWeight = 0;
    data.totalWeightWithPackaging = 0;
    data.materialWeight = Number(data.materialWeight);
    data.materialCost = Number(data.materialCost);
    let packageData = {
      data,
      packagingType,
      farmGateId,
      packagingId: status === "add" ? packagingId : packageId,
    };

    if (status === "add") {
      addPackageData(packageData);
    } else {
      editPackageData(packageData);
    }
  };

  const getValuesByPackagingType = () => {
    switch (packagingType) {
      case "single-unit":
        return {
          title: "Base unit packaging",
          label: "Unit size (kg)",
          type: "Base unit",
          name: "baseUnit",
        };
      case "sales-unit":
        return {
          title: "Pack or inner pack packaging",
          label: "Number of Base Units",
          type: "Pack",
          name: "numBaseUnit",
        };
      case "bulk-unit":
        return {
          title: "Case packaging",
          label: "Number of Packs",
          type: "Case",
          name: "numPacks",
        };
      case "transport-unit":
        return {
          title: "Pallet packaging",
          label: "Number of Cases",
          type: "Pallet",
          name: "numCases",
        };
    }
  };

  const handleClick = () => {
    if (!packagingName) return;
    setAccordionEnabled(!accordionEnabled);
  };

  const loadTableData = () => {
    return packagingData.map((data: any, index: number) => {
      return (
        <Table.Row className="tbleR" key={index}>
          <Table.Cell>
            <p>{data?.packageName ?? ""}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{packagingTypeData?.[data?.packageMaterial || 0]?.text || ""}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data?.materialWeight ?? ""}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data?.materialCost ?? ""}</p>
          </Table.Cell>
          <Table.Cell>
            <Grid>
              <Grid.Column computer={8} tablet={16} mobile={16}>
                <MdModeEditOutline
                  cursor={"pointer"}
                  size={24}
                  color="var(--mainColor)"
                  onClick={() => {
                    setUnitSizeUpdate(data);
                    setEnableSizeEditStatus(true);
                    setPackageId(data._id);
                  }}
                />
              </Grid.Column>
              <Grid.Column computer={8} tablet={16} mobile={16}>
                <MdDeleteOutline
                  cursor={"pointer"}
                  size={24}
                  color="var(--mainColor)"
                  onClick={() => {
                    setDeleteData(data);
                    setVisibleDeleteModal(true);
                  }}
                />
              </Grid.Column>
            </Grid>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  if (isLoading || isLoadingUpdate || isDeletePackaging) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  const scrollToLastEditedTextbox = () => {
    if (lastEditedTextboxRef.current) {
      const lastEditedTextbox = document.getElementById(
        `${lastEditedTextboxRef.current}`
      );

      if (lastEditedTextbox) {
        lastEditedTextbox.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const onchangeDebounce = _.debounce((value) => changeUnits(value), 1000);

  return (
    <>
     <Accordion>
     <AccordionTitle active={accordionEnabled} onClick={handleClick}>
        <AccordionTitleView
          isDisabled={!packagingName}
          accordionEnabled={accordionEnabled}
          title={getValuesByPackagingType()?.title}
        />
      </AccordionTitle>
      <AccordionContent active={accordionEnabled}>
        <Grid>
          <GridRow>
            <Grid.Column floated="left" computer={5} tablet={8} mobile={16}>
              <InputText
                id={getValuesByPackagingType()?.name}
                register={packaging}
                labelName={getValuesByPackagingType()?.label}
                type="number"
                placeholder={
                  packagingData && packagingData.length > 0 ? unitSizes : "0"
                }
                name={getValuesByPackagingType()?.name}
                minNumber={0}
                onChangeFunction={(e: any) => {
                  const value = Number(e.target.value);
                  setPackageUnitValue(
                    e.target.value === ""
                      ? "0"
                      : e.target.value === "0"
                      ? 0
                      : value
                  );
                }}
              />
            </Grid.Column>

            {packagingType === "transport-unit" ? (
              <>
                <Grid.Column floated="left" computer={3} tablet={8} mobile={16}>
                  <InputText
                    errors={errorsUnit.palletWidth}
                    register={packaging}
                    labelName={"Pallet Size"}
                    placeholder={
                      packagingData && packagingData.length > 0
                        ? palletWidth
                        : "width (cm)"
                    }
                    name="palletWidth"
                    type="number"
                    minNumber={0}
                    onChangeFunction={(e: any) => {
                      const value = Number(e.target.value);
                      setPalletSizeWidth(
                        e.target.value === ""
                          ? "0"
                          : e.target.value === "0"
                          ? 0
                          : value
                      );
                    }}
                  />
                </Grid.Column>
                <Grid.Column floated="left" computer={3} tablet={8} mobile={16}>
                  <label className={`textLabelBlind`}>{`+`}</label>
                  <InputText
                    errors={errorsUnit.palletLength}
                    register={packaging}
                    labelName={""}
                    placeholder={
                      packagingData && packagingData.length > 0
                        ? palletLength
                        : "length (cm)"
                    }
                    name="palletLength"
                    type="number"
                    minNumber={0}
                    onChangeFunction={(e: any) => {
                      const value = Number(e.target.value);
                      setpalletSizeLength(
                        e.target.value === ""
                          ? "0"
                          : e.target.value === "0"
                          ? 0
                          : value
                      );
                    }}
                  />
                </Grid.Column>
                {(packageUnitValue !== "0" &&
                  (packageUnitValue === 0 || packageUnitValue > 0)) ||
                (palletSizeWidth !== "0" &&
                  (palletSizeWidth === 0 || palletSizeWidth > 0)) ||
                (palletSizeLength !== "0" &&
                  (palletSizeLength === 0 || palletSizeLength > 0)) ? (
                  <Grid.Column
                    floated="left"
                    computer={5}
                    tablet={8}
                    mobile={16}
                  >
                    <CustomButton
                      theme="green"
                      customColumnStyle={"addUnitSize"}
                      title={
                        unitSizes > 0 ||
                        (packagingType === "transport-unit" &&
                          (palletLength > 0 || palletWidth > 0))
                          ? "Update"
                          : "Save"
                      }
                      onClick={() => {
                        setIsSaveBtnClicked(true);
                        setClickedPackagingUnit(
                          getValuesByPackagingType()?.name
                        );
                        if (packagingType === "transport-unit") {
                          const packageData = {
                            palletWidth: palletSizeWidth,
                            palletLength: palletSizeLength,
                            packageUnitValue: packageUnitValue,
                          };
                          onchangeDebounce(packageData);
                        } else {
                          onchangeDebounce(packageUnitValue);
                        }
                      }}
                    />
                  </Grid.Column>
                ) : null}
              </>
            ) : null}

            {packagingType !== "transport-unit" && (
              <>
                {packageUnitValue !== "0" &&
                (packageUnitValue === 0 || packageUnitValue > 0) ? (
                  <Grid.Column
                    floated="left"
                    computer={5}
                    tablet={8}
                    mobile={16}
                  >
                    <CustomButton
                      theme="green"
                      customColumnStyle={"addUnitSize"}
                      title={unitSizes > 0 ? "Update" : "Save"}
                      onClick={() => {
                        setIsSaveBtnClicked(true);
                        setClickedPackagingUnit(
                          getValuesByPackagingType()?.name
                        );
                        onchangeDebounce(packageUnitValue);
                      }}
                    />
                  </Grid.Column>
                ) : null}
                <Grid.Column
                  floated="right"
                  computer={5}
                  tablet={8}
                  mobile={16}
                >
                  <label className={`textLabel`}>{`Total weight of ${
                    getValuesByPackagingType()?.type
                  }`}</label>
                  <br />
                  <label className={`textLabel`}>{totalWeightOfItem}</label>
                </Grid.Column>
              </>
            )}
          </GridRow>
          {packagingType === "transport-unit" && (
            <GridRow>
              <Grid.Column floated="left" computer={5} tablet={8} mobile={16}>
                <label className={`textLabel`}>{`Total weight of ${
                  getValuesByPackagingType()?.type
                }`}</label>
                <br />
                <label className={`textLabel`}>{totalWeightOfItem}</label>
              </Grid.Column>
            </GridRow>
          )}
          <GridRow className="contentMargin">
            <Grid.Column computer={4} tablet={8} mobile={16}>
              <InputText
                register={packaging}
                errors={errorsUnit.packageName}
                labelName={"Packaging Name"}
                placeholder="Packaging Name"
                name="packageName"
                required={true}
                errorMessage="Packaging name is required"
              />
            </Grid.Column>
            <Grid.Column computer={4} tablet={8} mobile={16}>
              <DropDown
                labelName={"Packaging Material"}
                placeholder="Packaging Material"
                currentData={packagingTypeData}
                defaultValue={packagingTypeData?.[defaultType].value || null}
                customGridColumn={"customGridDropDownPackaging"}
                handleChangeState={(e: any, { value }: any) => {
                  let getIndex = packagingTypeData?.findIndex(
                    (e: any) => e?.value === value
                  );
                  setDefaultType(getIndex);
                }}
              />
            </Grid.Column>
            <Grid.Column computer={3} tablet={8} mobile={16}>
              <InputNumber
                control={control}
                errors={errorsUnit.materialWeight}
                labelName={"Material Weight(kg)"}
                placeholder="0.00"
                name="materialWeight"
                required={true}
                errorMessage="Material Weight is required"
                otherValidation={{
                  validate: {
                    valueCheck: (v: any) =>
                      valueGraterThanZero(v) ||
                      "Material weight must be grater than 0",
                  },
                }}
              />
            </Grid.Column>
            <Grid.Column computer={3} tablet={8} mobile={16}>
              <InputNumber
                control={control}
                errors={errorsUnit.materialCost}
                labelName={"Material Cost per unit"}
                placeholder="0.00"
                name="materialCost"
                required={false}
                errorMessage="Material Cost is required"
              />
            </Grid.Column>
            <Grid.Column computer={2} tablet={8} mobile={16}>
              {enableSizeEditStatus ? (
                <CustomButton
                  theme="green"
                  customColumnStyle={"addUnitSize"}
                  title="Update"
                  onClick={handleSubmitSingleUnit((data) => {
                    packageDataInsert(data, "edit");
                  })}
                />
              ) : (
                <CustomButton
                  theme="green"
                  customColumnStyle={"addUnitSize"}
                  icon="plus"
                  onClick={handleSubmitSingleUnit((data) => {
                    packageDataInsert(data, "add");
                  })}
                />
              )}
            </Grid.Column>
          </GridRow>
        </Grid>
        {packagingData?.length > 0 && (
          <Grid.Column
            computer={16}
            tablet={16}
            mobile={16}
            className="packagingDetailsView"
          >
            <CommonTable tableHeaderData={CultivationPackagingView}>
              {loadTableData()}
            </CommonTable>
          </Grid.Column>
        )}
      </AccordionContent>
      <ConfirmModal
        viewModal={visibleDeleteModal}
        closeModal={() => setVisibleDeleteModal(false)}
        cancel={() => {
          setVisibleDeleteModal(false);
          setDeleteData({});
        }}
        approve={() => {
          setVisibleDeleteModal(false);
          deletePackageData(deleteData);
        }}
        title="Delete package data"
        subTitle="Are you sure you want to delete packaging data?"
      />
      </Accordion>
    </>
  );
};

export default Packaging;
