import React, { useEffect, useState } from "react";
import { Grid, Table } from "semantic-ui-react";

import {
  CommonTable,
  CommonModal,
  InputText,
  CustomButton,
} from "../../../components";
import {
  Energy,
  Packaging,
  Processing,
  ProcessRecipe,
  Storage,
  Transportation,
} from "../../../config/constants";

import "./viewProcessDetails.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { useForm } from "react-hook-form";
import useWindowDimensions from "../../../hooks/windowsSize";
import CultivationConfirmModal from "../../cultivation/cultivationConfirmation/cultivationConfirmation";
import { useProcessCalculateData } from "../../../api/process";

const ViewProcessDetailsModal = ({ visibleModal, setVisibleModal }: any) => {
  let { processData } = useSelector((state: RootState) => state.process);
  const { height } = useWindowDimensions();
  const [showModal, setShowModal] = useState(false);

  const { mutate } = useProcessCalculateData();

  const {
    register,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setValue("productName", processData?.productName || "");
  }, [processData]);

  const loadProductRecipeData = () => {
    return processData?.receipe.map((data: any, index) => {
      return (
        <Table.Row key={index}>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.ingredientName}</p>
          </Table.Cell>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.content}</p>
          </Table.Cell>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.prcentage}</p>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  const loadProcessingData = () => {
    return processData?.processing.map((data, index: any) => {
      return (
        <Table.Row key={index}>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.processingName}</p>
          </Table.Cell>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.processingType}</p>
          </Table.Cell>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.input}</p>
          </Table.Cell>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.output}</p>
          </Table.Cell>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.capacity}</p>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  const loadPackagingData = () => {
    return processData?.packaging.map((data, index: any) => {
      return (
        <Table.Row key={index}>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.packageName}</p>
          </Table.Cell>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.meaterialWeight}</p>
          </Table.Cell>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.packageMaterial}</p>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  const loadStorageData = () => {
    return processData?.storage.map((data, index: any) => {
      return (
        <Table.Row key={index}>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.warehouseName}</p>
          </Table.Cell>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.warehouseSize}</p>
          </Table.Cell>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.temperature}</p>
          </Table.Cell>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.electricity}</p>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  const loadEnergyData = () => {
    return processData?.energyGrid.map((data, index: any) => {
      return (
        <Table.Row key={index}>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.energyType}</p>
          </Table.Cell>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.kWh}</p>
          </Table.Cell>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.percentage}</p>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  const loadTransportData = () => {
    return processData?.internalTransport.map((data, index: any) => {
      return (
        <Table.Row key={index}>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.transportType}</p>
          </Table.Cell>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.averageDistance}</p>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  return (
    <>
      <CommonModal
        modalOpenState={visibleModal}
        modalCloseState={() => setVisibleModal(false)}
        centered={true}
        customDimmer="customDimmerProduct"
        title="Processing Impact"
      >
        <div
          style={{
            height: height * 0.9 - 180,
            overflowX: "hidden",
          }}
        >
          <Grid>
            <Grid.Column computer={5} tablet={8} mobile={16}>
              <InputText
                register={register}
                name="productName"
                disabled={true}
              />
            </Grid.Column>
          </Grid>
          <>
            {processData?.receipe ? (
              <>
                <p className="confirmationTitle">Product Recipe</p>
                <Grid>
                  <Grid.Column computer={9} tablet={8} mobile={16}>
                    <CommonTable tableHeaderData={ProcessRecipe}>
                      {loadProductRecipeData()}
                    </CommonTable>
                  </Grid.Column>
                </Grid>
              </>
            ) : null}

            {processData?.processing ? (
              <>
                <p className="confirmationTitle">Process</p>

                <Grid>
                  <Grid.Column
                    computer={16}
                    tablet={16}
                    mobile={16}
                    className="processDetailsTableView"
                  >
                    <CommonTable tableHeaderData={Processing}>
                      {loadProcessingData()}
                    </CommonTable>
                  </Grid.Column>
                </Grid>
              </>
            ) : null}

            {processData?.packaging ? (
              <>
                <p className="confirmationTitle">Packing</p>

                <Grid>
                  <Grid.Column
                    computer={16}
                    tablet={16}
                    mobile={16}
                    className="processDetailsTableView"
                  >
                    <CommonTable tableHeaderData={Packaging}>
                      {loadPackagingData()}
                    </CommonTable>
                  </Grid.Column>
                </Grid>
              </>
            ) : null}

            {processData?.storage ? (
              <>
                <p className="confirmationTitle">Storage</p>

                <Grid>
                  <Grid.Column
                    computer={16}
                    tablet={16}
                    mobile={16}
                    className="processDetailsTableView"
                  >
                    <CommonTable tableHeaderData={Storage}>
                      {loadStorageData()}
                    </CommonTable>
                  </Grid.Column>
                </Grid>
              </>
            ) : null}

            {processData?.energyGrid ? (
              <>
                <p className="confirmationTitle">Energy</p>

                <Grid>
                  <Grid.Column
                    computer={16}
                    tablet={16}
                    mobile={16}
                    className="processDetailsTableView"
                  >
                    <CommonTable tableHeaderData={Energy}>
                      {loadEnergyData()}
                    </CommonTable>
                  </Grid.Column>
                </Grid>
              </>
            ) : null}

            {processData?.internalTransport ? (
              <>
                <p className="confirmationTitle">Transportation</p>

                <Grid>
                  <Grid.Column
                    computer={16}
                    tablet={16}
                    mobile={16}
                    className="processDetailsTableView"
                  >
                    <CommonTable tableHeaderData={Transportation}>
                      {loadTransportData()}
                    </CommonTable>
                  </Grid.Column>
                </Grid>
              </>
            ) : null}
          </>
        </div>
        <div
          style={{
            height: height * 0.1,
          }}
          className="modalRegisterButtonMain"
        >
          <div className="modalRegisterButtonLeft">
            <CustomButton
              theme="green"
              title={"Confirm"}
              onClick={() => setShowModal(true)}
              type={"submit"}
            />
          </div>
        </div>
      </CommonModal>
      <CultivationConfirmModal
        viewModal={showModal}
        closeModal={() => setShowModal(false)}
        check={() => {
          setShowModal(false);
        }}
        calculation={() => {
          mutate(processData?._id)
          setShowModal(false);
        }}
        title="Confirm"
        subTitle="Are you sure all details have been included and are correct?"
      />
    </>
  );
};

export default ViewProcessDetailsModal;
