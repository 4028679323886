import {
  ReactFlowProvider,
  Node,
  Edge,
  EdgeChange,
  NodeChange,
} from "reactflow";
import {
  Dispatch,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Sidebar,
  Segment,
  Button,
  Icon,
  Dimmer,
  Loader,
  Popup,
} from "semantic-ui-react";
import { WebrtcProvider } from "y-webrtc";
import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom"

import { TitleActionBar } from "../../components";
import "./DrawingTool.scss";
import CanvasMain from "./CanvasMain";
import AddingItemsPanel from "./AddingNodes/AddingItemsPanel";
import { useGetProductById, useGetProductSalesUnits } from "../../api/product";
import UpdateNodeModal from "./UpdateNodes/UpdateNodeModal";
import {
  useCreateDrawing,
  useGetDrawingToolData,
  usePutDrawingToolDataToQueue,
  useUpdateDrawing,
} from "../../api/drawingTool";
import ConfirmModal from "../../components/confirmViewModal/ConfirmModal";
import CopyDrawingModal from "./CopyDrawingModal";
import { nodeCatagories } from "../../config/drawingConstants";
import useNodesStateSynced from "./Hooks/useNodesStateSynced";
import useEdgesStateSynced from "./Hooks/useEdgesStateSynced";
import ydoc from "../../api/socket";
import DrawingErrorsModal from "./DrawingErrorsModal";
import { NP_SOCKET_API_URL } from "../../config/constants";
import calculatorLoading from "../../assets/animations/CalculatorLoading.json";

interface DrawingsState {
  nodeItemId: any | null;
  productId: string | undefined;
  chartNodes: Node<any>[];
  chartEdges: Edge<any>[];
  salesUnitId: string | undefined;
  productData: any;
  setChartNodes: Dispatch<Node[]>;
  setChartEdges: Dispatch<Edge[]>;
  onNodesChange: Dispatch<NodeChange[]>;
  onEdgesChange: Dispatch<EdgeChange[]>;
  setNodeItem: Dispatch<any>;
  updateChartGroup: (groupClassName: string, node: Node) => void;
  saveDrawing: (chartEdges: any, chartNodes: any) => void;
  saveProductData: Dispatch<any>;
}

const initialState: DrawingsState = {
  nodeItemId: null,
  productId: undefined,
  chartNodes: [],
  chartEdges: [],
  salesUnitId: undefined,
  productData: undefined,
  setChartNodes: () => {},
  setChartEdges: () => {},
  onNodesChange: () => {},
  onEdgesChange: () => {},
  setNodeItem: () => {},
  updateChartGroup: () => {},
  saveDrawing: () => {},
  saveProductData: () => {},
};

export const DrawingContext = createContext(initialState);

const DrawingTool = () => {
  // Drawing States
  const [chartNodes, setChartNodes, onNodesChange] = useNodesStateSynced();
  const [chartEdges, setChartEdges, onEdgesChange] = useEdgesStateSynced();
  const [nodeItemId, setNodeItem] = useState<any>(null);
  const [productData, saveProductData] = useState<any>({});
  const [isVisible, setIsVisible] = useState(false);
  const [isVisibleCopyModal, setIsVisibleCopyModal] = useState(false);
  // Dropdown States
  const [searchKey, setSearchKey] = useState("");
  const [salesUnitId, setSalesUnitId] = useState<string>();
  const [calculateModalOpen, setCalculateModalOpen] = useState<boolean>(false);
  const [currentProductId, setCurrentProductId] = useState<any>();
  const [putDataToQueueErrors, setPutDataToQueueErrors] = useState<string[]>(
    []
  );

  const navigate = useNavigate();

  const {
    data: productSalesUnitsData,
    isLoading: isProductSalesUnitsLoading,
    isSuccess: isSuccessSalesUnits,
  } = useGetProductSalesUnits({ searchKey });

  const productId = useMemo(() => {
    return productSalesUnitsData?.find((ps) => ps.salesUnitId === salesUnitId)
      ?.productId;
  }, [salesUnitId, isSuccessSalesUnits]);

  useEffect(() => {
    ydoc.destroy();
    if (salesUnitId) {
      const provider = new WebrtcProvider(`${salesUnitId}`, ydoc, {
        signaling: [NP_SOCKET_API_URL],
        filterBcConns: true,
      });
      if (provider.connected) {
        ydoc.load();
      }
    }
  }, [salesUnitId]);

  const {
    data: drawingToolData,
    isLoading: isDrawingToolLoading,
    isSuccess,
    isError,
    refetch: reFetchDrawingTool,
  } = useGetDrawingToolData(salesUnitId);

  const {
    data: productDetails,
    isSuccess: isSuccessProduct,
    isError: isErrorProduct,
  } = useGetProductById(currentProductId);

  const { mutate: createDrawing, isLoading: isLoadingCreateDrawing } =
    useCreateDrawing();
  const { mutate: updateDrawing, isLoading: isLoadingUpdateDrawing } =
    useUpdateDrawing();
  const {
    isLoading: isLoadingPutDataToQueue,
    error: putDataToQueueError,
    mutateAsync: putDrawingToolDataToQueueAsync,
  } = usePutDrawingToolDataToQueue();

  // set queue errors
  useEffect(() => {
    if (putDataToQueueError?.errors) {
      setPutDataToQueueErrors(putDataToQueueError.errors);
    }
  }, [putDataToQueueError]);

  const getProductData = useCallback(() => {
    setCurrentProductId(productId);
  }, [productId]);

  const setProductData = useCallback(() => {
    if (isSuccessProduct || isErrorProduct) {
      saveProductData(productDetails);
    }
  }, [isSuccessProduct, isErrorProduct]);

  useEffect(() => {
    getProductData();
    setProductData();
    if (isSuccess || isError) {
      setChartNodes(drawingToolData?.chartNodes || []);
      setChartEdges(drawingToolData?.chartEdges || []);
    }
  }, [drawingToolData, isError, isSuccess, getProductData, setProductData]);

  const saveDrawing = useCallback(
    (chartEdges: any, chartNodes: any) => {
      if (drawingToolData?._id) {
        updateDrawing({
          ...drawingToolData,
          chartEdges: chartEdges,
          chartNodes: chartNodes,
        });
        return;
      }
      const newDrawing = {
        salesUnitId,
        productId,
        chartEdges: chartEdges,
        chartNodes: chartNodes,
      };
      createDrawing(newDrawing, {
        onSuccess: () => {
          reFetchDrawingTool();
        },
      });
    },
    [drawingToolData, salesUnitId, updateDrawing, createDrawing]
  );

  // Data for dropdown
  const productSalesUnits: DropdownOption[] = (productSalesUnitsData || []).map(
    ({ productName, salesUnitName, salesUnitId }) => ({
      key: salesUnitId,
      text: `${productName} - ${salesUnitName}`,
      value: salesUnitId,
    })
  );

  const updateChartGroup = useCallback(
    (groupClassName: string, node: Node) => {
      setChartNodes((nds) => {
        const data = [...nds];
        return data.map((n) => {
          if (n.type !== nodeCatagories.special) {
            return {
              ...n,
              className: groupClassName,
            };
          } else if (n.id === node.id) {
            return {
              ...n,
              position: node.position,
            };
          }
          return { ...n };
        });
      });
    },
    [setChartNodes]
  );

  // Context Provider Values
  const value = {
    chartNodes,
    chartEdges,
    nodeItemId,
    salesUnitId,
    productId,
    productData,
    setChartNodes,
    setChartEdges,
    onEdgesChange,
    onNodesChange,
    setNodeItem,
    updateChartGroup,
    saveDrawing,
    saveProductData,
  };

  if (!salesUnitId) {
    return (
      <>
        <TitleActionBar
          title={"Product Name"}
          currentData={productSalesUnits}
          defaultValue={salesUnitId}
          handleChangeState={(e: any, data: any) => {
            setSalesUnitId(data.value as string);
          }}
          onSearchChange={(e: any, data: any) => {
            setSearchKey(data.searchQuery);
          }}
          loading={isProductSalesUnitsLoading}
          search
        />
        <div className="product-empty-description">
          <h3>
            Please select the product or create the product if product is not
            available from the dropdown.
          </h3>
        </div>
      </>
    );
  }

  // Loading state check
  if (
    isDrawingToolLoading ||
    isLoadingCreateDrawing ||
    isLoadingUpdateDrawing
  ) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  if (isLoadingPutDataToQueue) {
    return (
      <Dimmer active>
        <div className="loading-calculation">
          <Lottie animationData={calculatorLoading} loop={true} />
        </div>
      </Dimmer>
    );
  }

  return (
    <DrawingContext.Provider value={value}>
      <ReactFlowProvider>
        <Sidebar.Pushable as={Segment} style={{ overflow: "hidden" }}>
          <AddingItemsPanel
            isVisible={isVisible}
            onClose={() => setIsVisible(!isVisible)}
          />
          <TitleActionBar
            title={"Product Name"}
            currentData={productSalesUnits}
            defaultValue={salesUnitId}
            handleChangeState={(e: any, data: any) => {
              setSalesUnitId(data.value as string);
            }}
            onSearchChange={(e: any, data: any) => {
              setSearchKey(data.searchQuery);
            }}
            loading={isProductSalesUnitsLoading}
            search
          />
          <Button
            icon
            color="teal"
            className="open-side-menu-button"
            onClick={() => {
              setIsVisible(!isVisible);
            }}
          >
            <Icon name="angle double right" />
          </Button>
          <CanvasMain />
          {drawingToolData?._id ? (
            <Popup
              content="Copy Drawing."
              size="mini"
              trigger={
                <Button
                  icon
                  color="teal"
                  className="drawing-copy-button"
                  onClick={() => {
                    setIsVisibleCopyModal(true);
                  }}
                >
                  Duplicate
                </Button>
              }
            />
          ) : null}

          <Popup
            content="Save Drawing."
            size="mini"
            trigger={
              <Button
                icon
                color="teal"
                className="drawing-save-button"
                onClick={() => {
                  saveDrawing(chartEdges, chartNodes);
                }}
              >
                Save
              </Button>
            }
          />

          <Popup
            content="Calculate Drawing Data."
            size="mini"
            trigger={
              <Button
                icon
                color="teal"
                className="drawing-calculate-button"
                onClick={() => {
                  setCalculateModalOpen(true);
                }}
              >
                Calculate
              </Button>
            }
          />

          {!!nodeItemId ? <UpdateNodeModal /> : null}

          <ConfirmModal
            viewModal={calculateModalOpen}
            closeModal={() => setCalculateModalOpen(false)}
            cancel={() => {
              setCalculateModalOpen(false);
            }}
            approve={async () => {
              await putDrawingToolDataToQueueAsync(drawingToolData);
              navigate(`/product-summary?salesUnitId=${salesUnitId}&productId=${productId}`);
              setCalculateModalOpen(false);
            }}
            title="Calculate Drawing Data."
            subTitle="Are you sure you have entered all the data for calculation?"
          />
        </Sidebar.Pushable>
      </ReactFlowProvider>
      {isVisibleCopyModal ? (
        <CopyDrawingModal
          drawingId={drawingToolData?._id}
          closeModal={() => {
            setIsVisibleCopyModal(false);
          }}
          onSuccessCopy={(salesUnitId: any) => {
            setSalesUnitId(salesUnitId);
          }}
        />
      ) : null}
      <DrawingErrorsModal
        errors={putDataToQueueErrors}
        setPutDataToQueueErrors={setPutDataToQueueErrors}
      />
    </DrawingContext.Provider>
  );
};

export default DrawingTool;
