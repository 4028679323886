import React, { useState, useEffect, useMemo } from "react";
import { Checkbox, Dimmer, Grid, Loader, Table } from "semantic-ui-react";
import { useForm } from "react-hook-form";

import {
  InputText,
  CustomButton,
  CommonModal,
  TitleView,
} from "../../../components";
import useWindowDimensions from "../../../hooks/windowsSize";

import "./userDetails.scss";
import { useGetUserById } from "../../../api/userDetails";

const UserDetailsModal = ({ visibleModal, setVisibleModal, id,userRolesData }: any) => {
  const { height } = useWindowDimensions();

  // custom hook user data load
  const {
    data: userDetails,
    isLoading: userLoading,
    mutate,
    reset: userReset,
  } = useGetUserById(id);

  // check user data input data changed
  const values = useMemo(() => {
    return {
      phone: userDetails?.phone || "",
      email: userDetails?.email || "",
      firstName: userDetails?.firstName || "",
      lastName: userDetails?.lastName || "",
    };
  }, [userDetails]);

  // load user data
  useEffect(() => {
    if (visibleModal) {
      mutate(id);
    }
    return () => {
      reset();
      userReset();
    };
  }, [visibleModal]);

  const {
    register,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: values,
    values: values,
  });

  //handle loading
  if (userLoading) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  return (
    <>
      <CommonModal
        modalOpenState={visibleModal}
        modalCloseState={() => {
          setVisibleModal(false);
          reset();
        }}
        centered={true}
        customDimmer="customDimmerProduct"
        title="User Details"
      >
        <TitleView
          title="User info"
          CustomTitleViewMain="productRegisterTitle"
        />
        <Grid>
          <Grid.Column computer={16} tablet={16} mobile={16}>
            <>
              <form>
                <div
                  style={{
                    height: height * 0.9 - 180,
                    overflowX: "hidden",
                  }}
                >
                  <Grid>
                    <Grid.Column computer={16} tablet={16} mobile={16}>
                      <Grid>
                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveBottom"
                        >
                          <InputText
                            register={register}
                            errors={errors.firstName}
                            labelName={"First Name"}
                            placeholder="First Name"
                            name="firstName"
                            required={true}
                            errorMessage={"Please enter user name"}
                            disabled={true}
                          />
                        </Grid.Column>
                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveBottom"
                        >
                          <InputText
                            register={register}
                            errors={errors.firstName}
                            labelName={"Last Name"}
                            placeholder="Last Name"
                            name="lastName"
                            required={true}
                            errorMessage={"Please enter user name"}
                            disabled={true}
                          />
                        </Grid.Column>

                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveTop"
                        >
                          <InputText
                            register={register}
                            errors={errors.email}
                            labelName={"Email"}
                            placeholder="Email"
                            name="email"
                            required={true}
                            disabled={true}
                          />
                        </Grid.Column>
                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveTop"
                        >
                          <InputText
                            register={register}
                            errors={errors.phone}
                            labelName={"phone"}
                            placeholder="phone"
                            name="phone"
                            required={false}
                            errorMessage={"Please enter name"}
                            disabled={true}
                          />
                        </Grid.Column>

                        <Grid.Column
                          computer={16}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveTop"
                        >
                          <label className={`inputTextLable`}>Role</label>
                          <Grid className="userDetailsRoles">
                            {userRolesData?.map((data:any,index:any) => {
                              return (
                                <Grid.Column
                                  key={index}
                                  computer={4}
                                  tablet={16}
                                  mobile={16}
                                >
                                  <Checkbox
                                    label={data.text}
                                    checked={userDetails?.userRole?.some(
                                      (e: any) => e == data.text
                                    )}
                                    disabled
                                  />
                                </Grid.Column>
                              );
                            })}
                          </Grid>
                        </Grid.Column>
                      </Grid>
                    </Grid.Column>
                  </Grid>
                </div>

                <div
                  style={{
                    height: height * 0.1,
                  }}
                  className="modalRegisterButtonMain"
                >
                  <div className="modalRegisterButtonLeft">
                    <CustomButton
                      theme="green"
                      title={"Cancel"}
                      customColumnStyle={"cardInsertSaveOther"}
                      buttonOutLine={true}
                      onClick={() => {
                        setVisibleModal(false);
                        reset();
                      }}
                    />
                  </div>
                </div>
              </form>
            </>
          </Grid.Column>
        </Grid>
      </CommonModal>
    </>
  );
};

export default UserDetailsModal;
