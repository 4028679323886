import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Grid } from "semantic-ui-react";
import { useForm } from "react-hook-form";

import {
  useDeleteWasteManagementData,
  usePostWasteManagementData,
  usePutWasteManagementData,
} from "../../api/wasteManagement";
import { errorView, successMessage } from "../../helpers/ErrorHandler";
import ConfirmModal from "../confirmViewModal/ConfirmModal";
import { DropDown } from "../selectDropDown/DropDown";
import { InputNumber } from "../InputText/InputNumber";
import { CustomButton } from "../button/Button";
import { CommonTable } from "../commonTable/CommonTable";
import { LoadWasteManagementTableData } from "./WasteManagementTableView";
import { WasteManagementDataCol } from "../../config/constants";
import { InputText } from "../InputText/InputText";
import { CommonModal } from "../commonModal/CommonModal";
import useWindowDimensions from "../../hooks/windowsSize";

import "./WasteManagement.scss";
import { isArray } from "lodash";

export const WasteManagementModal = ({
  visibleModal,
  setVisibleModal,
  wasteManagementType,
  wasteManagement,
  processId,
  sideStreamValue,
  refetchWasteData,
  type,
  supplierDataHandle = false
}: any) => {
  const { height } = useWindowDimensions();
  const [defaultManagementType, setDefaultManagementType] = useState<any>(1);
  const [enableEdit, setEnableEdit] = useState<any>(false);
  const [updateData, setUpdateData] = useState<any>({});
  const [deleteData, setDeleteData] = useState<any>({});
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);

  const { mutate: postWasteDataSet } = usePostWasteManagementData();
  const { mutate: putWasteDataSet } = usePutWasteManagementData();
  const { mutate: deleteWasteDataSet } = useDeleteWasteManagementData();
  
  const values = useMemo(() => {
    return {
      managementName: null,
      sideStreamsDistribution: 0,
      totalSide: 0,
      sideStream: sideStreamValue
    };
  }, [visibleModal]);

  useEffect(() => {
    reset();
    setTotalValue();
  }, [visibleModal]);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    mode: "all",
    defaultValues: values,
    values: values,
  });

  //check waste data insert validation
  const checkWasteDataValidation = (data: any) => {
    if (data.sideStreamsDistribution == 0) {
      errorView("Side Streams Distribution must greater than 0");
      return false;
    } else {
      return true;
    }
  };

  //reset after change all data
  const resetData = (Status?: String) => {
    reset();
    setDefaultManagementType(1);
    refetchWasteData();
    setEnableEdit(false);
  };

  //create new waste
  const createNewWaste = (data: any) => {
    if (checkWasteDataValidation(data)) {
      if (enableEdit) {
        let createWaste = {
          wasteManagementId: updateData._id,
          type,
          supplierDataHandle,
          wasteData: {
            ...updateData,
            managementName: data.managementName,
            managementTypeId: defaultManagementType,
            sideStreamsDistribution: data.sideStreamsDistribution,
          },
        };
        putWasteDataSet(createWaste, {
          onSuccess(data) {
            successMessage("Waste Management edited successfully")
            resetData("Edit");
          },
        });
      } else {
        let createChemicals = {
          processingId: processId,
          type,
          supplierDataHandle,
          wasteData: {
            managementName: data.managementName,
            managementTypeId: defaultManagementType,
            sideStreamsDistribution: data.sideStreamsDistribution,
          },
        };
        postWasteDataSet(createChemicals, {
          onSuccess(data) {
            successMessage("Waste Management created successfully")
            resetData("Create");
          },
        });
      }
    }
  };

  //delete waste data
  const deleteWasteData = (data: any) => {
    let deleteWasteData = {
      type,
      supplierDataHandle,
      wasteData: {
        wasteManagementId: data._id,
        processingId: processId,
      },
    };
    deleteWasteDataSet(deleteWasteData, {
      onSuccess(data) {
        successMessage("Waste Management deleted successfully")
        resetData("Delete");
      },
    });
  };

  //handle delete cancel
  const handleDeleteCancel = () => {
    setVisibleDeleteModal(false);
    setDeleteData({});
  };

  //handle delete
  const handleDeleteApprove = () => {
    if (visibleDeleteModal) {
      setVisibleDeleteModal(false);
      deleteWasteData(deleteData);
    }
  };

  //update waste data
  const updateWasteData = (data: any) => {
    setUpdateData(data);
    setDefaultManagementType(data?.managementTypeId || 1);
    setValue("managementName", data?.managementName || "");
    setValue("sideStreamsDistribution", data?.sideStreamsDistribution || 0);
    setEnableEdit(true);
  };

  //delete data collect
  const deleteDataAction = (data: any) => {
    setDeleteData(data);
    setVisibleDeleteModal(true);
  };

  //get management type data
  const getManagementType = useCallback(() => {
    if (isArray(wasteManagementType)) {
      let findManagementType = wasteManagementType.find(
        (e: any) => e.value === defaultManagementType.toString()
      );
      return findManagementType?.value || "";
    }
  }, [wasteManagementType, defaultManagementType]);

  //get total stream
  const getSideStreamTotal = useMemo(() => {
    if (isArray(wasteManagement)) {
      let totalSideStream = wasteManagement.reduce(
        (a, b) => a + b.sideStreamsDistribution,
        0
      );
      setValue("totalSide", totalSideStream);
      return totalSideStream;
    }
  }, [wasteManagement, visibleModal]);

  //load total stream
  const setTotalValue = useCallback(() => {
    setValue("totalSide", getSideStreamTotal);
  }, [getSideStreamTotal]);

  return (
    <CommonModal
      modalOpenState={visibleModal}
      modalCloseState={() => {
        setVisibleModal(false);
      }}
      centered={true}
      customDimmer="custom-dimmer-modal"
      title="Side Stream and Waste Management"
    >
      <div
        style={{
          height: height * 0.8 - 90,
          overflowX: "hidden",
        }}
      >
        <Grid className="wasteManagementDetailsInsertMain">
          <Grid.Column computer={5}>
            <InputText
              labelName={"Total Side stream (kg)"}
              errors={errors.totalSide}
              register={register}
              placeholder="Total Side stream (kg)"
              name={"totalSide"}
              defaultValues={getSideStreamTotal}
            />
          </Grid.Column>
          <Grid.Column computer={5}>
            <InputText
              labelName={"Side stream (kg)"}
              errors={errors.sideStream}
              register={register}
              placeholder="Side stream (kg)"
              name={"sideStream"}
              defaultValues={sideStreamValue}
              disabled
            />
          </Grid.Column>
          <Grid.Column computer={16}>
            <div className="wasteManagementDetailsInsertBox">
              <form onSubmit={handleSubmit(createNewWaste)}>
                <Grid>
                  <Grid.Column computer={5}>
                    <InputText
                      labelName={"Management name"}
                      errors={errors.managementName}
                      register={register}
                      placeholder="Management name"
                      name={"managementName"}
                      required={true}
                      errorMessage="Management name is required"
                    />
                  </Grid.Column>
                  <Grid.Column computer={5}>
                    <DropDown
                      required={true}
                      labelName={"Management type"}
                      placeholder="Management type"
                      currentData={wasteManagementType}
                      defaultValue={getManagementType()}
                      customGridColumn={"customGridColomnType"}
                      handleChangeState={(e: any, { value }: any) => {
                        setDefaultManagementType(value);
                      }}
                    />
                  </Grid.Column>
                  <Grid.Column computer={4}>
                    <InputNumber
                      control={control}
                      errors={errors.sideStreamsDistribution}
                      labelName={"Distribution (kg)"}
                      placeholder="Distribution (kg)"
                      name="sideStreamsDistribution"
                      required={true}
                      errorMessage="Distribution is required"
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={2}
                    tablet={16}
                    mobile={16}
                    className="wasteManagementAdd"
                  >
                    <CustomButton
                      onClick={handleSubmit(createNewWaste)}
                      title={enableEdit ? "Edit" : "Add"}
                      theme="green"
                      type="submit"
                    />
                  </Grid.Column>
                </Grid>
              </form>
            </div>
          </Grid.Column>
        </Grid>
        <Grid style={{ marginTop: "20px" }}>
          <Grid.Column computer={16} tablet={16} mobile={16}>
            <CommonTable tableHeaderData={WasteManagementDataCol}>
              <LoadWasteManagementTableData
                wasteManagement={wasteManagement}
                wasteManagementType={wasteManagementType}
                updateWasteData={updateWasteData}
                deleteDataAction={deleteDataAction}
              />
            </CommonTable>
          </Grid.Column>
        </Grid>
      </div>
      <div className="sipButtonContent">
        <div className="sipCancel">
          <CustomButton
            onClick={() => {
              setVisibleModal(false);
              resetData();
            }}
            title="Cancel"
            theme="green"
            buttonOutLine={true}
          />
        </div>
      </div>
      <ConfirmModal
        viewModal={visibleDeleteModal}
        closeModal={() => setVisibleDeleteModal(false)}
        cancel={() => {
          handleDeleteCancel();
        }}
        approve={() => {
          handleDeleteApprove();
        }}
        title="Delete Chemical Data"
        subTitle="Are you sure you want to delete chemical data?"
      />
    </CommonModal>
  );
};
