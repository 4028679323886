import { useMutation, useQuery } from "@tanstack/react-query";
import { ApiServiceErr, MutOptions, axiosApi } from "./apiService";
import { successMessage } from "../helpers/ErrorHandler";

export const useProcessCalculateData = (opt?: MutOptions<string>) =>
  useMutation<string, ApiServiceErr, any>(async (id) => {
    const response = await axiosApi.put(`/process-module/queue/${id}`);
    successMessage(response.data);
    return response.data;
  }, opt);

export const usePostProcessGateInternalStorageData = (data?: any) => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.post(
      `/supplier-process-gate/internal-storage?processGateId=${data?.processGateId}`,
      data?.storageData
    );
    return response.data;
  });
};

export const usePutProcessGateInternalStorageData = (data?: any) => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.put(
      `/supplier-process-gate/internal-storage/${data?.internalStorageId}?processGateId=${data?.processGateId}`,
      data?.storageData
    );
    return response.data;
  });
};

export const useDeleteProcessGateInternalStorageData = (data?: any) => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.delete(
      `/supplier-process-gate/internal-storage/${data?.internalStorageId}?ProcessGate=${data?.processGateId}`
    );
    return response.data;
  });
};

export const useGetProcessGateInternalStorageById = (
  processGateInternalStorageId: any
) =>
  useQuery(
    ["getProcessGateInternalStorageById"],
    async () => {
      const response = await axiosApi.get(
        `/supplier-process-gate/internal-storage/${processGateInternalStorageId}`
      );
      return response?.data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  export const usePostProcessGateRecipeData = () => {
    return useMutation<string, ApiServiceErr, any>(async (data) => {
      const response = await axiosApi.post(
        `/${data.type}/recipe/${data?.processId}`,
        data
      );
      return response.data;
    });
  };

export const useGetProcessRecipeByProcessId = (data: { recipeId: string, type: string}) =>
  useQuery(
    ["getProcessRecipeByProcessId"],
    async () => {
      const response = await axiosApi.get(
        `/${data.type}/recipe/${data.recipeId}`
      );
      return response?.data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!data.recipeId,
    }
  );
