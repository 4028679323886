import React, { useState } from "react";
import { Grid, Table } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { TbFileSearch } from "react-icons/tb";

import {
  InputText,
  CustomButton,
  InputTextArea,
  CommonTable,
  CommonModal,
  TitleView,
  SearchValue,
  ItemAdder,
} from "../../../components";
import {
  ClientProductTableContent,
  ClientProductListCol,
} from "../../../config/constants";
import { MdModeEditOutline, MdDeleteOutline } from "react-icons/md";
import useWindowDimensions from "../../../hooks/windowsSize";

import "./ClientEdit.scss";

const ClientEditModal = ({ visibleModal, setVisibleModal }: any) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { height, width } = useWindowDimensions();

  const navigate = useNavigate();

  const onSubmit = (data: any) => {};

  const [itemsNewIngredients, setItemsNewIngredients] = useState([{}]);

  const itemAddHandleNewIngredients = () => {
    setItemsNewIngredients([...itemsNewIngredients, {}]);
  };
  const itemRemoveHandleNewIngredients = (index: number) => {
    itemsNewIngredients.splice(index, 1);
    setItemsNewIngredients([...itemsNewIngredients]);
  };

  const loadTableData = () => {
    return ClientProductTableContent.map((data, index) => {
      return (
        <Table.Row className="tbleR" key={index}>
          <Table.Cell>
            <p>{data.name}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data.type}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data.impact}</p>
          </Table.Cell>
          <Table.Cell>
            <Grid>
              <Grid.Column computer={4} tablet={16} mobile={16} key={index}>
                <TbFileSearch
                  cursor={"pointer"}
                  onClick={() => navigate("supplier-details")}
                  size={24}
                  color="var(--mainColor)"
                />
              </Grid.Column>
              <Grid.Column computer={4} tablet={16} mobile={16} key={index}>
                <MdModeEditOutline
                  cursor={"pointer"}
                  onClick={() => navigate("supplier-edit")}
                  size={24}
                  color="var(--mainColor)"
                />
              </Grid.Column>
              <Grid.Column computer={4} tablet={16} mobile={16} key={index}>
                <MdDeleteOutline cursor={"pointer"} size={24} color="var(--mainColor)" />
              </Grid.Column>
            </Grid>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  return (
    <CommonModal
      modalOpenState={visibleModal}
      modalCloseState={() => setVisibleModal(false)}
      centered={true}
      customDimmer="customDimmerProduct"
      title="Edit Client Details"
    >
      <Grid>
        <Grid.Column computer={16} tablet={16} mobile={16}>
          <div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div
                style={{
                  height: height * 0.9 - 180,
                  overflowX: "hidden",
                }}
              >
                <Grid>
                  <Grid.Column
                    computer={8}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <SearchValue title={"Client Name*"} />
                  </Grid.Column>
                  <Grid.Column
                    computer={8}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <InputText
                      register={register}
                      errors={errors.email}
                      labelName={"Email"}
                      placeholder="Email"
                      name="email"
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={8}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <InputText
                      register={register}
                      errors={errors.ContactNumber}
                      labelName={"Contact Number"}
                      placeholder="Contact Number"
                      name="ContactNumber"
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={8}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <InputText
                      register={register}
                      errors={errors.address}
                      labelName={"Address"}
                      placeholder="Address"
                      name="address"
                    />
                  </Grid.Column>

                  <Grid.Column
                    computer={8}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <InputText
                      register={register}
                      errors={errors.city}
                      labelName={"City"}
                      placeholder="City"
                      name="city"
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={8}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <InputText
                      register={register}
                      errors={errors.province}
                      labelName={"Province"}
                      placeholder="Province"
                      name="province"
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={8}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <InputText
                      register={register}
                      errors={errors.country}
                      labelName={"Country"}
                      placeholder="Country"
                      name="country"
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={8}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <InputText
                      register={register}
                      errors={errors.zipCode}
                      labelName={"Zip Code"}
                      placeholder="Zip Code"
                      name="zipCode"
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={16}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <InputTextArea
                      register={register}
                      errors={errors.productName}
                      labelName={"Other Information"}
                      placeholder="Other Information"
                      name="otherInfo"
                      customInputValidation={true}
                    />
                  </Grid.Column>
                </Grid>

                <TitleView
                  title="Add Product"
                  CustomTitleViewMain="supplierAddCustomIngredientTitle"
                />
                {itemsNewIngredients.map((item, index) => {
                  return (
                    <ItemAdder
                      itemAddHandle={itemAddHandleNewIngredients}
                      itemRemoveHandle={itemRemoveHandleNewIngredients}
                      items={itemsNewIngredients}
                      currentIndex={index}
                      computerMainView={10}
                      tabletMainView={16}
                      mobileMainView={16}
                      customItemAdderGridMain="supplierCustomItemAdderGridMain"
                      customItemAdderGridWithInputs="supplierCustomItemAdderGridWithInputs paddingRemoveVertical paddingRemoveLeft "
                      customItemAddButtonMain="supplierCustomItemAddButtonMain"
                    >
                      <SearchValue title={"Product Name*"} />
                      <></>
                    </ItemAdder>
                  );
                })}

                <Grid style={{ marginTop: "20px" }}>
                  <Grid.Column computer={16} tablet={16} mobile={16}>
                    <CommonTable tableHeaderData={ClientProductListCol}>
                      {loadTableData()}
                    </CommonTable>
                  </Grid.Column>
                </Grid>
              </div>

              <div
                style={{
                  height: height * 0.1,
                }}
                className="modalRegisterButtonMain"
              >
                <div className="modalRegisterButtonLeft">
                  <CustomButton
                    theme="green"
                    title={"Update Client"}
                    type={"submit"}
                  />
                  <CustomButton
                    theme="green"
                    buttonOutLine={true}
                    title={"Delete"}
                    type={"submit"}
                  />
                </div>
              </div>
            </form>
          </div>
        </Grid.Column>
      </Grid>
    </CommonModal>
  );
};

export default ClientEditModal;
