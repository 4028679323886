import React, { useMemo } from "react";
import { Grid } from "semantic-ui-react";

import {
  InputText,
  CustomButton,
  DropDown,
  TitleView,
  SearchValue,
} from "../../../components";
import {
  DATA_TYPES,
  FARMING_TYPES,
  FIELD_TYPES,
  INGREDIENT_TYPE,
  UNIT_TYPES,
} from "../../../config/constants";
import { isEmpty } from "lodash";

const SupplerIngredientDataView = ({
  dataView = false,
  handleSubmit = () => {},
  register,
  ingredientType,
  setIngredientType,
  dataType,
  errors,
  country,
  countries,
  setCountry,
  isCountriesDataLoading,
  defaultType,
  setDefaultType,
  setDataType,
  produceType,
  produceTypes,
  setProduceType,
  isProduceTypesLoading,
  farmingType,
  fieldType,
  setFarmingType,
  setFieldType,
  disableEdit,
  errorsNewSupplier,
  ingredientSupplierList,
  supplierData,
  handleSearchValidation,
  getSearchValue,
  checkRawOrProcess,
  specifyIngredient,
  specifyIngredientValue,
  setSpecifyIngredientValue,
}: any) => {
  let processIngredient = checkRawOrProcess.processIngredient;
  let produceIngredient = checkRawOrProcess.produceIngredient;
  let previewData = useMemo(() => {
    return processIngredient || produceIngredient ? true : false;
  }, [ingredientType, dataType]);

  return (
    <>
      <form onSubmit={handleSubmit()}>
        <div style={{ marginBottom: 20 }}>
          <Grid>
            <>
              <Grid.Column
                computer={16}
                tablet={16}
                mobile={16}
                className="paddingRemoveVertical"
              >
                {disableEdit ? (
                  <InputText
                    register={register}
                    errors={errorsNewSupplier.supplier_name}
                    labelName={"Supplier Name*"}
                    placeholder="Type your supplier name"
                    name="supplier_name"
                    required={true}
                    errorMessage={"Please enter supplier name"}
                    disabled={true}
                  />
                ) : (
                  <SearchValue
                    searchDataValues={ingredientSupplierList}
                    defaultValue={supplierData?.supplier_name || ""}
                    checkOther={(status: any) => {
                      handleSearchValidation(status);
                    }}
                    selectDetails={getSearchValue}
                    title={"Supplier Name"}
                  />
                )}
              </Grid.Column>
              {!isEmpty(supplierData) ? (
                <>
                  <TitleView
                    CustomTitleViewMain="customTileViewHeader"
                    title="Ingredient information"
                  />
                  <Grid.Column
                    computer={8}
                    tablet={8}
                    mobile={16}
                    className="paddingRemoveTop"
                  >
                    <DropDown
                      labelName="Ingredient Type"
                      placeholder="Ingredient Type"
                      defaultValue={ingredientType}
                      currentData={INGREDIENT_TYPE}
                      handleChangeState={(e: any, { value }: any) => {
                        setIngredientType(value);
                      }}
                      search
                      key={"country"}
                      customGridColumn={"customGridColomnTyp"}
                      disabled={dataView}
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={8}
                    tablet={8}
                    mobile={16}
                    className="paddingRemoveTop"
                  >
                    <DropDown
                      labelName="Data Type"
                      placeholder="Select Type"
                      defaultValue={dataType}
                      currentData={DATA_TYPES}
                      customGridColumn={"customGridColomnTyp"}
                      handleChangeState={(e: any, { value }: any) => {
                        setDataType(value);
                      }}
                      disabled={dataView}
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={4}
                    tablet={8}
                    mobile={16}
                    className="paddingRemoveTop"
                  >
                    <InputText
                      register={register}
                      errors={errors.unit_size}
                      labelName={"Unit Size"}
                      placeholder="Unit Size"
                      name="unit_size"
                      required={true}
                      errorMessage={"Please enter unit size"}
                      type="number"
                      valueAsNumber={true}
                      step={0.0001}
                      disabled={dataView}
                    />
                  </Grid.Column>

                  <Grid.Column
                    computer={4}
                    tablet={8}
                    mobile={16}
                    className="paddingRemoveTop"
                  >
                    <DropDown
                      labelName={"Unit Type"}
                      currentData={UNIT_TYPES}
                      defaultValue={defaultType}
                      customGridColumn={"customGridColomnTyp"}
                      handleChangeState={(e: any, { value }: any) => {
                        setDefaultType(value);
                      }}
                      disabled={dataView}
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={4}
                    tablet={8}
                    mobile={16}
                    className="paddingRemoveTop"
                  >
                    <InputText
                      register={register}
                      errors={errors.ean_bar_code}
                      labelName={"EAN Barcode"}
                      placeholder="EAN Barcode"
                      name="ean_bar_code"
                      required={false}
                      disabled={dataView}
                    />
                  </Grid.Column>
                  {previewData ? (
                    <>
                      <TitleView
                        CustomTitleViewMain="customTileViewHeader"
                        title={
                          processIngredient
                            ? "Processed ingredient information"
                            : "Produce Information"
                        }
                      />
                      {produceIngredient ? (
                        <>
                          <Grid.Column
                            computer={4}
                            tablet={8}
                            mobile={16}
                            className="paddingRemoveTop"
                          >
                            <DropDown
                              labelName="Produce Type"
                              placeholder="Select Produce Type"
                              defaultValue={produceType}
                              currentData={produceTypes}
                              handleChangeState={(e: any, { value }: any) => {
                                setProduceType(value);
                              }}
                              loading={isProduceTypesLoading}
                              customGridColumn={"customGridColomnTyp"}
                              search
                              key={"produceType"}
                              disabled={dataView}
                            />
                          </Grid.Column>
                          <Grid.Column
                            computer={4}
                            tablet={8}
                            mobile={16}
                            className="paddingRemoveTop"
                          >
                            <InputText
                              register={register}
                              errors={errors.produceCultivar}
                              labelName={"Produce Cultivar"}
                              placeholder="Produce Cultivar"
                              name="produceCultivar"
                              required={false}
                              customGridColumn={"addCultivarCustomGridColumn"}
                              disabled={dataView}
                            />
                          </Grid.Column>

                          <Grid.Column
                            computer={4}
                            tablet={8}
                            mobile={16}
                            className="paddingRemoveTop"
                          >
                            <DropDown
                              labelName="Farming Type"
                              placeholder="Select Farming Type"
                              defaultValue={farmingType}
                              currentData={FARMING_TYPES}
                              customGridColumn={"customGridColomnTyp"}
                              handleChangeState={(e: any, { value }: any) => {
                                setFarmingType(value);
                              }}
                              disabled={dataView}
                            />
                          </Grid.Column>
                          <Grid.Column
                            computer={4}
                            tablet={8}
                            mobile={16}
                            className="paddingRemoveTop"
                          >
                            <DropDown
                              labelName="Field Type"
                              placeholder="Select Field Type"
                              defaultValue={fieldType}
                              currentData={FIELD_TYPES}
                              customGridColumn={"customGridColomnTyp"}
                              handleChangeState={(e: any, { value }: any) => {
                                setFieldType(value);
                              }}
                              disabled={dataView}
                            />
                          </Grid.Column>
                        </>
                      ) : null}

                      {processIngredient ? (
                        <Grid.Column
                          computer={8}
                          tablet={8}
                          mobile={16}
                          className="paddingRemoveTop"
                        >
                          <DropDown
                            labelName="Specify Ingredient"
                            placeholder="Specify Ingredient"
                            defaultValue={specifyIngredientValue}
                            currentData={specifyIngredient}
                            handleChangeState={(e: any, { value }: any) => {                              
                              setSpecifyIngredientValue(value);
                            }}
                            loading={isCountriesDataLoading}
                            search
                            key={"country"}
                            customGridColumn={"customGridColomnTyp"}
                            disabled={dataView}
                          />
                        </Grid.Column>
                      ) : null}

                      <Grid.Column
                        computer={4}
                        tablet={8}
                        mobile={16}
                        className="paddingRemoveTop"
                      >
                        <DropDown
                          labelName="Country"
                          placeholder="Select country"
                          defaultValue={country}
                          currentData={countries()}
                          handleChangeState={(e: any, { value }: any) => {
                            setCountry(value);
                          }}
                          loading={isCountriesDataLoading}
                          search
                          key={"country"}
                          customGridColumn={"customGridColomnTyp"}
                          disabled={dataView}
                        />
                      </Grid.Column>
                    </>
                  ) : null}
                </>
              ) : null}
            </>
            {!isEmpty(supplierData) ? (
              <Grid.Column
                computer={3}
                tablet={8}
                mobile={16}
                className="paddingRemoveTop"
              >
                {!dataView ? (
                  <CustomButton
                    theme="green"
                    customColumnStyle={"customIngredientUnitButton"}
                    title="ADD"
                    type="submit"
                  />
                ) : null}
              </Grid.Column>
            ) : null}
          </Grid>
        </div>
      </form>
    </>
  );
};

export default SupplerIngredientDataView;
