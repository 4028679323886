import { Grid, Table } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import {
  InputText,
  CustomButton,
  CommonModal,
  TitleView,
  CommonTable,
} from "../../../components";
import useWindowDimensions from "../../../hooks/windowsSize";
import {
  FacilityEnergyTableCol,
  FacilityHeatTable,
  FacilityHeatTableWithoutEdit,
  FacilityWaterTable,
  FacilityWaterTableWithoutEdit,
} from "../../../config/constants";
import "./facilityDetails.scss";

const FacilityDetailsModal = ({
  visibleModal,
  setVisibleModal,
  facilityData,
}: any) => {
  const { register } = useForm({
    values: facilityData,
  });
  const { height } = useWindowDimensions();

  const energy = facilityData?.energy || [];
  const heatSource = facilityData?.heatSource || [];
  const waterSource = facilityData?.waterSource || [];

  const loadTableData = () => {
    return energy.map((data: any, index: number) => {
      return (
        <Table.Row className="tbleR" key={index}>
          <Table.Cell>
            <p>{data?.EnergyType ?? ""}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data?.Price ?? 0.0}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data?.Percentage ?? ""}</p>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  const loadTableHeatData = () => {
    return heatSource.map((data: any, index: number) => {
      return (
        <Table.Row className="tbleR" key={index}>
          <Table.Cell>
            <p>{data?.HeatSource ?? ""}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data?.Volumes ?? ""}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data?.Price ?? ""}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data?.Percentage ?? ""}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data?.Year ?? ""}</p>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  const loadTableWaterData = () => {
    return waterSource.map((data: any, index: number) => {
      return (
        <Table.Row className="tbleR" key={index}>
          <Table.Cell>
            <p>{data?.WaterSource ?? ""}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data?.Volumes ?? ""}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data?.Price ?? ""}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data?.Year ?? ""}</p>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  return (
    <CommonModal
      modalOpenState={visibleModal}
      modalCloseState={() => setVisibleModal(false)}
      centered={true}
      customDimmer="customDimmerProduct"
      title={facilityData?.facilityName}
    >
      <Grid>
        <Grid.Column computer={16} tablet={16} mobile={16}>
          <div>
            <form>
              <div
                style={{
                  height: height * 0.9 - 180,
                  overflowX: "hidden",
                }}
              >
                <Grid>
                  <Grid.Column
                    computer={8}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <InputText
                      register={register}
                      labelName={"Facility Name"}
                      placeholder="Facility Name"
                      name="facilityName"
                      disabled={true}
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={8}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <InputText
                      register={register}
                      labelName={"Address"}
                      placeholder="Address"
                      name="facilityAddress"
                      disabled={true}
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={8}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <InputText
                      register={register}
                      labelName={"City"}
                      placeholder="City"
                      name="facilityCity"
                      disabled={true}
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={8}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <InputText
                      register={register}
                      labelName={"Country"}
                      placeholder="Country"
                      name="facilityCountry"
                      disabled={true}
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={8}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <InputText
                      register={register}
                      labelName={"Facility Type"}
                      placeholder="Facility Type"
                      name="facilityType"
                      disabled={true}
                    />
                  </Grid.Column>
                </Grid>
                {energy.length > 0 ? (
                  <TitleView
                    title="Energy Source"
                    CustomTitleViewMain="facilityViewEnergyTitle"
                  />
                ) : null}

                <Grid>
                  <Grid.Column
                    computer={16}
                    tablet={16}
                    mobile={16}
                    className="userBankDetailsMain "
                  >
                    <div>
                      <Grid.Column
                        computer={16}
                        tablet={16}
                        mobile={16}
                        className="productRegisterTable"
                      >
                        {energy.length > 0 && (
                          <CommonTable tableHeaderData={FacilityEnergyTableCol}>
                            {loadTableData()}
                          </CommonTable>
                        )}
                      </Grid.Column>
                    </div>
                  </Grid.Column>
                </Grid>
                {heatSource.length > 0 ? (
                  <TitleView
                    title="Heat Source"
                    CustomTitleViewMain="facilityViewEnergyTitle"
                  />
                ) : null}
                <Grid>
                  <Grid.Column
                    computer={16}
                    tablet={16}
                    mobile={16}
                    className="userBankDetailsMain "
                  >
                    <div>
                      <Grid.Column
                        computer={16}
                        tablet={16}
                        mobile={16}
                        className="productRegisterTable"
                      >
                        {heatSource.length > 0 && (
                          <CommonTable
                            tableHeaderData={FacilityHeatTableWithoutEdit}
                          >
                            {loadTableHeatData()}
                          </CommonTable>
                        )}
                      </Grid.Column>
                    </div>
                  </Grid.Column>
                </Grid>
                {waterSource.length > 0 ? (
                  <TitleView
                    title="Water Source"
                    CustomTitleViewMain="facilityViewEnergyTitle"
                  />
                ) : null}
                <Grid>
                  <Grid.Column
                    computer={16}
                    tablet={16}
                    mobile={16}
                    className="userBankDetailsMain "
                  >
                    <div>
                      <Grid.Column
                        computer={16}
                        tablet={16}
                        mobile={16}
                        className="productRegisterTable"
                      >
                        {waterSource.length > 0 && (
                          <CommonTable
                            tableHeaderData={FacilityWaterTableWithoutEdit}
                          >
                            {loadTableWaterData()}
                          </CommonTable>
                        )}
                      </Grid.Column>
                    </div>
                  </Grid.Column>
                </Grid>
              </div>

              <div
                style={{
                  height: height * 0.1,
                }}
                className="modalRegisterButtonMain"
              >
                <div className="modalRegisterButtonLeft">
                  <CustomButton
                    theme="green"
                    title={"Close"}
                    customColumnStyle={"cardInsertSaveOther"}
                    onClick={() => setVisibleModal(false)}
                  />
                </div>
              </div>
            </form>
          </div>
        </Grid.Column>
      </Grid>
    </CommonModal>
  );
};

export default FacilityDetailsModal;
