import React, { useEffect } from "react";
import { Grid, Table } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

import {
  InputText,
  InputTextArea,
  CommonTable,
  CommonModal,
  TitleView,
} from "../../../components";
import {
  ClientProductListCol,
} from "../../../config/constants";
import useWindowDimensions from "../../../hooks/windowsSize";

import "./ClientDetails.scss";

const ClientDetailsModal = ({ visibleModal, setVisibleModal }: any) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const { height } = useWindowDimensions();

  const onSubmit = (data: any) => {};

  let { aClientData } = useSelector(
    (state: RootState) => state.client
  );
  
  useEffect(() => {
    if(aClientData) {
      setValue('clientName', aClientData?.clientName)
      setValue('email', aClientData?.clientEmail)
      setValue('ContactNumber', aClientData?.clientPhone)
      setValue('address', aClientData?.clientAddress)
      setValue('city', aClientData?.clientCity)
      setValue('province', aClientData?.clientProvince)
      setValue('country', aClientData?.clientCountry)
      setValue('zipCode', aClientData?.clientZipCode)
      setValue('otherInfo', aClientData?.clientOtherInfo)
    }
  }, [aClientData, setValue])
  
  const loadTableData = () => {
    return aClientData?.product?.map((data: any, index: any) => {
      return (
        <Table.Row className="tbleR" key={index}>
          <Table.Cell>
            <p>{data?.productName}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data?.salesUnitName}</p>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  return (
    <CommonModal
      modalOpenState={visibleModal}
      modalCloseState={() => setVisibleModal(false)}
      centered={true}
      customDimmer="customDimmerProduct"
      title="Client Details"
    >
      <Grid>
        <Grid.Column computer={16} tablet={16} mobile={16}>
          <div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div
                style={{
                  height: height * 0.9 - 180,
                  overflow: "scroll",
                }}
              >
                <Grid>
                <Grid.Column
                    computer={8}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <InputText
                    disabled={true}
                      register={register}
                      errors={errors.clientName}
                      labelName={"Client Name"}
                      name="clientName"
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={8}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <InputText
                    disabled={true}
                      register={register}
                      errors={errors.email}
                      labelName={"Email"}
                      name="email"
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={8}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <InputText
                    disabled={true}
                      register={register}
                      errors={errors.ContactNumber}
                      labelName={"Contact Number"}
                      name="ContactNumber"
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={8}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <InputText
                    disabled={true}
                      register={register}
                      errors={errors.address}
                      labelName={"Address"}
                      name="address"
                    />
                  </Grid.Column>

                  <Grid.Column
                    computer={8}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <InputText
                    disabled={true}
                      register={register}
                      errors={errors.city}
                      labelName={"City"}
                      name="city"
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={8}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <InputText
                    disabled={true}
                      register={register}
                      errors={errors.province}
                      labelName={"Province"}
                      name="province"
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={8}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <InputText
                    disabled={true}
                      register={register}
                      errors={errors.country}
                      labelName={"Country"}
                      name="country"
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={8}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <InputText
                    disabled={true}
                      register={register}
                      errors={errors.zipCode}
                      labelName={"Zip Code"}
                      name="zipCode"
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={16}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <InputTextArea
                      register={register}
                      errors={errors.productName}
                      labelName={"Other Information"}
                      name="otherInfo"
                      customInputValidation={true}
                    />
                  </Grid.Column>
                </Grid>

                <TitleView
                  title="Product Details"
                  CustomTitleViewMain="supplierAddCustomIngredientTitle"
                />
                <Grid style={{ marginTop: "20px" }}>
                  <Grid.Column computer={16} tablet={16} mobile={16}>
                    <CommonTable tableHeaderData={ClientProductListCol}>
                      {loadTableData()}
                    </CommonTable>
                  </Grid.Column>
                </Grid>
              </div>
            </form>
          </div>
        </Grid.Column>
      </Grid>
    </CommonModal>
  );
};

export default ClientDetailsModal;
