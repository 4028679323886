import React, { useEffect, useState, useRef } from "react";
import { Grid } from "semantic-ui-react";

import { CommonModal } from "../../../components";
import useWindowDimensions from "../../../hooks/windowsSize";
import {
  useGetIngredientPackaging,
  useGetIngredientsSupplierList,
  useGetIngredientWholeList,
  useGetIngredientList
} from "../../../api/ingredients";
import "./IngredientRegister.scss";
import IngredientPackagingView from "./IngredientPackageing";
import IngredientDataRegister from "./IngredientDataRegister";
import SupplerRegister from "./SupplierRegister";

interface HandleValidation {
  resetSupplierData(): void;
}

const IngredientRegisterModalView = ({
  visibleModal,
  setVisibleModal,
  ingredientList,
  refetchIngredients
}: any) => {
  const { height } = useWindowDimensions();
  const [ingredientData, setIngredientData] = useState<any>(undefined);
  const [showSupplier, setShowSupplier] = useState<boolean>(false);
  const [loadPackageData, setLoadPackageData] = useState<any>(false);
  const [supplierData, setSupplierData] = useState<any>(undefined);
  const [hideIngredientView, setHideIngredientView] = useState<any>(false);
  const [viewPackage, setViewPackage] = useState<any>({
    processIngredient: false,
    produceIngredient: false,
    enableIngredient:false
  });

  const ref = useRef<HandleValidation>();
  const packageDataView =
    viewPackage.enableIngredient
      ? true
      : false;

  let ingredientId =
    ingredientData?.ingredient_data?.ingredient_id ||
    ingredientData?.ingredient_data?._id;
  let supplierId =
    supplierData?.supplier_data?.supplier_id ||
    supplierData?.supplier_data?._id;

  let getPackagingData = {
    ingredientId: ingredientId,
    supplierId: supplierId,
    loadPackageData: loadPackageData,
  };

  useEffect(() => {
    if (!visibleModal) {
      setIngredientData(undefined);
      setSupplierData(undefined);
      setLoadPackageData(false);
      setHideIngredientView(false);
    }
  }, [visibleModal]);

  const {
    data: ingredientListByProduct,
    refetch: ingredientListByProductRefetch,
  } = useGetIngredientWholeList(true);

  const {
    data: ingredientSupplierList,
    refetch: supplerListByProductRefetch,
  } = useGetIngredientsSupplierList();

  const {
    data: ingredientPackageData,
    refetch: ingredientPackageDataRefetch,
  } = useGetIngredientPackaging(getPackagingData);

  const handleCloseModal = () => {
    if (hideIngredientView) return;
    setVisibleModal(false);
  };

  return (
    <CommonModal
      modalOpenState={visibleModal}
      modalCloseState={() => {
        refetchIngredients()
        if (hideIngredientView) {
          ref?.current?.resetSupplierData();
        } else {
          handleCloseModal();
        }
      }}
      centered={true}
      customDimmer="customDimmerProduct"
      title="Add New Product Ingredient"
    >
      <Grid>
        <Grid.Column
          computer={16}
          tablet={16}
          mobile={16}
          className="userBankDetailsMain"
        >
          <div
            style={{
              height: height * 0.9 - 50,
              overflowX: "hidden",
            }}
          >
            {!hideIngredientView ? (
              <IngredientDataRegister
                ingredientListByProduct={ingredientListByProduct}
                ingredientRegisterData={setIngredientData}
                refetchIngredient={ingredientListByProductRefetch}
                currentIngredientData={ingredientData}
                showSupplier={setShowSupplier}
                setViewPackage={setViewPackage}
              />
            ) : null}
            
            {showSupplier ? (
              <SupplerRegister
                ref={ref}
                ingredientSupplierList={ingredientSupplierList}
                ingredientData={ingredientData}
                supplierCurrentData={() => {}}
                ingredientList={ingredientList}
                refetchSupplier={supplerListByProductRefetch}
                setIngredientData={setIngredientData}
                setSupplier={setSupplierData}
                loadPackageData={setLoadPackageData}
                hideIngredientView={(status: any) =>
                  setHideIngredientView(status)
                }
                changeDataIngredient={setViewPackage}
              />
            ) : null}
            {loadPackageData && packageDataView ? (
              <IngredientPackagingView
                ingredientId={ingredientId}
                supplierId={supplierId}
                refetchPackagingData={ingredientPackageDataRefetch}
                ingredientPackageData={ingredientPackageData}
              />
            ) : null}
          </div>
        </Grid.Column>
      </Grid>
    </CommonModal>
  );
};

export default IngredientRegisterModalView;
