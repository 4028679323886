import React, { useEffect, useState } from "react";
import Icon from "@mdi/react";
import {
  mdiFileSign,
  mdiFileSearchOutline,
  mdiPencil,
  mdiDeleteOutline,
} from "@mdi/js";
import {
  Grid,
  PaginationProps,
  Placeholder,
  PlaceholderLine,
  PlaceholderParagraph,
  Table,
} from "semantic-ui-react";

import "./simulationTable.scss";
import PercentageLabel from "./percentageLabel";
import TableFooter from "./tableFooter";

interface SimulationTableProps {
  data: SimulationData[];
  onEdit: (data: SimulationData, isView?: boolean) => void;
  onDelete: (data: SimulationData) => void;
  setPageNumber: (pageNumber: number) => void;
  setPageSize: (pageSize: number) => void;
  pageNumber: number;
  pageSize: number;
  totalSimulations: number;
  isGetProductSimulationsLoading: boolean;
}

const LoadingComponent = () => (
  <Placeholder fluid>
    <PlaceholderParagraph>
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
    </PlaceholderParagraph>
    <PlaceholderParagraph>
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
    </PlaceholderParagraph>
  </Placeholder>
);

const SimulationTable: React.FC<SimulationTableProps> = ({
  data,
  onEdit,
  onDelete,
  setPageNumber,
  setPageSize,
  pageNumber,
  pageSize,
  totalSimulations,
  isGetProductSimulationsLoading,
}) => {
  const [totalPages, setTotalPages] = useState(10);

  useEffect(() => {
    setTotalPages(Math.ceil(totalSimulations / pageSize));
  }, [totalSimulations, pageSize]);

  const handlePaginationChange = (
    e: any,
    { activePage, totalPages }: PaginationProps
  ) => {
    if (typeof activePage === "number") setPageNumber(activePage);
    if (typeof totalPages === "number") setTotalPages(totalPages);
  };

  const getCurrentImpact = (originalImpact:number, simulatedImpact:number) => {
    return Math.fround(((simulatedImpact-originalImpact)/originalImpact) * 100).toFixed(4);
  }
  return (
    <Table singleLine className="custom-table">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Category</Table.HeaderCell>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Original Impact (kgCO2 eq)</Table.HeaderCell>
          <Table.HeaderCell>Simulated Impact (kgCO2 eq)</Table.HeaderCell>
          <Table.HeaderCell>Impact Change %</Table.HeaderCell>
          {/* <Table.HeaderCell>Cost Impact %</Table.HeaderCell> */}
          <Table.HeaderCell>Simulation Result</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {isGetProductSimulationsLoading && (
          <Table.Row>
            <Table.Cell colSpan="7">
              <LoadingComponent />
            </Table.Cell>
          </Table.Row>
        )}
        {!isGetProductSimulationsLoading &&
          data.map((item: SimulationData, index) => (
            <Table.Row key={index}>
              <Table.Cell>{item.category}</Table.Cell>
              <Table.Cell>{item.simulationName}</Table.Cell>
              <Table.Cell>{item.originalImpact?.toLocaleString()}</Table.Cell>
              <Table.Cell>{item.simulatedImpact?.toLocaleString()}</Table.Cell>
              <Table.Cell>
                {getCurrentImpact(item.originalImpact, item.simulatedImpact)}
              </Table.Cell>
              {/* <Table.Cell>
                <PercentageLabel percentage={item.costImpact} />
              </Table.Cell> */}
              <Table.Cell>
                <Grid>
                  {/* <Grid.Column computer={3} tablet={3} mobile={3}>
                  <div onClick={() => {}}>
                    <Icon
                      className="table-icon"
                      color="var(--mainColor)"
                      path={mdiFileSign}
                      size={1.2}
                    />
                  </div>
                </Grid.Column> */}
                  <Grid.Column computer={3} tablet={3} mobile={3}>
                    <div
                      onClick={() => {
                        onEdit(item, true);
                      }}
                    >
                      <Icon
                        className="table-icon"
                        color="var(--mainColor)"
                        path={mdiFileSearchOutline}
                        size={1.2}
                      />
                    </div>
                  </Grid.Column>
                  <Grid.Column computer={3} tablet={3} mobile={3}>
                    <div
                      onClick={() => {
                        onEdit(item);
                      }}
                    >
                      <Icon
                        className="table-icon"
                        color="var(--mainColor)"
                        path={mdiPencil}
                        size={1.2}
                      />
                    </div>
                  </Grid.Column>
                  <Grid.Column computer={3} tablet={3} mobile={3}>
                    <div
                      onClick={() => {
                        onDelete(item);
                      }}
                    >
                      <Icon
                        className="table-icon"
                        color="var(--mainColor)"
                        path={mdiDeleteOutline}
                        size={1.2}
                      />
                    </div>
                  </Grid.Column>
                </Grid>
              </Table.Cell>
            </Table.Row>
          ))}
      </Table.Body>
      <Table.Footer fullWidth>
        <Table.Row>
          <Table.HeaderCell colSpan="7">
            <TableFooter
              activePage={pageNumber}
              totalPages={totalPages}
              handlePaginationChange={handlePaginationChange}
              pageSize={pageSize}
              changePageSize={setPageSize}
            />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
};

export default SimulationTable;
