import PackagingSimulation from "../screens/productSimulation/productSimulation";
import { adminAccess, userAccess } from "./useRoleAccess";

export const PackagingSimulationRoutes = [
  {
    path: "/packaging-simulation",
    route: <PackagingSimulation />,
    protectRoutes: true,
    roleGrantAccess: [...adminAccess, ...userAccess],
  },
];
