import React from "react";
import { Grid, Table } from "semantic-ui-react";
import {
  CommonTable,
  CustomButton,
  DropDown,
  TitleView,
} from "../../../components";
import { FacilityWaterTable } from "../../../config/constants";
import { MdDeleteOutline } from "react-icons/md";
import { InputNumber } from "../../../components/InputText/InputNumber";

import "./facilityForm.scss";
import { checkYearValidation } from "../../../utils/utils";

const FacilityWaterSource = ({
  waterType,
  defaultWaterType,
  setDefaultWaterType,
  errorsFacilityWater,
  handleSubmitFacilityWater,
  addFacilityWaterData,
  addFacilityWater,
  currentFacilityWaterData,
  setDeleteWaterData,
  setVisibleDeleteWaterModal,
  control,
}: any) => {
  const loadTableData = () => {
    return currentFacilityWaterData.map((data: any, index: number) => {
      return (
        <Table.Row className="tbleR" key={index}>
          <Table.Cell>
            <p>{data?.WaterSource ?? ""}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data?.Volumes ?? 0.0}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data?.Price ?? 0.0}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data?.Year ?? ""}</p>
          </Table.Cell>
          <Table.Cell>
            <Grid>
              <Grid.Column computer={4} tablet={16} mobile={16}>
                <MdDeleteOutline
                  cursor={"pointer"}
                  size={24}
                  color="var(--mainColor)"
                  onClick={() => {
                    setDeleteWaterData(data);
                    setVisibleDeleteWaterModal(true);
                  }}
                />
              </Grid.Column>
            </Grid>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  return (
    <>
      <TitleView title="Water source details" />
      <Grid>
        <Grid.Column
          computer={16}
          tablet={16}
          mobile={16}
          className="userBankDetailsMain "
        >
          <div>
            <Grid>
              <Grid.Column computer={3} tablet={8} mobile={16}>
                <DropDown
                  labelName={"Water Source"}
                  placeholder="Water Source"
                  currentData={waterType}
                  defaultValue={waterType?.[defaultWaterType].value || null}
                  customGridColumn={"customGridColomnType"}
                  handleChangeState={(e: any, { value }: any) => {
                    let getIndex = waterType?.findIndex(
                      (e: any) => e?.value === value
                    );
                    setDefaultWaterType(getIndex);
                  }}
                />
              </Grid.Column>
              <Grid.Column computer={3} tablet={8} mobile={16}>
                <InputNumber
                  control={control}
                  errors={errorsFacilityWater.Volumes}
                  labelName={"Volumes"}
                  placeholder="Volumes"
                  name="Volumes"
                  required={true}
                  errorMessage="Volumes is required"
                />
              </Grid.Column>
              <Grid.Column computer={3} tablet={8} mobile={16}>
                <InputNumber
                  control={control}
                  errors={errorsFacilityWater.Price}
                  labelName={"Price"}
                  placeholder="Price"
                  name="Price"
                  required={true}
                  errorMessage="Price is required"
                />
              </Grid.Column>
              <Grid.Column computer={2} tablet={8} mobile={16}>
                <InputNumber
                  control={control}
                  errors={errorsFacilityWater.Year}
                  labelName={"Year"}
                  placeholder="Year"
                  name="Year"
                  required={true}
                  errorMessage="Year is required"
                  otherValidation={{
                    validate: {
                      valueCheck: (v: any) =>
                        checkYearValidation(v) || "Please add valid year",
                    },
                  }}
                />
              </Grid.Column>
              <Grid.Column computer={2} tablet={8} mobile={16}>
                <CustomButton
                  theme="green"
                  customColumnStyle={"addUnitSize"}
                  icon="plus"
                  onClick={handleSubmitFacilityWater((data: any) => {
                    addFacilityWaterData(data);
                  })}
                />
              </Grid.Column>
            </Grid>
            <Grid.Column
              computer={16}
              tablet={16}
              mobile={16}
              className="productRegisterTable customTableBottom"
            >
              {addFacilityWater?.length > 0 && (
                <CommonTable tableHeaderData={FacilityWaterTable}>
                  {loadTableData()}
                </CommonTable>
              )}
            </Grid.Column>
          </div>
        </Grid.Column>
      </Grid>
    </>
  );
};

export default FacilityWaterSource;
