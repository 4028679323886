import React, { useEffect, useMemo, useState } from "react";
import { Grid, Table } from "semantic-ui-react";
import {
  CustomButton,
  CommonTable,
  InputText,
  CommonModal,
  TitleView,
} from "../../../components";

import useWindowDimensions from "../../../hooks/windowsSize";
import { usePostProcessGateRecipeData } from "../../../api/process";
import { useGetSuppliersByIds } from "../../../api/supplier";
import { errorView, successMessage } from "../../../helpers/ErrorHandler";
import { useForm } from "react-hook-form";

const RecipeModal = ({
  visibleModal,
  setVisibleModal,
  data,
  processId,
  recipeId,
  nodeItem,
  type
}: any) => {
  const values = useMemo(() => {
    return {
      recipeData: data,
      nodeItem,
      processId,
      recipeId,
      type,
    };
  }, [visibleModal]);
  const { height } = useWindowDimensions();

  const {
    register,
    reset,
    handleSubmit,
    getValues,
    setValue,
    setError,
    formState: { errors },
    control,
  } = useForm({
    mode: "all",
    defaultValues: values,
    values: values,
  });

  const [supplierIds, setSupplierIds] = useState<string[]>([]);
  const [processTotal, setProcessTotal] = useState<any>(0);
  const [ingredientTotal, setIngredientTotal] = useState<any>(0);
  const [validationError, setValidationError] = useState<{id: string, error: string}>({id: '', error: ''});

  const { mutate: postProcessGateRecipeData, isSuccess: isSuccessPostProcessGateRecipeData } = usePostProcessGateRecipeData();
  const suppliers = useGetSuppliersByIds(supplierIds);

  const onSubmitHandler = (data: any) => {
    const formData: any = getValues();
    let processList = [];
    let ingredientList = [];
    for (const key in formData) {
      if (key.includes("process") && key.split("-")[1]) {
        const processNode = values.recipeData?.processList?.find((process: any) => process.data?.reference?.processId === key.split("-")[1]);
        processList.push({
          id: key.split("-")[1],
          value: formData[key].value,
          parentNodeType: processNode?.parentNodeDetails?.type,
          parentNodeId: processNode?.parentNodeDetails?.processGateId || processNode?.parentNodeDetails?.farmGateId,
          previousComponentId: processNode?.previousComponentId,
          previousComponentType: processNode?.previousComponentType
        });
      } else if (key.includes("ingredient") && key.split("-")[2]) {
        const ingredientNode = values.recipeData?.ingredientList?.find((ingredient: any) => ingredient.data?.reference?.ingredientId === key.split("-")[2]);
        ingredientList.push({
          id: key.split("-")[2],
          value: formData[key].value,
          supplierId: key.split("-")[1],
          previousComponentId: ingredientNode?.previousComponentId,
          previousComponentType: ingredientNode?.previousComponentType
        });
      }
    }
    const recipeData = {
      processId,
      processList,
      ingredientList,
      type
    };

    postProcessGateRecipeData(recipeData);    
  };

  useEffect(() => {
    if (isSuccessPostProcessGateRecipeData) {
      successMessage("Recipe Added Successfully");
      setVisibleModal(false);
    }
  }, [isSuccessPostProcessGateRecipeData]);


  useEffect(() => {
    if (values.recipeData?.ingredientList?.length > 0) {
      values.recipeData?.ingredientList?.map((ingredient: any) => {
        setSupplierIds( ids => {
          if (ids) {
            return [...ids, ingredient?.data?.reference?.supplierId];
          }
          return [ingredient?.data?.reference?.supplierId];
        });
      });
    }
  }, [values]);

  const getSupplierName = (supplierData: any, ingredientId: string, supplierId: string) => {
    let supplierName = '';
    if (supplierData) {
      supplierData.map((supplier: any) => {
        if (supplier.isFetched && supplier.data._id === supplierId && supplier.data.ingredientIds.includes(ingredientId)) {
          supplierName = supplier.data.supplier_name;
        }
      });
    }
    return supplierName;
  }

  const validateTotalValue = (id: string, value: string) => {
    setValidationError({id: '', error: ''});
    if (isNaN(parseFloat(value))) {
      setValidationError({
        id,
        error: "Please enter a valid number",
      });
      const formData: any = getValues();
      for (const key in formData) {
        if (key.includes("process")) {
          setProcessTotal(0)
        } else if (key.includes("ingredient")) {
          setIngredientTotal(0)
        }
      }
      return;
    } else {
      const formData: any = getValues();
      let totalProcessValue = 0;
      let totalIngValue = 0;
      
      for (const key in formData) {
        if (key.includes("process") && key.split("-")[1] && formData[key].value) {
          totalProcessValue += parseFloat(formData[key].value);
          setProcessTotal(totalProcessValue)
        } else if (key.includes("ingredient") && key.split("-")[2] && formData[key].value) {
          totalIngValue += parseFloat(formData[key].value);
          setIngredientTotal(totalIngValue)
        }
      }
    }
  }

  const renderProcessListTable = (data: any) => {
    return (
      data.processList?.map((process: any) => {
        const processId =process.data?.reference?.processId
        return (
          <Table.Row className="tbleR" key={processId}>
            <Table.Cell>
              <p>{process?.data?.label}</p>
            </Table.Cell>
            <Table.Cell>
                <InputText
                  register={register}
                  name={`process-${processId}.value`}
                  //errors={errors?.[`process-${processId}.value`]}
                  control={control}
                  defaultValues={process?.value}
                  onChangeFunction={(e: any) => {
                    validateTotalValue(processId, e.target.value);
                  }}
                  valueAsNumber={true}
                />
                {validationError.id === processId && <span style={{ color: 'red'}}>{validationError.error}</span>}
              </Table.Cell>
          </Table.Row>
        );
      })
    )
  };

  return (
    <div>
      <CommonModal
        modalOpenState={visibleModal}
        modalCloseState={() => {
          setVisibleModal(false);
          setIngredientTotal(0)
          setProcessTotal(0)
          reset();
        }}
        centered={true}
        customDimmer="customDimmerProduct"
        title="Add Recipe"
      >
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <div style={{
            height: height * 0.8 - 90,
            overflowX: "hidden",
          }}
          >
            <Grid>
              {values.recipeData?.processList?.length > 0 && (
                  <>
                    <Grid.Row>
                    <Grid.Column width={16}>
                      <TitleView
                          CustomTextTitle="customT"
                          CustomTitleViewMain="recipeTitle"
                          title="Process (s)"
                        />
                    </Grid.Column>
                    <Grid.Column width={16}>
                      <CommonTable tableHeaderData={[{name: 'Name', col: 12}, {name: 'Value %', col: 4}]}>
                        {renderProcessListTable(values.recipeData)}
                      </CommonTable>
                    </Grid.Column>
                  </Grid.Row>
                </>
              )}
            </Grid>
            <Grid>
            {values.recipeData?.ingredientList?.length > 0 && (
                  <>
                    <Grid.Row>
                    <Grid.Column width={16}>
                      <TitleView
                          CustomTextTitle="customT"
                          CustomTitleViewMain="recipeTitle"
                          title="Ingredient (s)"
                        />
                    </Grid.Column>
                    <Grid.Column width={16}>
                      <CommonTable tableHeaderData={[{name: 'Name', col: 6},{ name: 'Supplier', col: 6}, {name: 'Value %', col: 4}]}>
                         {values.recipeData?.ingredientList?.map((ingredient: any, index: any) => {
                            return (
                              <Table.Row key={ingredient?.data?.reference?.ingredientId}>
                                <Table.Cell>
                                  <p>{ingredient?.data?.label}</p>
                                </Table.Cell>
                                <Table.Cell>
                                  <p>{getSupplierName(suppliers, ingredient?.data?.reference.ingredientId, ingredient?.data?.reference?.supplierId)}</p>
                                </Table.Cell>
                                <Table.Cell>
                                  <InputText
                                    register={register}
                                    name={`ingredient-${ingredient?.data?.reference?.supplierId}-${ingredient?.data?.reference?.ingredientId}.value`}
                                    control={control}
                                    defaultValues={ingredient?.value}
                                    onChangeFunction={(e: any) => {
                                      validateTotalValue(ingredient?.data?.reference?.ingredientId, e.target.value);
                                    }}
                                    valueAsNumber={false}
                                  />
                                  {validationError.id === ingredient?.data?.reference?.ingredientId && <span style={{ color: 'red'}}>{validationError.error}</span>}
                                </Table.Cell>
                              </Table.Row>
                            );
                          })}
                      </CommonTable>
                    </Grid.Column>
                  </Grid.Row>
                </>
              )}
            </Grid>
          </div>
          
          <div className="sipAddTotalError" >{processTotal > 100 ? <span style={{ color: 'red'}}>Sum of recipe process(es) value(s) should be less than or equal to 100%</span>
          : ingredientTotal > 100 ? <span style={{ color: 'red'}}>Sum of recipe ingredient(s) value(s) should be less than or equal to 100%</span> : 
          (Number(processTotal) + Number(ingredientTotal)) > 100 && <span style={{ color: 'red'}}>Sum of ingredient(s) value(s) and process(es) value(s) should be less than or equal to 100%</span>}</div>
          <div className="buttonGroup">
            <div className="sipCancel">
              <CustomButton
                onClick={() => {
                  setVisibleModal(false)}
                }
                title="Cancel"
                theme="green"
                buttonOutLine={true}
                disabled={Boolean(validationError.error)}
              />
            </div>
            <div className="sipAdd">
              <CustomButton
                type="submit"
                title="Save"
                theme="green"
                disabled={processTotal > 100 || ingredientTotal > 100 || (Number(processTotal) + Number(ingredientTotal)) > 100}
              />
            </div>
          </div>
        </form>
      </CommonModal>
    </div>
  );
};

export default RecipeModal;
