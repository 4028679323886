import {
  useContext,
  useState,
  useMemo,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { useForm } from "react-hook-form";
import { Grid, Dimmer, Loader } from "semantic-ui-react";
import { useReactFlow } from "reactflow";
import { DrawingContext } from "../../../DrawingTool";
import useWindowDimensions from "../../../../../hooks/windowsSize";
import {
  InputText,
  CustomButton,
  DropDown,
  ChemicalContentModal,
} from "../../../../../components";
import ConfirmModal from "../../../../../components/confirmViewModal/ConfirmModal";
import { SipContents } from "../../../../../config/constants";
import AddIngredientModal from "../../../../cultivation/processing/IngredientContentModal";
import SipRegisterModal from "../../../../cultivation/processing/SipContentModal";
import {
  useGetFacilityWholeList,
  useGetFarmGateProcessDataById,
  useGetProcessTypesWholeList,
  usePutFarmGateProcessData,
  usePostFarmGateProcessData,
  useDeleteFarmGateProcessData,
} from "../../../../../api/cultivations";
import { errorView, successMessage } from "../../../../../helpers/ErrorHandler";
import { InputNumber } from "../../../../../components/InputText/InputNumber";
import MainBottomButtonView from "../../../../../components/mainBottomButtonView/MainBottomButtonView";
import {
  useGetChemicalTypes,
  useGetWasteManagementTypes,
  useGetWasteManagementWasteTypes,
} from "../../../../../api/static-data";
import { useGetTransportTypesList } from "../../../../../api/cultivations";
import { findIncomingIngredientsAndProcess } from "../../../../../helpers/DrawingHelper";
import RecipeModal from "../../../../cultivation/processing/RecipeModal";
import { useGetProcessRecipeByProcessId } from "../../../../../api/process";
import { WasteManagementModal } from "../../../../../components/wasteManagment/WasteManagementModal";
import { valueIsZero } from "../../../../../utils/utils";
import { isEmpty } from "lodash";
import { removeNewDrawingItemStatus, saveNewDrawingItem } from "../../../../../utils/cacheStorage";

interface HandleValidation {
  checkCustomRequired(data?: any): void;
}

export const FarmGateProcessBase = ({ modalData }: any) => {
  const { farmGateId, ingredientId, supplierId, nodeItem, productId } =
    modalData;
  const { deleteElements } = useReactFlow();
  const {
    chartEdges,
    chartNodes,
    setNodeItem,
    setChartNodes,
    saveDrawing,
    setChartEdges,
  } = useContext(DrawingContext);
  // get chart node item from node id
  const processId = nodeItem?.data?.reference?.processId;
  const { height } = useWindowDimensions();
  const [viewIngredient, setViewIngredient] = useState(false);
  const [viewCipSip, setViewCipSip] = useState(false);
  const [viewChemical, setViewChemical] = useState(false);
  const [viewWasteManagement, setViewWasteManagement] = useState(false);
  const [facilityName, setFacilityName] = useState<any>();
  const [processingType, setProcessingType] = useState("");
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [viewRecipe, setViewRecipe] = useState(false);
  const [recipeData, setRecipeData] = useState({});
  const [isValidate, setIsValidate] = useState(false);
  const customCapacityValidation = useRef<HandleValidation>(null);
  const customInputValidation = useRef<HandleValidation>(null);
  const customOutputValidation = useRef<HandleValidation>(null);

  const { data: facilityWholeList, isSuccess: isFacilitySuccess } =
    useGetFacilityWholeList("FACTORY");
  const { data: processTypesWholeList } = useGetProcessTypesWholeList();
  const { mutate: postFarmGateProcessData } = usePostFarmGateProcessData();
  const { mutate: putFarmGateProcessData } = usePutFarmGateProcessData();
  const { mutate: deleteFarmGateProcessData } = useDeleteFarmGateProcessData();
  const { data, status, fetchStatus, refetch } =
    useGetFarmGateProcessDataById(processId);

  const chemicalData = data?.chemical || [];
  const wasteManagement = data?.wasteManagements || [];
  const [cipSipData, setCipSipData] = useState<any>([]);
  const [addIngredientActive, setAddIngredientActive] = useState(false);

  useEffect(() => {
    if (isFacilitySuccess) {
      setFacilityName(data?.facilityId || facilityWholeList?.[0]?.value);
      setValue("facilityName", data?.facilityId || facilityWholeList?.[0]?.value);
    }
  }, [isFacilitySuccess,data])

  const { data: chemicalDataType, isSuccess: chemicalDataLoad } =
    useGetChemicalTypes();

  const { data: wasteManagementType, isSuccess: wasteManagementTypeLoad } =
    useGetWasteManagementWasteTypes();

  const { data: TransportTypeList } = useGetTransportTypesList();

  const values = useMemo(() => {
    setCipSipData(data?.cipSip);
    setProcessingType(`${data?.processingType}`);
    setFacilityName(`${data?.facilityId}`);
    return {
      processingName: data?.processingName,
      processingType: data?.processingType,
      facilityName: data?.facilityId,
      machineModelNo: data?.machineModelNo,
      capacity: data?.capacity || 0,
      input: data?.input || 0,
      output: data?.output || 0,
      sideStream: data?.sideStream,
      heat: data?.heat || 0,
      electricity: data?.electricity || 0,
      water: data?.water || 0,
      citricAcid: data?.citricAcid,
    };
  }, [nodeItem, data]);

  const handleChemical = () => {
    if (processId) {
      setViewChemical(true);
    } else {
      errorView("Please save the process to add chemical details");
    }
  };

  const handleWasteManagement = () => {
    if (processId) {
      setViewWasteManagement(true);
    } else {
      errorView("Please save the process to add waste management details");
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    control,
  } = useForm({
    mode: "all",
    defaultValues: values,
    values: values,
  });

  const onSubmit = (data: any) => {
    const dataToCreate = {
      processingName: data?.processingName,
      ingredientId: ingredientId,
      supplierId: supplierId,
      processNumber: 1,
      facilityId: data?.facilityName,
      processingIngredient: [],
      cipSip: cipSipData,
      processingType: data?.processingType,
      machineModelNo: data?.machineModelNo,
      capacity: data?.capacity || 0,
      input: data?.input || 0,
      output: data?.output,
      sideStream: data?.sideStream,
      heat: data?.heat || 0,
      electricity: data?.electricity || 0,
      water: data?.water || 0,
      citricAcid: data?.citricAcid,
      processingStatus: "ACTIVE",
      farmGateId: farmGateId,
      processId: nodeItem?.data?.reference?.processId,
    };

    if (nodeItem?.data?.reference) {
      putFarmGateProcessData(dataToCreate, {
        onSuccess: (data: any) => {
          removeNewDrawingItemStatus()
          const updatedNode = {
            ...nodeItem,
            data: {
              ...nodeItem?.data,
              label: data?.processingName,
              // reference is mongoose mix type so you can set any type of here please set necessary reference only
              reference: {
                processId: data?._id,
              },
            },
          };
          const updatedChartNodes = chartNodes.map((n: any) => {
            if (n.id === nodeItem?.id) {
              return updatedNode;
            }
            return n;
          });
          setChartNodes(updatedChartNodes);
          setNodeItem(updatedNode);
          saveDrawing(chartEdges, updatedChartNodes);
          successMessage("Process updated successfully");
        },
      });
    } else {
      postFarmGateProcessData(dataToCreate, {
        onSuccess: (data: any) => {
          saveNewDrawingItem("created")
          const updatedNode = {
            ...nodeItem,
            data: {
              ...nodeItem?.data,
              label: data?.processingName,
              // reference is mongoose mix type so you can set any type of here please set necessary reference only
              reference: {
                processId: data?._id,
              },
            },
          };
          const updatedChartNodes = chartNodes.map((n: any) => {
            if (n.id === nodeItem?.id) {
              return updatedNode;
            }
            return n;
          });
          setChartNodes(updatedChartNodes);
          setNodeItem(updatedNode);
          saveDrawing(chartEdges, updatedChartNodes);
          successMessage("Process created successfully");
        },
      });
    }
  };

  const onNodesDelete = () => {
    deleteElements({ nodes: [{ id: nodeItem.id }] });
    setNodeItem(null);
  };

  const loadCIPData = () => {
    if (nodeItem?.data.reference && cipSipData?.length > 0) {
      const responseSipData = Object.keys(cipSipData?.[0]);
      const newSipContent: any = SipContents?.map((sip) => {

        const sipContent = responseSipData?.map((resSip) => {
          return (
            sip?.typeName == resSip && {
              ...sip,
              value: cipSipData?.[0][resSip],
            }
          );
        });
        return sipContent.filter((value) => value !== false);
      });
      newSipContent.push([{name: 'Frequency', value: cipSipData?.[0]?.frequency, unit: "", typeName: ""}])
      newSipContent.push([{name: 'Production Amount', value: cipSipData?.[0]?.productionAmount, unit: "", typeName: ""}])
      return newSipContent.flat(Infinity);
    } else {
      return SipContents;
    }
  };
  const { data: processRecipeData, refetch: refetchProcessRecipe } =
    useGetProcessRecipeByProcessId({
      type: "farm-gate",
      recipeId: data?.recipeId,
    });

  const handleRecipeModal = () => {
    let processList: any[] = [];
    let ingredientList: any[] = [];
    if (!processId) {
      errorView("Please save the process to add recipe");
      return;
    }
    try {
      const incomings = findIncomingIngredientsAndProcess(
        chartEdges,
        chartNodes,
        nodeItem.id,
        ingredientList,
        processList
      );
      let notSavedPreviousComponent = 0;
      if (
        incomings.ingredientList.length > 0 ||
        incomings.processList.length > 0
      ) {
        processList = incomings?.processList
          ?.map((item: any) => {
            const processMap = processRecipeData?.processList?.find(
              (process: any) => item?.data?.reference?.processId === process.id
            );
            if (item.previousComponentId === null) {
              notSavedPreviousComponent++;
            }

            if (processMap) {
              return {
                ...processMap,
                ...item,
              };
            }
            return item;
          })
          .filter(
            (item: any) => item?.data?.reference?.processId !== processId
          );

        let notSavedIngredientCount = 0;
        ingredientList = incomings?.ingredientList
          .map((item: any) => {
            const ingredientMap = processRecipeData?.ingredientList?.find(
              (ingredient: any) =>
                item?.data?.reference?.ingredientId === ingredient.id
                && item?.data?.reference?.supplierId === ingredient.supplierId
            );
            if (!item?.data?.reference?.supplierId) {
              notSavedIngredientCount++;
            }

            if (item.previousComponentId === null) {
              notSavedPreviousComponent++;
            }

            if (ingredientMap) {
              return {
                ...ingredientMap,
                ...item,
              };
            }
            return item;
          })
          .filter(
            (v: any, i: any, a: any) =>
              a.findIndex(
                (t: any) =>
                  t?.data?.reference?.ingredientId === v?.data?.reference?.ingredientId
                  && t?.data?.reference?.supplierId === v?.data?.reference?.supplierId
              ) === i
          );

        if (notSavedIngredientCount > 0) {
          errorView("Please save ingredient for this product");
          return;
        }

        if (notSavedPreviousComponent > 0) {
          errorView("Please save previous process for this recipe");
          return;
        }
        setViewRecipe(true);
        setRecipeData({
          ingredientList,
          processList,
          processId,
        });
      } else {
        errorView("Please create ingredient or process");
      }
    } catch (error) {
      errorView(error);
    }
  };

  if (
    nodeItem?.data.reference &&
    status == "loading" &&
    fetchStatus == "fetching"
  ) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  return (
    <Grid>
      <Grid.Column
        computer={16}
        tablet={16}
        mobile={16}
        className="userBankDetailsMain"
      >
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div
              style={{
                height: height * 0.9 - 100,
                overflowX: "hidden",
              }}
            >
              <Grid>
                <Grid.Column computer={8} tablet={8} mobile={16}>
                  <InputText
                    register={register}
                    errors={errors?.processingName}
                    labelName={"Processing Name*"}
                    placeholder="Processing Name"
                    name={`processingName`}
                    required={true}
                    errorMessage={"Please enter processing Name"}
                  />
                </Grid.Column>
                <Grid.Column computer={8} tablet={8} mobile={16}>
                  <DropDown
                    labelName={"Processing Type"}
                    placeholder="Select Type"
                    currentData={processTypesWholeList}
                    defaultValue={processingType}
                    customGridColumn={"customGridColomnTyp"}
                    handleChangeState={(e: any, { value }: any) => {
                      setProcessingType(value);
                      setValue("processingType", value);
                    }}
                  />
                </Grid.Column>
                <Grid.Column computer={8} tablet={8} mobile={16}>
                  <DropDown
                    labelName={"Facility Name*"}
                    placeholder="Select Facility Name"
                    currentData={facilityWholeList}
                    defaultValue={facilityName}
                    required
                    validate={isValidate}
                    errorMessage={"Facility name is required"}
                    customGridColumn={"customGridColomnTyp"}
                    handleChangeState={(e: any, { value }: any) => {
                      setFacilityName(value);
                      setValue("facilityName", value);
                    }}
                  />
                </Grid.Column>
                <Grid.Column computer={8} tablet={8} mobile={16}>
                  <InputText
                    register={register}
                    labelName={"Machine Model Number"}
                    placeholder="Machine Model Number"
                    name={`machineModelNo`}
                  />
                </Grid.Column>
                <Grid.Column computer={8} tablet={8} mobile={16}>
                  <InputNumber
                    ref={customCapacityValidation}
                    transform={{
                      input: (value: any) =>
                        isNaN(value) || value === 0 ? "" : value.toString(),
                      output: (e: any) => {
                        return e;
                      },
                    }}
                    control={control}
                    labelName={"Capacity(kg/hr)*"}
                    placeholder="Capacity(kg/hr)"
                    name={`capacity`}
                    valueAsNumber={true}
                    errors={errors?.capacity}
                    customRequired={true}
                    errorMessage="Capacity is required"
                  />
                </Grid.Column>
                <Grid.Column computer={8} tablet={8} mobile={16}>
                  <InputNumber
                    ref={customInputValidation}
                    transform={{
                      input: (value: any) =>
                        isNaN(value) || value === 0 ? "" : value.toString(),
                      output: (e: any) => {
                        return e;
                      },
                    }}
                    control={control}
                    labelName={"Input(kg)*"}
                    placeholder="Input(kg)"
                    name={`input`}
                    valueAsNumber={true}
                    errors={errors?.input}
                    customRequired={true}
                    errorMessage="Input is required"
                  />
                </Grid.Column>
                <Grid.Column computer={8} tablet={8} mobile={16}>
                  <InputNumber
                    ref={customOutputValidation}
                    transform={{
                      input: (value: any) =>
                        isNaN(value) || value === 0 ? "" : value.toString(),
                      output: (e: any) => {
                        return e;
                      },
                    }}
                    control={control}
                    labelName={"Output(kg)*"}
                    placeholder="Output(kg)"
                    name={`output`}
                    valueAsNumber={true}
                    errors={errors?.output}
                    customRequired={true}
                    errorMessage="Output is required"
                  />
                </Grid.Column>
                <Grid.Column computer={8} tablet={8} mobile={16}>
                  <InputNumber
                    transform={{
                      input: (value: any) =>
                        isNaN(value) || value === 0 ? "" : value.toString(),
                      output: (e: any) => {
                        return e;
                      },
                    }}
                    control={control}
                    labelName={"Waste/Side Stream (kg)"}
                    placeholder="Waste/Side Stream per kg"
                    name={`sideStream`}
                    valueAsNumber={true}
                  />
                </Grid.Column>

                <Grid.Column computer={8} tablet={8} mobile={16}>
                  <InputNumber
                    transform={{
                      input: (value: any) =>
                        isNaN(value) || value === 0 ? "" : value.toString(),
                      output: (e: any) => {
                        return e;
                      },
                    }}
                    control={control}
                    labelName={"Heat(MJ/kg)"}
                    placeholder="0"
                    name={`heat`}
                    valueAsNumber={true}
                  />
                </Grid.Column>

                <Grid.Column computer={8} tablet={8} mobile={16}>
                  <InputNumber
                    transform={{
                      input: (value: any) =>
                        isNaN(value) || value === 0 ? "" : value.toString(),
                      output: (e: any) => {
                        return e;
                      },
                    }}
                    control={control}
                    labelName={"Electricity(kWh/kg)"}
                    placeholder="0"
                    name={`electricity`}
                    valueAsNumber={true}
                  />
                </Grid.Column>
                <Grid.Column computer={8} tablet={8} mobile={16}>
                  <InputNumber
                    transform={{
                      input: (value: any) =>
                        isNaN(value) || value === 0 ? "" : value.toString(),
                      output: (e: any) => {
                        return e;
                      },
                    }}
                    control={control}
                    labelName={"Water(m3)"}
                    placeholder="0"
                    name={`water`}
                    valueAsNumber={true}
                  />
                </Grid.Column>
                <Grid.Column computer={8} tablet={8} mobile={16}></Grid.Column>
                {addIngredientActive && (
                  <Grid.Column computer={8} tablet={16} mobile={16}>
                    <label className={`inputTextLable`}>
                      Associate ingredients
                    </label>
                    <CustomButton
                      customColumnStyle="processingCustomColumnStyle"
                      customButtonStyle="customBtn"
                      theme="green"
                      title={`+ ${"  "}Add Ingredients`}
                      onClick={() => setViewIngredient(true)}
                    />
                  </Grid.Column>
                )}
                <Grid.Column computer={4} tablet={16} mobile={16}>
                  <CustomButton
                    customColumnStyle="processingCustomColumnStyle"
                    theme="green"
                    title="+ CIP / SIP"
                    customButtonStyle="customBtnCIP"
                    onClick={() => setViewCipSip(true)}
                  />
                </Grid.Column>
                <Grid.Column computer={4} tablet={16} mobile={16}>
                  <CustomButton
                    customColumnStyle="processingCustomColumnStyle"
                    theme="green"
                    title="+ Recipe"
                    customButtonStyle="customBtnCIP"
                    onClick={handleRecipeModal}
                  />
                </Grid.Column>
                <Grid.Column computer={4} tablet={16} mobile={16}>
                  <CustomButton
                    customColumnStyle="processingCustomColumnStyle"
                    theme="green"
                    title="+ Chemicals"
                    customButtonStyle="customBtnCIP"
                    onClick={() => handleChemical()}
                  />
                </Grid.Column>
                <Grid.Column computer={4} tablet={16} mobile={16}>
                  <CustomButton
                    customColumnStyle="processingCustomColumnStyle"
                    theme="green"
                    title="+ Waste/Side Stream"
                    customButtonStyle="customBtnCIP"
                    onClick={() => handleWasteManagement()}
                  />
                </Grid.Column>
              </Grid>
            </div>
            <MainBottomButtonView
              cancelStatus={true}
              deleteStatus={nodeItem?.data.reference}
              saveButtonStatus={true}
              saveButton={() => {
                customCapacityValidation.current?.checkCustomRequired(
                  getValues().capacity
                );
                customInputValidation.current?.checkCustomRequired(
                  getValues().input
                );
                customOutputValidation.current?.checkCustomRequired(
                  getValues().output
                );
              }}
              deleteButton={() => setVisibleDeleteModal(true)}
              cancelButton={() => setNodeItem(null)}
              saveTitle={nodeItem?.data.reference ? "Update" : "Save"}
              type="submit"
            />
          </form>
        </div>
      </Grid.Column>
      <AddIngredientModal
        visibleModal={viewIngredient}
        setVisibleModal={() => {
          setViewIngredient(false);
        }}
      />
      <SipRegisterModal
        visibleModal={viewCipSip}
        setVisibleModal={() => {
          setViewCipSip(false);
        }}
        getSipData={(data: any) => {
          setCipSipData(data);
        }}
        processCIPData={loadCIPData()}
      />
      <RecipeModal
        visibleModal={viewRecipe}
        setVisibleModal={() => {
          setViewRecipe(false);
        }}
        data={recipeData}
        processId={nodeItem?.data?.reference?.processId}
        nodeItem={nodeItem}
        recipeId={data?.recipeId}
        type="farm-gate"
        refetchProcessRecipe={refetchProcessRecipe}
      />
      <ChemicalContentModal
        visibleModal={viewChemical}
        setVisibleModal={() => {
          setViewChemical(false);
        }}
        chemicalDataType={chemicalDataType}
        processCIPData={() => { }}
        chemicalData={chemicalData}
        processId={processId}
        TransportTypeList={TransportTypeList}
        refetchChemical={refetch}
        type="farm"
      />
      <WasteManagementModal
        visibleModal={viewWasteManagement}
        wasteManagementType={wasteManagementType}
        wasteManagement={wasteManagement}
        processId={processId}
        sideStreamValue={values?.sideStream}
        setVisibleModal={() => {
          setViewWasteManagement(false);
        }}
        refetchWasteData={refetch}
        type="farm"
      />
      <ConfirmModal
        viewModal={visibleDeleteModal}
        closeModal={() => setVisibleDeleteModal(false)}
        cancel={() => {
          setVisibleDeleteModal(false);
        }}
        approve={() => {
          const dataToDelete = {
            farmGateId: farmGateId,
            processId: nodeItem?.data?.reference?.processId,
          };
          deleteFarmGateProcessData(dataToDelete, {
            onSuccess: (data: any) => {
              onNodesDelete();
              successMessage("Process deleted successfully");
            },
          });
          setVisibleDeleteModal(false);
        }}
        title={`Delete ${data?.processingName} Process`}
        subTitle={`Are you sure you want to remove the ${data?.processingName} farm gate process? Please consider you cannot recover these data after remove`}
      />
    </Grid>
  );
};
