import { Modal, Icon } from "semantic-ui-react";
import "./commonModal.scss";
import { ImageView } from "../ImageView/ImageView";
import { images } from "../../assets/images";

interface CommonModalProps {
  modalOpenState: boolean;
  modalCloseState: (state: boolean) => void;
  children: any;
  title?: string;
  size?: 'mini' | 'tiny' | 'small' | 'large' | 'fullscreen';
  centered?: boolean;
  customModalTitleMain?: string;
  customModalTitle?: string;
  customModalIcon?: string;
  customDimmer?: string;
  style?: any;
}

export const CommonModal: React.FC<CommonModalProps> = ({
  modalOpenState,
  modalCloseState,
  children,
  title,
  size = "small",
  centered = false,
  customModalTitleMain,
  customModalTitle,
  customModalIcon,
  customDimmer,
  style,
}: any) => {
  return (
    <Modal
      centered={centered}
      size={size}
      onClose={() => {
        modalCloseState(false);
      }}
      open={modalOpenState}
      className={`${customDimmer}`}
      style={{
        ...style,
      }}
    >
      <Modal.Content className="full-height">
        <Modal.Description className="modal-description">
          {title ? (
            <div className={`commonModalMain ${customModalTitleMain}`}>
              <h3 className={`commonModalTitle ${customModalTitle}`}>
                {title}
              </h3>
              <ImageView
                src={images.CloseIcon}
                customImageView="customCloseIconView"
                onClick={() => modalCloseState(false)}
              />
            </div>
          ) : null}
          {children}
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};
