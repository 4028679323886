import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Grid } from "semantic-ui-react";
import _ from "lodash";
import "./inputText.scss";
import { Controller } from "react-hook-form";

interface HandleValidation {
  checkCustomRequired(data: any): void;
}

interface OtherProps extends React.ComponentPropsWithoutRef<"button"> {
  computer?: any;
  tablet?: any;
  mobile?: any;
  name?: any;
  register?: any;
  errors?: any;
  control?: any;
  transform?: any;
  defaultValue?: any;
  customLabelText?: any;
  labelName?: any;
  customGridMain?: any;
  customGridColumn?: any;
  errorMessage?: any;
  customErrorText?: any;
  customText?: any;
  required?: any;
  otherValidation?: any;
  checkValidation?: any;
  enableMinus?: any;
  placeholder?: any;
  valueAsNumber?: any;
  customRequired?: any;
  customInputValidation?: any;
  type?: any;
  removeDecimal?:any
}

export const InputNumber = forwardRef<HandleValidation, OtherProps>(
  (props, ref) => {
    const {
      computer = undefined,
      tablet = undefined,
      mobile = undefined,
      name,
      errors,
      control,
      transform,
      defaultValue,
      customLabelText,
      labelName,
      customGridMain,
      customGridColumn,
      errorMessage,
      customErrorText,
      customText,
      required = false,
      otherValidation,
      checkValidation = false,
      enableMinus = false,
      placeholder = "",
      customRequired,
      removeDecimal=false
    } = props;

    const [customValidation, setCustomValidation] = useState(false);
    let errorViewStyle = "textInputFormError"
    let errorBottomStyle = "textInputError"

    useImperativeHandle(ref, () => ({
      checkCustomRequired(data: any) {
        if ((_.isEmpty(data?.toString()) || data == 0)  && customRequired) {
          setCustomValidation(true);
        } else {
          setCustomValidation(false);
        }
      },
    }));

    //check error message
    const checkErrorMessage = (errors: any) => {
      switch (errors.type) {
        case "required":
          return errorMessage;
        default:
          return errors.message;
      }
    };

    //check error message
    const checkError = (errors: any) => {
      if (errors) {
        errorViewStyle = "textInputFormError"
        errorBottomStyle = "textInputError"
        return true;
      } else if (customValidation) {
        errorViewStyle = "textInputFormCustomError"
        errorBottomStyle = "textInputCustomError"
        return true;
      } else {
        errorViewStyle = "textInputFormError"
        errorBottomStyle = "textInputError"
        return false;
      }
    };

    //check error number
    const checkNumber = () => {
      if (checkValidation) {
        return transform;
      } else {
        return {
          input: (values: any) => {
            if (enableMinus) {
              let value = values || "";
              if (value?.replace || undefined) {
                value = value?.replace(/[^0-9-]+/g, "");
                var pattern = /([-])?([0-9]+)/g;
                var matches = value.match(pattern) || value;
                if (matches) {
                  value = matches[0];
                  return value?.toString();
                } else {
                  return "";
                }
              } else {
                return value;
              }
            } else if(removeDecimal){
              let value = values || "";
              if (value?.replace || undefined) {
                value = value?.replace(/[^0-9-]+/g, "");
                var pattern = /^\d*$/g;
                var matches = value.match(pattern) || value;
                if (matches) {
                  value = matches[0];
                  return value?.toString();
                } else {
                  return "";
                }
              } else {
                return value;
              }
            } else{
              if (isNaN(parseInt(values)) || values === 0) {
                return "";
              } else {
                return values?.toString();
              }
            }
          },
          output: (e: any) => {
            if (enableMinus) {
              return e;
            } else {
              if (!isNaN(e.target.value)) {
                return e;
              }
            }
          },
        };
      }
    };

    //check input change
    const inputOnchangeValue = (e: any, field: any) => {
      field.onChange(
        checkValidation ? transform.output(e) : checkNumber().output(e)
      );
    };

    //return input changes
    const returnValue = (field: any) => {
      return checkValidation
        ? transform.input(field.value)
        : checkNumber().input(field.value);
    };

    return (
      <>
        <label className={`inputTextLable ${customLabelText}`}>
          {labelName}
        </label>
        <Grid className={`${customGridMain}`}>
          <Grid.Column
            computer={computer}
            tablet={tablet}
            mobile={mobile}
            className={`inputTextContanier ${customGridColumn}`}
          >
            <Controller
              defaultValue={defaultValue}
              control={control}
              rules={{
                required: {
                  value: required,
                  message: errorMessage,
                },
                ...otherValidation,
              }}
              name={name}
              render={({ field }) => (
                <input
                  className={
                    checkError(errors)
                      ? `${errorViewStyle} ${customText}`
                      : `textInputFormText ${customText}`
                  }
                  onChange={(e) => inputOnchangeValue(e, field)}
                  value={returnValue(field)}
                  placeholder={placeholder}
                />
              )}
            />

            {checkError(errors) && (
              <span className={`${errorBottomStyle} ${customErrorText}`}>
                {customValidation && customRequired
                  ? errorMessage
                  : checkErrorMessage(errors)}
              </span>
            )}
          </Grid.Column>
        </Grid>
      </>
    );
  }
);
