import React, { useEffect, useMemo, useState } from "react";
import { Grid, Table } from "semantic-ui-react";
import {
  CustomButton,
  CommonTable,
  InputText,
  CommonModal,
  TitleView,
} from "../../../components";

import useWindowDimensions from "../../../hooks/windowsSize";
import { usePostProcessGateRecipeData } from "../../../api/process";
import {  successMessage } from "../../../helpers/ErrorHandler";
import { useForm } from "react-hook-form";
import { useGetSupplierRawMaterialsById } from "../../../api/supplierRawMaterials";

const SupplierRecipeModal = ({
  visibleModal,
  setVisibleModal,
  data,
  processId,
  recipeId,
  nodeItem,
  type,
}: any) => {
  const values = useMemo(() => {
    return {
      recipeData: data,
      nodeItem,
      processId,
      recipeId,
      type,
    };
  }, [visibleModal]);
  const { height } = useWindowDimensions();

  const {
    register,
    reset,
    handleSubmit,
    getValues,
    setValue,
    setError,
    formState: { errors },
    control,
  } = useForm({
    mode: "all",
    defaultValues: values,
    values: values,
  });

  const [supplierIds, setSupplierIds] = useState<string[]>([]);
  const [validationError, setValidationError] = useState<{
    id: string;
    error: string;
  }>({ id: "", error: "" });

  const {
    mutate: postProcessGateRecipeData,
    isSuccess: isSuccessPostProcessGateRecipeData,
  } = usePostProcessGateRecipeData();
  const suppliers = useGetSupplierRawMaterialsById(supplierIds);

  const onSubmitHandler = (data: any) => {
    const formData: any = getValues();
    let processList = [];
    let ingredientList = [];

    for (const key in formData) {
      if (key.includes("process") && key.split("-")[1]) {
        processList.push({
          id: key.split("-")[1],
          value: formData[key].value ? formData[key].value : 0,
        });
      } else if (key.includes("ingredient") && key.split("-")[1]) {
        ingredientList.push({
          id: key.split("-")[1],
          value: formData[key].value ? formData[key].value : 0,
        });
      }
    }
    const recipeData = {
      processId,
      processList,
      ingredientList,
      type,
    };

    postProcessGateRecipeData(recipeData);
  };

  useEffect(() => {
    if (isSuccessPostProcessGateRecipeData) {
      successMessage("Recipe Added Successfully");
      setVisibleModal(false);
    }
  }, [isSuccessPostProcessGateRecipeData]);

  useEffect(() => {
    if (values.recipeData?.ingredientList?.length > 0) {
      values.recipeData?.ingredientList?.map((ingredient: any) => {
        setSupplierIds((ids) => {
          if (ids) {
            return [ingredient?.data?.reference?.supplierRawMaterialId];
          }
          return [ingredient?.data?.reference?.supplierRawMaterialId];
        });
      });
    }
  }, [values]);

  const validateTotalValue = (id: string, value: string) => {
    setValidationError({ id: "", error: "" });
    if (isNaN(parseFloat(value))) {
      setValidationError({
        id,
        error: "Please enter a valid number",
      });
      return;
    } else {
      const formData: any = getValues();
      let totalValue = 0;
      for (const key in formData) {
        if (key.includes("process") && key.split("-")[1]) {
          totalValue += parseFloat(formData[key].value);
        } else if (key.includes("ingredient") && key.split("-")[1]) {
          totalValue += parseFloat(formData[key].value);
        }
      }

      totalValue += parseFloat(value);
      if (totalValue > 101) {
        setValidationError({
          id,
          error: "Sum of recipe values less than or equal to 100%",
        });
      }
    }
  };

  const renderProcessListTable = (data: any) => {
    return data.processList?.map((process: any) => {
      const processId = process.data?.reference?.processId;
      return (
        <Table.Row className="tbleR" key={processId}>
          <Table.Cell>
            <p>{process?.data?.label}</p>
          </Table.Cell>
          <Table.Cell>
            <InputText
              register={register}
              name={`process-${processId}.value`}
              control={control}
              defaultValues={process?.value}
              onChangeFunction={(e: any) => {
                validateTotalValue(processId, e.target.value);
              }}
              valueAsNumber={true}
            />
            {validationError.id === processId && (
              <span style={{ color: "red" }}>{validationError.error}</span>
            )}
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  return (
    <div>
      <CommonModal
        modalOpenState={visibleModal}
        modalCloseState={() => {
          setVisibleModal(false);
          reset();
        }}
        centered={true}
        customDimmer="customDimmerProduct"
        title="Add Recipe"
      >
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <div
            style={{
              height: height * 0.8 - 90,
              overflowX: "hidden",
            }}
          >
            <Grid>
              {values.recipeData?.processList?.length > 0 && (
                <>
                  <Grid.Row>
                    <Grid.Column width={16}>
                      <TitleView
                        CustomTextTitle="customT"
                        CustomTitleViewMain="recipeTitle"
                        title="Process (s)"
                      />
                    </Grid.Column>
                    <Grid.Column width={16}>
                      <CommonTable
                        tableHeaderData={[
                          { name: "Name", col: 12 },
                          { name: "Value %", col: 4 },
                        ]}
                      >
                        {renderProcessListTable(values.recipeData)}
                      </CommonTable>
                    </Grid.Column>
                  </Grid.Row>
                </>
              )}
            </Grid>
            <Grid>
              {values.recipeData?.ingredientList?.length > 0 && (
                <>
                  <Grid.Row>
                    <Grid.Column width={16}>
                      <TitleView
                        CustomTextTitle="customT"
                        CustomTitleViewMain="recipeTitle"
                        title="Ingredient (s)"
                      />
                    </Grid.Column>
                    <Grid.Column width={16}>
                      <CommonTable
                        tableHeaderData={[
                          { name: "Name", col: 6 },
                          { name: "Supplier", col: 6 },
                          { name: "Value %", col: 4 },
                        ]}
                      >
                        {values.recipeData?.ingredientList?.map(
                          (ingredient: any, index: any) => {
                            return (
                              <Table.Row
                                key={
                                  ingredient?.data?.reference
                                    ?.supplierRawMaterialId
                                }
                              >
                                <Table.Cell>
                                  <p>{ingredient?.data?.label}</p>
                                </Table.Cell>
                                <Table.Cell>
                                  <p>{suppliers?.data?.supplierName}</p>
                                </Table.Cell>
                                <Table.Cell>
                                  <InputText
                                    register={register}
                                    name={`ingredient-${ingredient?.data?.reference?.supplierRawMaterialId}.value`}
                                    control={control}
                                    defaultValues={ingredient?.value}
                                    onChangeFunction={(e: any) => {
                                      validateTotalValue(
                                        ingredient?.data?.reference
                                          ?.supplierRawMaterialId,
                                        e.target.value
                                      );
                                    }}
                                    valueAsNumber={false}
                                  />
                                  {validationError.id ===
                                    ingredient?.data?.reference
                                      ?.ingredientId && (
                                    <span style={{ color: "red" }}>
                                      {validationError.error}
                                    </span>
                                  )}
                                </Table.Cell>
                              </Table.Row>
                            );
                          }
                        )}
                      </CommonTable>
                    </Grid.Column>
                  </Grid.Row>
                </>
              )}
            </Grid>
          </div>
          <div className="buttonGroup">
            <div className="sipCancel">
              <CustomButton
                onClick={() => {
                  setVisibleModal(false);
                }}
                title="Cancel"
                theme="green"
                buttonOutLine={true}
                disabled={Boolean(validationError.error)}
              />
            </div>
            <div className="sipAdd">
              <CustomButton type="submit" title="Save" theme="green" />
            </div>
          </div>
        </form>
      </CommonModal>
    </div>
  );
};

export default SupplierRecipeModal;
