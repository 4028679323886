import React, { useEffect, useMemo, useState } from "react";
import { Grid, Table } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  CommonTable,
  InputText,
  CustomButton,
  SearchValue,
  Switch,
  CommonModal,
  DropDown,
  TitleView,
} from "../../../components";
import {
  FieldConfirmCol,
  EnergyConfirmCol,
  PackagingConfirmCol,
  ProcessingConfirmCol,
  StorageConfirmCol,
  TransportationConfirmCol,
  PesticideCol,
  TransportationExternal,
  PackagingMachineConfirmCol,
} from "../../../config/constants";
import useWindowDimensions from "../../../hooks/windowsSize";

import "./cultivationDetails.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { useAppDispatch } from "../../../store/hooks";
import { getCultivationDataById } from "../../../store/actions/cultivation";
import { isEmpty } from "lodash";
import { InputNumber } from "../../../components/InputText/InputNumber";
import CultivationConfirmModal from "../cultivationConfirmation/cultivationConfirmation";
import { useCalculateData } from "../../../api/cultivations";

const CultivationDetailsModal = ({
  visibleModal,
  setVisibleModal,
  cultivationId,
}: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showIngredientModal, setShowIngredientModal] = useState(false);
  const { height } = useWindowDimensions();
  let { cultivationData } = useSelector(
    (state: RootState) => state.cultivation
  );

  useEffect(() => {
    if (!isEmpty(cultivationId)) {
      dispatch(getCultivationDataById(cultivationId));
    }
  }, []);

  const values = useMemo(() => {
    return {
      ingredientName: cultivationData?.ingredientName || "",
      produceCultivar: cultivationData?.produceCultivar || "",
      produceType: cultivationData?.produceType || "",
      originCountry: cultivationData?.originCountry || "",
      farmingField: cultivationData?.farmingField || "Open Field",
      farmingType: cultivationData?.farmingType || "Organic",
      harvestSeason: cultivationData?.cultivationField?.harvestSeason || "",
      harvestYear: cultivationData?.cultivationField?.harvestYear || "",
      yield: cultivationData?.cultivationField?.yield || "",
      growthArea: cultivationData?.cultivationField?.growthArea || "",
    };
  }, [cultivationData]);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: values,
    values: values,
  });

  const loadFieldTableData = () => {
    return cultivationData?.cultivationField?.fertilizerList?.map(
      (data: any, index: any) => {
        return (
          <Table.Row className="tbleR" key={index}>
            <Table.Cell>
              <p>{data.fertilizerName}</p>
            </Table.Cell>
            <Table.Cell>
              <p>{data.fertilizerQuantity}</p>
            </Table.Cell>
          </Table.Row>
        );
      }
    );
  };

  const loadPesticideTableData = () => {
    return cultivationData?.cultivationField?.persticideList?.map(
      (data: any, index: any) => {
        return (
          <Table.Row className="tbleR" key={index}>
            <Table.Cell>
              <p>{data.pesticideName}</p>
            </Table.Cell>
            <Table.Cell>
              <p>{data.presticideQuantity}</p>
            </Table.Cell>
          </Table.Row>
        );
      }
    );
  };

  const loadPackagingTableData = () => {
    return cultivationData?.packaging?.map((data: any, index: any) => {
      return (
        <Table.Row className="tbleR" key={index}>
          <Table.Cell>
            <p>{data.packageName}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data.meaterialWeight}</p>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  const loadPackagingMachineTableData = () => {
    return cultivationData?.packagingMethod?.map((data: any, index: any) => {
      return (
        <Table.Row className="tbleR" key={index}>
          <Table.Cell>
            <p>{data.packagingMethod}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data.energy}</p>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  const loadProcessTableData = () => {
    return cultivationData?.processing?.map((data: any, index: any) => {
      return (
        <Table.Row className="tbleR" key={index}>
          <Table.Cell>
            <p>{data.processingName}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data.processingType}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data.machineModelNo}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data.facilityId}</p>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  const loadEnergyTableData = () => {
    return cultivationData?.energy?.map((data: any, index: any) => {
      return (
        <Table.Row className="tbleR" key={index}>
          <Table.Cell>
            <p>{data.type}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data.precentage}</p>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  const loadStorageTableData = () => {
    return cultivationData?.storage?.map((data: any, index: any) => {
      return (
        <Table.Row className="tbleR" key={index}>
          <Table.Cell>
            <p>{data.warehouseName}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data.warehouseSize}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data.weight}</p>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  const loadInternalTableData = () => {
    return cultivationData?.internalTransportation?.map(
      (data: any, index: any) => {
        return (
          <Table.Row className="tbleR" key={index}>
            <Table.Cell>
              <p>{data.transportType}</p>
            </Table.Cell>
            <Table.Cell>
              <p>{data.averageDistance}</p>
            </Table.Cell>
          </Table.Row>
        );
      }
    );
  };

  const loadExternalTableData = () => {
    return cultivationData?.externalTransportation?.map(
      (data: any, index: any) => {
        return (
          <Table.Row className="tbleR" key={index}>
            <Table.Cell>
              <p>{data.transportType}</p>
            </Table.Cell>
            <Table.Cell>
              <p>{data.distance}</p>
            </Table.Cell>
          </Table.Row>
        );
      }
    );
  };

  const { mutate } = useCalculateData();

  return (
    <>
      <CommonModal
        modalOpenState={visibleModal}
        modalCloseState={() => setVisibleModal(false)}
        centered={true}
        customDimmer="customDimmerProduct"
        title="Cultivation Detail"
      >
        <div
          style={{
            height: height * 0.9 - 180,
            overflowX: "hidden",
          }}
        >
          <Grid>
            <Grid.Column
              computer={8}
              tablet={8}
              mobile={16}
              className="paddingRemoveBottom"
            >
              <InputText
                register={register}
                labelName={"ingredientName"}
                placeholder="ingredientName"
                name="ingredientName"
                disabled={true}
              />
            </Grid.Column>
          </Grid>

          <Grid>
            <Grid.Column
              computer={8}
              tablet={8}
              mobile={16}
              className="paddingRemoveVertical"
            >
              <InputText
                register={register}
                labelName={"Produce Type"}
                placeholder="Produce Type"
                name="produceType"
                disabled={true}
              />
            </Grid.Column>
            <Grid.Column
              computer={8}
              tablet={8}
              mobile={8}
              className="paddingRemoveVertical"
            >
              <InputText
                register={register}
                labelName={"Produce Cultivar"}
                placeholder="Produce Cultivar"
                name="produceCultivar"
                disabled={true}
              />
            </Grid.Column>
            <Grid.Column
              computer={16}
              tablet={16}
              mobile={16}
              className="paddingRemoveVertical"
            >
              <InputText
                register={register}
                errors={errors.originCountry}
                labelName={"Origin Country"}
                placeholder="Origin Country"
                name="originCountry"
                required={false}
                disabled={true}
              />
            </Grid.Column>
            <Grid.Column
              computer={8}
              tablet={8}
              mobile={16}
              className="paddingRemoveVertical"
            >
              <Switch
                label={"Farming Type"}
                titleOne={"Organic"}
                titleTwo={"Conventional"}
                titleOneValue={"Organic"}
                titleTwoValue={"Conventional"}
                defaultValue={getValues().farmingType}
                onClick={undefined}
                disabled={true}
              />
            </Grid.Column>
            <Grid.Column
              computer={8}
              tablet={8}
              mobile={16}
              className="paddingRemoveVertical"
            >
              <Switch
                label={"Farming Field"}
                titleOne={"Open field"}
                titleTwo={"Greenhouse"}
                titleOneValue={"Open Field"}
                titleTwoValue={"Green House"}
                defaultValue={getValues().farmingField}
                onClick={undefined}
                disabled={true}
              />
            </Grid.Column>
          </Grid>

          <Grid style={{ width: "100%" }}>
            <Grid.Column computer={4} tablet={8} mobile={16}>
              <InputText
                register={register}
                labelName={"Yield"}
                placeholder="Yield"
                name="yield"
                disabled={true}
              />
            </Grid.Column>
            <Grid.Column computer={4} tablet={8} mobile={16}>
              <InputText
                register={register}
                labelName={"Growth area / Ha"}
                placeholder="Growth area / Ha"
                name="growthArea"
                disabled={true}
              />
            </Grid.Column>
            <Grid.Column computer={4} tablet={8} mobile={16}>
              <InputText
                register={register}
                placeholder="Growth area / Ha"
                name="harvestYear"
                labelName={"Harvest year"}
                disabled={true}
              />
            </Grid.Column>
            <Grid.Column computer={4} tablet={8} mobile={16}>
              <InputText
                register={register}
                placeholder="Harvest season"
                name="harvestSeason"
                labelName={"Harvest season"}
                disabled={true}
              />
            </Grid.Column>
          </Grid>

          {cultivationData?.cultivationField?.fertilizerList && (
            <>
              <Grid>
                <Grid.Column computer={16} tablet={8} mobile={16}>
                  <TitleView title="Fertilizer" />
                  <CommonTable tableHeaderData={FieldConfirmCol}>
                    {loadFieldTableData()}
                  </CommonTable>
                </Grid.Column>
              </Grid>
            </>
          )}

          {cultivationData?.cultivationField?.persticideList && (
            <>
              <Grid>
                <Grid.Column computer={16} tablet={8} mobile={16}>
                  <TitleView title="Persticide" />
                  <CommonTable tableHeaderData={PesticideCol}>
                    {loadPesticideTableData()}
                  </CommonTable>
                </Grid.Column>
              </Grid>
            </>
          )}

          {cultivationData?.packaging && (
            <Grid>
              <Grid.Column computer={16} tablet={16} mobile={16}>
                <TitleView title="Packaging" />
                <CommonTable tableHeaderData={PackagingConfirmCol}>
                  {loadPackagingTableData()}
                </CommonTable>
              </Grid.Column>
            </Grid>
          )}

          {cultivationData?.packagingMethod && (
            <Grid>
              <Grid.Column computer={16} tablet={16} mobile={16}>
                <TitleView title="Packaging Machine" />
                <CommonTable tableHeaderData={PackagingMachineConfirmCol}>
                  {loadPackagingMachineTableData()}
                </CommonTable>
              </Grid.Column>
            </Grid>
          )}

          {cultivationData?.processing && (
            <Grid>
              <Grid.Column computer={16} tablet={16} mobile={16}>
                <TitleView title="Processing" />
                <CommonTable tableHeaderData={ProcessingConfirmCol}>
                  {loadProcessTableData()}
                </CommonTable>
              </Grid.Column>
            </Grid>
          )}

          {cultivationData?.energy && (
            <Grid>
              <Grid.Column computer={16} tablet={16} mobile={16}>
                <TitleView title="Energy" />
                <CommonTable tableHeaderData={EnergyConfirmCol}>
                  {loadEnergyTableData()}
                </CommonTable>
              </Grid.Column>
            </Grid>
          )}

          {cultivationData?.storage && (
            <Grid>
              <Grid.Column computer={16} tablet={16} mobile={16}>
                <TitleView title="Storage" />
                <CommonTable tableHeaderData={StorageConfirmCol}>
                  {loadStorageTableData()}
                </CommonTable>
              </Grid.Column>
            </Grid>
          )}

          {cultivationData?.internalTransportation && (
            <Grid>
              <Grid.Column computer={16} tablet={16} mobile={16}>
                <TitleView title="Internal Transportation" />
                <CommonTable tableHeaderData={TransportationConfirmCol}>
                  {loadInternalTableData()}
                </CommonTable>
              </Grid.Column>
            </Grid>
          )}

          {cultivationData?.externalTransportation && (
            <Grid>
              <Grid.Column computer={16} tablet={16} mobile={16}>
                <TitleView title="External Transportation" />
                <CommonTable tableHeaderData={TransportationExternal}>
                  {loadExternalTableData()}
                </CommonTable>
              </Grid.Column>
            </Grid>
          )}
        </div>

        <div
          style={{
            height: height * 0.1,
          }}
          className="modalRegisterButtonMain"
        >
          <div className="modalRegisterButtonLeft">
            <CustomButton
              theme="green"
              title={"Add Next Ingredient"}
              onClick={() => navigate("/ingredient")}
              customColumnStyle={"customRecipeButton"}
              type={"submit"}
            />
            <CustomButton
              theme="green"
              title={"Confirm"}
              onClick={() => setShowIngredientModal(true)}
              type={"submit"}
            />
          </div>
        </div>
      </CommonModal>

      <CultivationConfirmModal
        viewModal={showIngredientModal}
        closeModal={() => setShowIngredientModal(false)}
        check={() => {
          setShowIngredientModal(false);
        }}
        calculation={() => {
          mutate(cultivationId);
          setShowIngredientModal(false);
        }}
        next={() => {
          setShowIngredientModal(false);
          navigate("/ingredient");
        }}
        title="Confirm"
        subTitle="Are you sure all details have been included and are correct?"
      />
    </>
  );
};

export default CultivationDetailsModal;
