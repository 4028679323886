import { useState, useEffect, useMemo } from "react";
import { Checkbox, Dimmer, Grid, Loader } from "semantic-ui-react";
import { useForm } from "react-hook-form";

import {
  InputText,
  CustomButton,
  CommonModal,
  TitleView,
  DropDown,
  NewImageUploader,
} from "../../../components";
import { UserType } from "../../../config/constants";
import useWindowDimensions from "../../../hooks/windowsSize";

import "./userProfile.scss";
import {
  useProfileImageUpload,
  useGetUserById,
  usePutUpdateUserData,
  useProfileImageDelete
} from "../../../api/userDetails";
import { successMessage } from "../../../helpers/ErrorHandler";
import defaultUserImage from "../../../assets/images/default_profile_pic.jpeg";

const UserProfileModal = ({ visibleModal, setVisibleModal, id, userRolesData }: any) => {
  const { height } = useWindowDimensions();
  const [roleData, setRoleData] = useState<any>([]);

  const { isLoading: isLoadingProfileImage, mutate: uploadProfileImage } =
    useProfileImageUpload();

  const { isLoading: isLoadingProfileImageDelete, mutate: deleteUserProfileImage } =
    useProfileImageDelete();

  //load user data
  const {
    data: userDetails,
    isLoading: isUserDataLoading,
    mutate: getUserData,
  } = useGetUserById();

  //update user data
  const {
    isLoading,
    isSuccess = false,
    mutate,
    reset: dataReset,
  } = usePutUpdateUserData();

  //handle user input data changes
  const values = useMemo(() => {
    setRoleData(userDetails?.userRole || []);
    return {
      phone: userDetails?.phone || "",
      email: userDetails?.email || "",
      firstName: userDetails?.firstName || "",
      lastName: userDetails?.lastName || "",
    };
  }, [userDetails]);

  //custom hook load data when page load
  useEffect(() => {
    getUserData(id);
  }, [visibleModal]);

  //custom hook reset user data when unmount and role change
  useEffect(() => {
    return () => {
      reset();
      dataReset();
    };
  }, [visibleModal, roleData]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: values,
    values: values,
  });

  //custom hook identify user data update successfully
  useEffect(() => {
    if (isSuccess) {
      successMessage("User data update");
      setVisibleModal(false);
    }
  }, [isSuccess]);

  //user data update submit
  const onSubmit = async (data: any) => {
    data.userRole = roleData;
    data.status = "ACTIVE";
    mutate({ id: userDetails?._id, userData: data });
  };

  //handle loading
  if (isUserDataLoading || isLoading) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  return (
    <>
      <CommonModal
        modalOpenState={visibleModal}
        modalCloseState={() => {
          setVisibleModal(false);
          // reset();
        }}
        centered={true}
        customDimmer="customDimmerProduct"
        title="Profile"
      >
        <TitleView
          title="Profile info"
          CustomTitleViewMain="productRegisterTitle"
        />
        <Grid>
          <Grid.Column computer={16} tablet={16} mobile={16}>
            <>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div
                  style={{
                    height: height * 0.9 - 180,
                    overflowX: "hidden",
                  }}
                >
                  <Grid>
                    <Grid.Column computer={8} tablet={16} mobile={16}>
                      <NewImageUploader
                        mainTitle={"Profile Picture"}
                        defaultImage={userDetails?.profileUrl || defaultUserImage}
                        buttonTitle={"Upload an Image"}
                        customImageViewMain={"customImageView"}
                        imageData={(data: any) => {
                          if (data) {
                            let dataValue = {
                              userId: userDetails?._id,
                              file: data,
                            };

                            uploadProfileImage(dataValue, {
                              onSuccess() {
                                getUserData(dataValue.userId);
                              },
                            });
                          }
                        }}
                        deleteImage={() => {
                          let data = {
                            userId: userDetails?._id,
                          };
                          deleteUserProfileImage(data, {
                            onSuccess() {
                              getUserData(data.userId);
                            },
                          });
                        }}
                        customImageUploadMain={"userProfileTopHeader"}
                      />
                    </Grid.Column>
                    <Grid.Column computer={16} tablet={16} mobile={16}>
                      <Grid>
                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveBottom"
                        >
                          <InputText
                            register={register}
                            errors={errors.firstName}
                            labelName={"First Name"}
                            placeholder="First Name"
                            name="firstName"
                            required={true}
                            errorMessage={"Please enter user name"}
                          />
                        </Grid.Column>
                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveBottom"
                        >
                          <InputText
                            register={register}
                            errors={errors.firstName}
                            labelName={"Last Name"}
                            placeholder="Last Name"
                            name="lastName"
                            required={true}
                            errorMessage={"Please enter user name"}
                          />
                        </Grid.Column>

                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveTop"
                        >
                          <InputText
                            register={register}
                            errors={errors.email}
                            labelName={"Email"}
                            placeholder="Email"
                            name="email"
                            required={true}
                            disabled={true}
                          />
                        </Grid.Column>
                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveTop"
                        >
                          <InputText
                            register={register}
                            errors={errors.phone}
                            labelName={"phone"}
                            placeholder="phone"
                            name="phone"
                            required={false}
                            errorMessage={"Please enter name"}
                          />
                        </Grid.Column>

                        <Grid.Column
                          computer={16}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveTop"
                        >
                          <label className={`inputTextLable`}>Role</label>
                          <Grid className="userDetailsRoles">
                            <Grid.Column
                              computer={4}
                              tablet={16}
                              mobile={16}
                            >
                              {userDetails?.userRole[0]}
                            </Grid.Column>
                          </Grid>
                        </Grid.Column>
                      </Grid>
                    </Grid.Column>
                  </Grid>
                </div>

                <div
                  style={{
                    height: height * 0.1,
                  }}
                  className="modalRegisterButtonMain"
                >
                  <div className="modalRegisterFlex">
                    <CustomButton
                      theme="green"
                      title={"Cancel"}
                      customColumnStyle={"cardInsertSaveOther"}
                      buttonOutLine={true}
                      onClick={() => {
                        setVisibleModal(false);
                        reset();
                      }}
                    />
                  </div>

                  <div className="modalRegisterButtonLeft">
                    <CustomButton
                      theme="green"
                      title={"Save"}
                      customColumnStyle={"cardInsertSaveOther"}
                      onClick={handleSubmit(onSubmit)}
                      type={"button"}
                    />
                  </div>
                </div>
              </form>
            </>
          </Grid.Column>
        </Grid>
      </CommonModal>
    </>
  );
};

export default UserProfileModal;
