import React from "react";
import { Grid, Button, Icon } from "semantic-ui-react";

import "./button.scss";
import { ImageView } from "../ImageView/ImageView";
import { images } from "../../assets/images";

interface CustomButtonProps {
  computer?: any;
  tablet?: any;
  mobile?: any;
  theme?: string;
  title?: string;
  customColumnStyle?: string;
  customButtonStyle?: string;
  onClick?: () => void;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  circular?: boolean;
  icon?: any;
  iconName?: any;
  iconSize?: any;
  buttonOutLine?: boolean;
  iconWithText?: boolean;
  customButtonIcon?: string;
  className?: string;
}

export const CustomButton: React.FC<CustomButtonProps> = ({
  computer = undefined,
  tablet = undefined,
  mobile = undefined,
  theme = "dark",
  title = "",
  customColumnStyle,
  customButtonStyle,
  onClick,
  type = "button",
  disabled = false,
  circular,
  icon,
  iconName,
  iconSize = "large",
  buttonOutLine = false,
  iconWithText = false,
  customButtonIcon,
  className,
}: any) => {
  const themeText = () => {
    switch (theme) {
      case "green":
        return disabled
          ? buttonOutLine
            ? "disableButtonOutlineMain buttonGreenOutline"
            : "disableButtonMain buttonGreen"
          : buttonOutLine
          ? "commonOutLineButton buttonGreenOutline"
          : "commonButton buttonGreen";
      case "dark":
        return disabled
          ? buttonOutLine
            ? "disableButtonOutlineMain buttonDarkOutline"
            : "disableButtonMain buttonDark"
          : buttonOutLine
          ? "commonOutLineButton buttonDarkOutline"
          : "commonButton buttonDark";
      case "blue":
        return disabled
          ? buttonOutLine
            ? "disableButtonOutlineMain buttonBlueOutline"
            : "disableButtonMain buttonBlue"
          : buttonOutLine
          ? "commonOutLineButton buttonBlueOutline"
          : "commonButton buttonBlue";

      case "red":
        return disabled
          ? buttonOutLine
            ? "disableButtonOutlineMain buttonRedOutline"
            : "disableButtonMain buttonRed"
          : buttonOutLine
          ? "commonOutLineButton buttonRedOutline"
          : "commonButton buttonRed";

      default:
        return disabled
          ? buttonOutLine
            ? "disableButtonOutlineMain buttonGreenOutline"
            : "disableButtonMain buttonGreen"
          : buttonOutLine
          ? "commonOutLineButton buttonGreenOutline"
          : "commonButton buttonGreen";
    }
  };

  const getImageData = (iconName:any) =>{
    switch (iconName) {
      case "plus":
        return images.PlusIconNew
      default:
        break;
    }
  }

  return (
    <Grid.Column
      computer={computer}
      tablet={tablet}
      mobile={mobile}
      className={`${customColumnStyle}`}
    >
      {icon ? (
        <Button
          className={`${themeText()} ${customButtonStyle} ${className}`}
          onClick={onClick}
          type={type}
          disabled={disabled}
          circular={circular}
          icon={true}
        >
          <Icon name={icon} size={iconSize} />
        </Button>
      ) : iconWithText ? (
        <div
          onClick={onClick}
          className={`${themeText()} ${customButtonStyle} ${className}`}
        >
           <ImageView
            avatar={false}
            src={getImageData(iconName)}
            addRandomId={false}
            customImageView={`imageCustomView`}
          />
          {title}
        </div>
      ) : (
        <Button
          className={`${themeText()} ${customButtonStyle} ${className}`}
          onClick={onClick}
          type={type}
          disabled={disabled}
        >
          {title}
        </Button>
      )}
    </Grid.Column>
  );
};
