import React from "react";
import { Grid, Table } from "semantic-ui-react";
import {
  CommonTable,
  CustomButton,
  DropDown,
  InputText,
  TitleView,
} from "../../../components";
import { FacilityEnergyTable } from "../../../config/constants";
import { MdDeleteOutline } from "react-icons/md";
import { InputNumber } from "../../../components/InputText/InputNumber";

import "./facilityForm.scss";

const FacilityEnergy = ({
  energyType,
  defaultEnergyType,
  setDefaultEnergyType,
  registerFacilityEnergy,
  errorsFacilityEnergy,
  handleSubmitFacilityEnergy,
  addFacilityEnergyData,
  addFacilityEnergy,
  currentFacilityData,
  setDeleteData,
  setVisibleDeleteModal,
  control,
}: any) => {
  const loadTableData = () => {
    return currentFacilityData.map((data: any, index: number) => {
      return (
        <Table.Row className="tbleR" key={index}>
          <Table.Cell>
            <p>{data?.EnergyType ?? ""}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data?.Price ?? 0.0}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data?.Percentage ?? ""}</p>
          </Table.Cell>
          <Table.Cell>
            <Grid>
              <Grid.Column computer={4} tablet={16} mobile={16}>
                <MdDeleteOutline
                  cursor={"pointer"}
                  size={24}
                  color="var(--mainColor)"
                  onClick={() => {
                    setDeleteData(data);
                    setVisibleDeleteModal(true);
                  }}
                />
              </Grid.Column>
            </Grid>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  return (
    <>
      <TitleView title="Electricity source" />
      <Grid>
        <Grid.Column
          computer={16}
          tablet={16}
          mobile={16}
          className="userBankDetailsMain "
        >
          <div>
            <Grid>
              <Grid.Column computer={3} tablet={8} mobile={16}>
                <DropDown
                  labelName={"Electricity Type"}
                  placeholder="Electricity Type"
                  currentData={energyType}
                  defaultValue={energyType?.[defaultEnergyType].value || null}
                  customGridColumn={"customGridColomnType"}
                  handleChangeState={(e: any, { value }: any) => {
                    let getIndex = energyType?.findIndex(
                      (e: any) => e?.value === value
                    );
                    setDefaultEnergyType(getIndex);
                  }}
                />
              </Grid.Column>
              <Grid.Column computer={3} tablet={8} mobile={16}>
                <InputNumber
                  control={control}
                  errors={errorsFacilityEnergy.Price}
                  labelName={"Price"}
                  placeholder="Price"
                  name="Price"
                  required={true}
                  errorMessage="Price is required"
                />
              </Grid.Column>
              <Grid.Column computer={3} tablet={8} mobile={16}>
                <InputNumber
                  control={control}
                  errors={errorsFacilityEnergy.Percentage}
                  labelName={"Percentage"}
                  placeholder="Percentage"
                  name="Percentage"
                  required={true}
                  errorMessage="Percentage is required"
                />
              </Grid.Column>

              <Grid.Column computer={3} tablet={8} mobile={16}>
                <CustomButton
                  theme="green"
                  customColumnStyle={"addUnitSize"}
                  icon="plus"
                  onClick={handleSubmitFacilityEnergy((data: any) => {
                    addFacilityEnergyData(data);
                  })}
                />
              </Grid.Column>
            </Grid>
            <Grid.Column
              computer={16}
              tablet={16}
              mobile={16}
              className="productRegisterTable customTableBottom"
            >
              {addFacilityEnergy?.length > 0 && (
                <CommonTable tableHeaderData={FacilityEnergyTable}>
                  {loadTableData()}
                </CommonTable>
              )}
            </Grid.Column>
          </div>
        </Grid.Column>
      </Grid>
    </>
  );
};

export default FacilityEnergy;
