import React, { useMemo, useState } from "react";
import { Grid, Table } from "semantic-ui-react";
import {
  CustomButton,
  CommonTable,
  InputText,
  CommonModal,
  DropDown
} from "../../../components";
import { useForm, useFieldArray } from "react-hook-form";

import { SipCol, PER_TYPES, PER_WEIGHT_UNITS } from "../../../config/constants";
import useWindowDimensions from "../../../hooks/windowsSize";
import { InputNumber } from "../../../components/InputText/InputNumber";

const SipContentModal = ({
  visibleModal,
  setVisibleModal,
  processCIPData,
  getSipData,
}: any) => {
  const values: any = useMemo(() => {
    return {
      cipData: processCIPData,
    };
  }, [visibleModal]);
  const { height } = useWindowDimensions();

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm({
    mode: "all",
    defaultValues: values,
    values: values,
  });

  const { fields: cipDetails } = useFieldArray({
    control,
    name: "cipData",
  });
  const [defaultPerTypeFrequency, setDefaultPerTypeFrequency] = useState(values?.cipData?.[values?.cipData.length - 2]?.value?.per || "week")
  const [defaultPerTypePA, setDefaultPerTypePA] = useState(values?.cipData?.[values?.cipData.length - 1]?.value?.per || "week")
  const [defaultWeightUnit, setDefaultWeightUnit] = useState(values?.cipData?.[values?.cipData.length - 1]?.value?.perUnit || "kg")
  const onSubmit = (data: any) => {
    let dataView = data.cipData.reduce(function (acc: any, obj: any) {
      acc[obj.typeName] = obj.value;
      return acc;
    }, {});
    
    getSipData([{...dataView, frequency: {
        amount: data?.frequency || 0,
        per: defaultPerTypeFrequency,
      },productionAmount: {
        amount: data?.prodAmount || 0,
        per: defaultPerTypePA,
        perUnit: defaultWeightUnit
      } 
    }]);
    setVisibleModal(false);
  };

  const cancelHandler = () => {
    cipDetails.forEach((v: any, index: any) => {
      setValue(`cipData.${index}.value`, null);
    });
  };

  
  const loadSipableData = () => {
    return cipDetails.map((data: any, index: any) => {
      return (
        <Table.Row className="tbleR" key={index}>
          <Table.Cell>
            <p>{data.name}</p>
          </Table.Cell>
          <Table.Cell>
            {data.name === "Model Number" ||
            data.name === "Manufacturer" ||
            data.name === "Chemical use" ? (
              <InputText
                register={register}
                placeholder={data.value}
                name={`cipData.${index}.value`}
              />
            ) : (
              <InputNumber
                transform={{
                  input: (value: any) =>
                    isNaN(value) || value === 0 ? "" : value.toString(),
                  output: (e: any) => {
                    return e;
                  },
                }}
                control={control}
                placeholder={"0"}
                name={`cipData.${index}.value`}
              />
            )}
          </Table.Cell>
          <Table.Cell>
            <p>{data.unit}</p>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  return (
    <div>
      <CommonModal
        modalOpenState={visibleModal}
        modalCloseState={() => {
          setVisibleModal(false);
          reset();
        }}
        centered={true}
        customDimmer="custom-dimmer-modal"
        title="Machine: CIP (Cleaning in Place) / SIP (Sterilization in Place)"
      >
        <div
          style={{
            height: height * 0.8 - 90,
            overflowX: "hidden",
          }}
        >
          <Grid>
              <Grid.Row style={{alignItems: 'center'}}>
                <Grid.Column computer={3} tablet={8} mobile={16}>
                  <label className={`textLabel`}>Frequency</label>
                </Grid.Column>
                <Grid.Column computer={5} tablet={8} mobile={16}>
                  <InputNumber
                    checkValidation={true}
                    transform={{
                      input: (value: any) =>
                        isNaN(value) || (/^\d*\.\d+$/.test(value)) ? "" : value.toString(),
                      output: (e: any) => {
                        return e;
                      },
                    }}
                    control={control}
                    placeholder={"0"}
                    defaultValue={values?.cipData?.[values?.cipData.length - 2]?.value?.amount}
                    name={`frequency`}
                  />
                </Grid.Column>
                <Grid.Column computer={1} tablet={8} mobile={16}>
                  <label className={`textLabel`}>time(s)</label>
                </Grid.Column>
                <Grid.Column computer={1} tablet={8} mobile={16}>
                  <label className={`textLabel`}>per</label>
                </Grid.Column>
                <Grid.Column computer={5} tablet={8} mobile={16}>
                  <DropDown
                    customLabelText={"customLabelText"}
                    currentData={PER_TYPES}
                    defaultValue={defaultPerTypeFrequency}
                    customGridColumn={"customGridColomnType"}
                    handleChangeState={(e: any, { value }: any) => {
                      setDefaultPerTypeFrequency(value);
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={{alignItems: 'center'}}>
                <Grid.Column computer={3} tablet={8} mobile={16}>
                  <label className={`textLabel`}>Production Amount</label>
                </Grid.Column>
                <Grid.Column computer={3} tablet={8} mobile={16}>
                  <InputNumber
                    transform={{
                      input: (value: any) =>
                        isNaN(value) ? "" : value.toString(),
                      output: (e: any) => {
                        return e;
                      },
                    }}
                    control={control}
                    placeholder={"0"}
                    defaultValue={values?.cipData?.[values?.cipData.length - 1]?.value?.amount}
                    name={`prodAmount`}
                  />
                </Grid.Column>
                <Grid.Column computer={3} tablet={8} mobile={16}>
                  <DropDown
                    customLabelText={"customLabelText"}
                    currentData={PER_WEIGHT_UNITS}
                    defaultValue={defaultWeightUnit}
                    customGridColumn={"customGridColomnType"}
                    handleChangeState={(e: any, { value }: any) => {
                      setDefaultWeightUnit(value);
                    }}
                  />
                </Grid.Column>
                <Grid.Column computer={1} tablet={8} mobile={16}>
                  <label className={`textLabel`}>per</label>
                </Grid.Column>
                <Grid.Column computer={5} tablet={8} mobile={16}>
                  <DropDown
                  customLabelText={"customLabelText"}
                    currentData={PER_TYPES}
                    defaultValue={defaultPerTypePA}
                    customGridColumn={"customGridColomnType"}
                    handleChangeState={(e: any, { value }: any) => {
                      setDefaultPerTypePA(value);
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
            <Grid.Column computer={16} tablet={16} mobile={16}>
              <CommonTable tableHeaderData={SipCol}>
                {loadSipableData()}
              </CommonTable>
            </Grid.Column>
          </Grid>
        </div>
        <div className="sipButtonContent">
          <div className="sipCancel">
            <CustomButton
              onClick={() => {
                cancelHandler();
                setVisibleModal(false);
              }}
              title="Cancel"
              theme="green"
              buttonOutLine={true}
            />
          </div>
          <div className="sipAdd">
            <CustomButton
              onClick={handleSubmit(onSubmit)}
              title="Save"
              theme="green"
            />
          </div>
        </div>
      </CommonModal>
    </div>
  );
};

export default SipContentModal;
