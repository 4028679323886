import React, { useState } from "react";
import { Grid } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import {
  InputText,
  CustomButton,
  CommonModal,
  SearchValue,
  ItemAdder,
} from "../../../components";
import useWindowDimensions from "../../../hooks/windowsSize";

import "./transportRegister.scss";

const TransportRegisterModal = ({ visibleModal, setVisibleModal }: any) => {
  const { height } = useWindowDimensions();

  const [transportation, setTranspotation] = useState([{}]);

  const itemAddHandleNewProcess = () => {
    setTranspotation([...transportation, {}]);
  };
  const itemRemoveHandleNewProcess = (index: number) => {
    transportation.splice(index, 1);
    setTranspotation([...transportation]);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = () => {};

  return (
    <CommonModal
      modalOpenState={visibleModal}
      modalCloseState={() => setVisibleModal(false)}
      centered={true}
      customDimmer="customDimmerProduct"
      title="Add Product Transportation"
    >
      <Grid>
        <Grid.Column computer={16} tablet={16} mobile={16}>
          <div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div
                style={{
                  height: height * 0.9 - 180,
                  overflowX: "hidden",
                }}
              >
                <Grid>
                  <Grid.Column computer={5} tablet={8} mobile={16}>
                    <SearchValue title={"Product Name"} />
                  </Grid.Column>
                </Grid>
                {transportation.map((item, index) => {
                  return (
                    <ItemAdder
                      itemAddHandle={itemAddHandleNewProcess}
                      itemRemoveHandle={itemRemoveHandleNewProcess}
                      computerMainView={16}
                      items={transportation}
                      currentIndex={index}
                      customItemAdderGridWithInputs={
                        "customItemAdderGridWithInputsPacking"
                      }
                      customItemAddButtonMain={"customItemAddButtonMainPacking"}
                    >
                      <Grid>
                        <Grid.Column
                          computer={4}
                          tablet={4}
                          mobile={16}
                          className="paddingRemoveLeft paddingRemoveVertical"
                        >
                          <SearchValue title={"Client Name"} />
                        </Grid.Column>

                        <Grid.Column
                          computer={4}
                          tablet={4}
                          mobile={16}
                          className="paddingRemoveLeft paddingRemoveVertical"
                        >
                          <InputText
                            register={register}
                            errors={errors.ingredientName}
                            labelName={"From"}
                            placeholder="From"
                            name="from"
                          />
                        </Grid.Column>
                        <Grid.Column
                          computer={3}
                          tablet={3}
                          mobile={16}
                          className="paddingRemoveLeft paddingRemoveVertical"
                        >
                          <InputText
                            register={register}
                            errors={errors.ingredientName}
                            labelName={"Destination"}
                            placeholder="Destination"
                            name="destination"
                          />
                        </Grid.Column>
                        <Grid.Column
                          computer={3}
                          tablet={3}
                          mobile={16}
                          className="paddingRemoveLeft paddingRemoveVertical"
                        >
                          <InputText
                            register={register}
                            errors={errors.ingredientName}
                            labelName={"Weight (kg)"}
                            placeholder="Weight (kg)"
                            name="weight"
                          />
                        </Grid.Column>
                        <Grid.Column
                          computer={3}
                          tablet={3}
                          mobile={16}
                          className=" paddingRemoveLeft paddingRemoveVertical"
                        >
                          <InputText
                            register={register}
                            errors={errors.ingredientName}
                            labelName={"Journey"}
                            placeholder="Journey"
                            name="journey"
                          />
                        </Grid.Column>
                        <Grid.Column
                          computer={3}
                          tablet={3}
                          mobile={16}
                          className="paddingRemoveLeft paddingRemoveVertical"
                        >
                          <InputText
                            register={register}
                            errors={errors.ingredientName}
                            labelName={"Type"}
                            placeholder="Type"
                            name="type"
                          />
                        </Grid.Column>
                      </Grid>
                      <></>
                    </ItemAdder>
                  );
                })}
              </div>

              <div
                style={{
                  height: height * 0.1,
                }}
                className="modalRegisterButtonMain"
              >
                <div className="modalRegisterButtonLeft">
                  <CustomButton
                    theme="green"
                    title={"Save Transportation"}
                    customColumnStyle={"customREnegryButton"}
                    type={"submit"}
                  />
                </div>
              </div>
            </form>
          </div>
        </Grid.Column>
      </Grid>
    </CommonModal>
  );
};

export default TransportRegisterModal;
