import { memo, useCallback, useState, useContext, useMemo } from "react";
import {
  getNodesBounds,
  NodeProps,
  useStore,
  NodeResizer,
  useReactFlow,
  NodeToolbar,
  Position,
} from "reactflow";
import { Icon, Button, Dimmer, Loader } from "semantic-ui-react";
import { errorView } from "../../../helpers/ErrorHandler";
import { nodeCatagories, nodeType } from "../../../config/drawingConstants";
import { useDeleteFarmGate } from "../../../api/farmGate";
import ConfirmModal from "../../../components/confirmViewModal/ConfirmModal";
import { useDeleteProcessGate } from "../../../api/processGate/processGate";
import ProcessGateFormModal from "../UpdateNodes/ProcessGate/components/ProcessGateFormModal";
import { getStringInsideBrackets } from "../../../utils/utils";
import { useUpdateSupplierProcessGate } from "../../../api/processGate/processGate";
import { DrawingForIngredientContext } from "../DrawingToolForIngredient";
import { successMessage } from "../../../helpers/ErrorHandler";
import { AuthContext } from "../../../context/AuthContext";

const lineStyle = { borderColor: "transparent" };
const padding = 25;

function GroupNode({ id, data, type }: NodeProps) {
  const { deleteElements, getNode } = useReactFlow();
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const { mutate: deleteFarmGate, isLoading: loadingFarmGateDelete } =
    useDeleteFarmGate();
  const { mutate } = useUpdateSupplierProcessGate();
  const [modalOpen, setModalOpen] = useState(false);

  const { mutate: deleteProcessGate, isLoading: loadingProcessGateDelete } =
    useDeleteProcessGate();

  const {
    chartNodes,
    chartEdges,
    supplierIngredientId,
    setChartNodes,
    setNodeItem,
    saveDrawing,
  } = useContext(DrawingForIngredientContext);
  const authState = useContext(AuthContext);
  const supplierUser = authState?.user || { _id: null, tenantId: null };
  const nodeItem = useMemo(() => getNode(id || ""), [id]);

  const { minWidth, minHeight, hasChildNodes, childNodes } = useStore(
    (store) => {
      const childNodes = Array.from(store.nodeInternals.values()).filter(
        (n) => n.parentNode === id
      );
      const rect = getNodesBounds(childNodes);
      return {
        minWidth: rect.width + padding * 2,
        minHeight: rect.height + padding * 2,
        hasChildNodes: childNodes.length > 0,
        childNodes,
      };
    },
    isEqual
  );

  // Delete Farm gate with validation
  const deleteFarmGateGroup = useCallback(() => {
    const isHaveCultivar = childNodes.some(
      (cn) => cn?.data.icon !== nodeType.Cultivar
    );
    if (isHaveCultivar) {
      errorView("Please delete all items inside the farm gate except cultivar");
      return;
    }
    const farmGateId = data?.reference?.farmGateId;
    if (farmGateId) {
      setDeleteConfirm(true);
      return;
    }
    deleteElements({ nodes: [{ id }] });
  }, [childNodes]);

  // Delete Process gate with validation
  const deleteProcessGateGroup = useCallback(() => {
    if (hasChildNodes) {
      errorView("Please delete all items inside the process gate!");
      return;
    }
    setDeleteConfirm(true);
  }, [hasChildNodes]);

  // Delete Group
  const onDelete = useCallback(() => {
    switch (type) {
      case nodeCatagories.farmGate:
        deleteFarmGateGroup();
        break;
      case nodeCatagories.processGate:
        deleteProcessGateGroup();
        break;
    }
  }, [type, deleteFarmGateGroup, deleteProcessGateGroup]);

  // Delete confirmation for group
  const onDeleteConfirm = useCallback(() => {
    switch (type) {
      case nodeCatagories.farmGate:
        const farmGateId = data.reference.farmGateId;
        deleteFarmGate(farmGateId, {
          onSuccess: () => {
            deleteElements({ nodes: [{ id }] });
          },
        });
        break;
      case nodeCatagories.processGate:
        const processGateId = data.reference.processGateId;
        deleteProcessGate(processGateId, {
          onSuccess: () => {
            deleteElements({ nodes: [{ id }] });
          },
        });
        break;
    }
  }, [deleteFarmGate, data]);

  // Edit process gate
  const onEditProcessGate = useCallback(() => {
    switch (type) {
      case nodeCatagories.processGate:
        setModalOpen(true);
        break;
    }
  }, [type, nodeItem]);

  const saveProcessGateData = (nodeItem: any, processData: any) => {
    const updatedNode = {
      ...nodeItem,
      data: {
        ...nodeItem?.data,
        reference: processData,
      },
    };
    const updatedChartNodes = [...chartNodes, updatedNode];
    setChartNodes(updatedChartNodes);
    setNodeItem(updatedNode);
    saveDrawing(chartEdges, updatedChartNodes);
    successMessage("Update process gate successfully");
  };

  const updateProcessGate = (
    processGateName: string,
    newNode: any,
    closeModal: () => void
  ) => {
    let processGate = {
      processGateId: data.reference.processGateId,
      processGateName,
      productId: supplierIngredientId,
      salesUnitId: supplierUser?.tenantId,
      userCompletedStage: "RECIPE",
    };
    mutate(processGate, {
      onSuccess(data) {
        saveProcessGateData(newNode, {
          processGateId: data._id,
          supplierIngredientId: data?.supplierIngredientId,
        });
        closeModal();
      },
    });
  };

  // Loading state check
  if (loadingFarmGateDelete || loadingProcessGateDelete) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  return (
    <>
      <div className="drawing_group" style={{ minWidth, minHeight }}>
        <label>{data.label}</label>
        <NodeResizer
          handleClassName="group_resizer"
          lineStyle={lineStyle}
          minWidth={minWidth}
          minHeight={minHeight}
        />
        <NodeToolbar position={Position.Bottom} align="end" offset={5}>
          &nbsp;
          <Button.Group size="tiny">
            {type === nodeCatagories.processGate && (
              <Button icon color="blue">
                <Icon
                  name="edit"
                  onClick={() => {
                    onEditProcessGate();
                  }}
                />
              </Button>
            )}
            <Button icon color="red">
              <Icon
                name="close"
                onClick={() => {
                  onDelete();
                }}
              />
            </Button>
          </Button.Group>
        </NodeToolbar>
      </div>
      <ProcessGateFormModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        newNode={nodeItem}
        edit={true}
        processGateName={getStringInsideBrackets(data?.label)}
        updateProcessGate={updateProcessGate}
        createProcessGate={() => {}}
      />
      {deleteConfirm ? (
        <ConfirmModal
          viewModal={deleteConfirm}
          closeModal={() => {
            setDeleteConfirm(false);
          }}
          cancel={() => {
            setDeleteConfirm(false);
          }}
          approve={() => {
            onDeleteConfirm();
          }}
          title={
            nodeCatagories.farmGate === type
              ? "Are you sure you want to remove the farm gate?"
              : "Are you sure you want to remove the process gate?"
          }
          subTitle={
            nodeCatagories.farmGate === type
              ? "Please consider you cannot recover these data after remove."
              : "Please consider you cannot recover these data after remove."
          }
        />
      ) : null}
    </>
  );
}

type IsEqualCompareObj = {
  minWidth: number;
  minHeight: number;
  hasChildNodes: boolean;
};

function isEqual(prev: IsEqualCompareObj, next: IsEqualCompareObj): boolean {
  return (
    prev.minWidth === next.minWidth &&
    prev.minHeight === next.minHeight &&
    prev.hasChildNodes === next.hasChildNodes
  );
}

export default memo(GroupNode);
