import { useQuery } from "@tanstack/react-query";
import { ApiServiceErr, axiosApi } from "./apiService";

interface country {
  key: string;
  text: string;
  value: string;
}

interface produceType {
  key: string;
  text: string;
  value: string;
}
export const useGetCountries = () => {
  return useQuery<country[], ApiServiceErr>(["getCountries"], async () => {
    const response = await axiosApi.get("/static-data/countries");
    return response.data;
  });
};

export const useGetProduceTypes = () => {
  return useQuery<produceType[], ApiServiceErr>(
    ["getProduceTypes"],
    async () => {
      const response = await axiosApi.get("/static-data/produce-types");
      return response.data;
    }
  );
};

export const useGetTransportationTypes = () => {
  return useQuery<produceType[], ApiServiceErr>(
    ["getTransportationTypes"],
    async () => {
      const response = await axiosApi.get("/static-data/transportation-types");
      return response.data;
    }
  );
};

export const useGetChemicalTypes = () => {
  return useQuery<produceType[], ApiServiceErr>(
    ["getChemicalTypes"],
    async () => {
      const response = await axiosApi.get("/static-data/chemical-list");
      return response.data.map((item: any) => {
        return {
          key: item.id,
          value: item.id,
          text: item.chemical_name,
        };
      });
    }
  );
};

export const useGetFertilizerList = () => {
  return useQuery<produceType[], ApiServiceErr>(
    ["getFertilizerList"],
    async () => {
      const response = await axiosApi.get("/static-data/fertilizer-list");
      return response.data;
    }
  );
};

export const useGetPesticideList = () => {
  return useQuery<produceType[], ApiServiceErr>(
    ["getPesticideList"],
    async () => {
      const response = await axiosApi.get("/static-data/pesticide-list");
      return response.data;
    }
  );
};

export const useGetWasteManagementTypes = () => {
  return useQuery<produceType[], ApiServiceErr>(
    ["getChemicalTypes"],
    async () => {
      const response = await axiosApi.get(
        "/static-data/waste-management-types"
      );
      return response.data.map((item: any) => {
        return {
          key: item.id,
          value: item.id,
          text: item.chemical_name,
        };
      });
    }
  );
};

export const useGetWasteManagementWasteTypes = () =>
  useQuery<any, ApiServiceErr>(
    ["getWasteMangementWasteData"],
    async () => {
      const response = await axiosApi.get(
        `/static-data/waste-management-types`
      );

      let wasteManagementTypes = response?.data.reduce(
        (currentType: any, data: any) => {
          let wasteType = {
            key: data?.id,
            text: data?.waste_management_type,
            value: data?.id,
          };
          return [...currentType, wasteType];
        },
        []
      );
      return wasteManagementTypes;
    },
    {
      cacheTime: 0,
    }
  );

export const useGetSpecifyIngredient = () =>
  useQuery<any, ApiServiceErr>(
    ["getWasteMangementWasteData"],
    async () => {
      const response = await axiosApi.get(`/static-data/process-produce-types`);
      let wasteManagementTypes = response?.data.reduce(
        (currentType: any, data: any) => {
          let wasteType = {
            key: data?.id,
            text: data?.process_produce_name,
            value: data?.id,
          };
          return [...currentType, wasteType];
        },
        []
      );
      return wasteManagementTypes;
    },
    {
      cacheTime: 0,
    }
  );

export const useGetIrrigationMethod = () =>
  useQuery<any, ApiServiceErr>(
    ["getIrrigationMethod"],
    async () => {
      const response = await axiosApi.get(`/static-data/irrigation-methods`);
      let irrigationData = response?.data.reduce(
        (currentType: any, data: any) => {
          let irrigation = {
            key: data?.id,
            text: data?.irrigation_method_name,
            value: data?.id,
          };
          return [...currentType, irrigation];
        },
        []
      );
      return irrigationData;
    },
    {
      cacheTime: 0,
    }
  );

export const useGetPowerSource = () =>
  useQuery<any, ApiServiceErr>(
    ["getPowerSource"],
    async () => {
      const response = await axiosApi.get(
        `/static-data/irrigation-power-sources`
      );
      let powerSource = response?.data.reduce((currentType: any, data: any) => {
        let powerSourceData = {
          key: data?.id,
          text: data?.irrigation_power_sources_name,
          value: data?.id,
        };
        return [...currentType, powerSourceData];
      }, []);
      return powerSource;
    },
    {
      cacheTime: 0,
    }
  );

export const useGetEnergySource = () =>
  useQuery<any, ApiServiceErr>(
    ["getEnergySource"],
    async () => {
      const response = await axiosApi.get(
        `/static-data/fuel-use-energy-sources`
      );
      let energySource = response?.data.reduce(
        (currentType: any, data: any) => {
          let powerSourceData = {
            key: data?.id,
            text: data?.fuel_use_energy_source_name,
            value: data?.id,
          };
          return [...currentType, powerSourceData];
        },
        []
      );
      return energySource;
    },
    {
      cacheTime: 0,
    }
  );

export const useGetLandUsage = () =>
  useQuery<any, ApiServiceErr>(
    ["getLandUsageChangeList"],
    async () => {
      const response = await axiosApi.get(`/static-data/land-use-change-list`);
      let landUsages = response?.data.reduce((currentType: any, data: any) => {
        let landUsage = {
          key: data?.id,
          text: data?.land_use_chanage_name,
          value: data?.land_use_chanage_name,
        };
        return [...currentType, landUsage];
      }, []);
      return landUsages;
    },
    {
      cacheTime: 0,
    }
  );

export const useGetHeatSourceType = () =>
  useQuery<any, ApiServiceErr>(
    ["getHeatSourceTypeData"],
    async () => {
      const response = await axiosApi.get(`/static-data/heat-sources`);
      let currentHeatType = response?.data.reduce(
        (currentType: any, data: any) => {
          let heatType = {
            key: data?.id,
            text: data?.heat_type,
            value: data?.id,
          };
          return [...currentType, heatType];
        },
        []
      );
      return currentHeatType;
    },
    {
      cacheTime: 0,
    }
  );

export const useGetWaterSourceType = () =>
  useQuery<any, ApiServiceErr>(
    ["getWaterSourceTypeData"],
    async () => {
      const response = await axiosApi.get(`/static-data/water-sources`);
      let currentWaterType = response?.data.reduce(
        (currentType: any, data: any) => {
          let waterType = {
            key: data?.id,
            text: data?.water_type,
            value: data?.id,
          };
          return [...currentType, waterType];
        },
        []
      );
      return currentWaterType;
    },
    {
      cacheTime: 0,
    }
  );
