import React, { useState } from "react";
import { Dimmer, Grid, Loader, Table } from "semantic-ui-react";

import { CustomButton, CommonTable, TitleBar } from "../../../components";
import { ProductTableCol } from "../../../config/constants";
import { TbFileSearch } from "react-icons/tb";
import { MdModeEditOutline, MdDeleteOutline } from "react-icons/md";
import ProductRegisterModal from "../productRegister/ProductRegisterModal";
import ProductDetailModal from "../productDetails/ProductDetailsModal";
import ProductEditModal from "../productEdit/ProductEditModal";

import ConfirmModal from "../../../components/confirmViewModal/ConfirmModal";
import { useGetProductData, useDeleteProductById } from "../../../api/product";
import PaginationView from "../../../components/pagination/Pagination";
import IngredientRegisterModalView from "../../ingredients/ingredientRegister/IngredientRegisterModalView";

const ProductList = () => {
  const [productId, setProductId] = useState("");
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleProductDetailsModal, setVisibleProductDetailsModal] =
    useState(false);
  const [visibleProductEditModal, setVisibleProductEditModal] = useState(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState<any>({});
  const [visibleRegModal, setVisibleRegModal] = useState(false);
  const [dataToPass, setDataToPass] = useState<any>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const {
    data,
    isLoading: productListLoading,
    refetch,
  } = useGetProductData({
    product_status: "ACTIVE",
    pageNumber,
    pageSize,
  });

  const { isLoading, mutate } = useDeleteProductById();

  const totalPages = Math.ceil((data?.total || 0) / (data?.pageSize || 0));

  //handle modal content view
  const getProductDetails = (id: any, type: any) => {
    setProductId(id);
    if (type == "edit") {
      setVisibleProductEditModal(true);
    } else {
      setVisibleProductDetailsModal(true);
    }
  };

  //delete product data
  const deleteProductDetails = (data: any) => {
    mutate(data._id, {
      onSuccess: () => {
        refetch();
      },
    });
    setDeleteData({});
  };

  //loading view
  if (productListLoading || isLoading) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  //loading product list data
  const loadTableData = () => {
    return data?.result?.map((data: any, index: any) => {
      return (
        <Table.Row className="tbleR" key={index}>
          <Table.Cell>
            <p>{data.product_name}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data.product_type}</p>
          </Table.Cell>
          <Table.Cell>
            {data?.sales_units && data?.sales_units?.length > 0 ? (
              <>
                {data?.sales_units?.map((units: any, index: any) => {
                  return <p>{units?.sales_unit_name || "-"}</p>;
                })}
              </>
            ) : (
              <Table.Row>
                <Table.Cell>-</Table.Cell>
              </Table.Row>
            )}
          </Table.Cell>
          <Table.Cell>
            {data?.sales_units && data?.sales_units?.length > 0 ? (
              <>
                {data?.sales_units?.map((units: any, index: any) => {
                  return (
                    <p>
                      {units?.climate_impact || "Calculation is in progress"}
                    </p>
                  );
                })}
              </>
            ) : (
              <Table.Row>
                <Table.Cell>-</Table.Cell>
              </Table.Row>
            )}
          </Table.Cell>
          <Table.Cell>
            <p>{data.registration_number}</p>
          </Table.Cell>
          <Table.Cell>
            <Grid>
              <Grid.Column computer={4} tablet={5} mobile={5}>
                <TbFileSearch
                  cursor={"pointer"}
                  onClick={() => {
                    getProductDetails(data._id, "view");
                  }}
                  size={24}
                  color="var(--mainColor)"
                />
              </Grid.Column>
              <Grid.Column computer={4} tablet={5} mobile={5}>
                <MdModeEditOutline
                  cursor={"pointer"}
                  onClick={() => {
                    getProductDetails(data._id, "edit");
                  }}
                  size={24}
                  color="var(--mainColor)"
                />
              </Grid.Column>
              <Grid.Column computer={4} tablet={5} mobile={5}>
                <MdDeleteOutline
                  cursor={"pointer"}
                  size={24}
                  color="var(--mainColor)"
                  onClick={() => {
                    setVisibleDeleteModal(true);
                    setDeleteData(data);
                  }}
                />
              </Grid.Column>
            </Grid>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  return (
    <>
      <TitleBar enableTitle={true} titleName={"Products"} />
      <CustomButton
        theme="green"
        title="New Product"
        onClick={() => setVisibleModal(true)}
        customColumnStyle="topButtonView"
        iconWithText={true}
        iconName="plus"
      />

      <Grid>
        <Grid.Column computer={16} tablet={16} mobile={16}>
          <CommonTable tableHeaderData={ProductTableCol} singleLine={false}>
            {loadTableData()}
          </CommonTable>
        </Grid.Column>
      </Grid>
      <PaginationView
        currentPageNo={pageNumber}
        totalPages={totalPages}
        pageSize={pageSize}
        currentSelectPage={(page: number) => {
          setPageNumber(page);
        }}
        changePageSize={(pageSize: number) => {
          setPageNumber(1);
          setPageSize(pageSize);
        }}
      />
      <ProductRegisterModal
        visibleModal={visibleModal}
        setVisibleModal={(status: any, data: any) => {
          if (status) {
            refetch();
            setVisibleModal(false);
            if (data?.enableNavigate || false) {
              setDataToPass(data.productData);
            }
            setVisibleRegModal(data?.enableNavigate || false);
          }
        }}
      />
      {visibleProductDetailsModal ? (
        <ProductDetailModal
          visibleModal={visibleProductDetailsModal}
          setVisibleModal={(status: any) => {
            setVisibleProductDetailsModal(false);
          }}
          productId={productId}
        />
      ) : null}
      {visibleProductEditModal ? (
        <ProductEditModal
          visibleModal={visibleProductEditModal}
          setVisibleModal={(status: any) => {
            if (status) {
              refetch();
              setVisibleProductEditModal(false);
            }
          }}
          productId={productId}
        />
      ) : null}
      {visibleRegModal ? (
        <IngredientRegisterModalView
          state={dataToPass}
          visibleModal={visibleRegModal}
          setVisibleModal={() => {
            setDataToPass({});
            setVisibleRegModal(false);
          }}
        />
      ) : null}
      <ConfirmModal
        viewModal={visibleDeleteModal}
        closeModal={() => setVisibleDeleteModal(false)}
        cancel={() => {
          setVisibleDeleteModal(false);
          setDeleteData({});
        }}
        approve={() => {
          setVisibleDeleteModal(false);
          deleteProductDetails(deleteData);
        }}
        title="Delete Product Data"
        subTitle="Are you sure you want to delete your product data?"
      />
    </>
  );
};

export default ProductList;
