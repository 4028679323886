import { useState, useEffect, useMemo } from "react";
import { Dimmer, Grid, Loader } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";

import {
  InputText,
  CustomButton,
  CommonModal,
  TitleView,
  DropDown,
} from "../../../components";
import useWindowDimensions from "../../../hooks/windowsSize";
import { useGetCountries } from "../../../api/static-data";

import "./companyRegister.scss";
import { InputNumber } from "../../../components/InputText/InputNumber";
import { usePostRegisterCompanyData } from "../../../api/company";
import { successMessage } from "../../../helpers/ErrorHandler";
import { InputDatePicker } from "../../../components/InputDatePicker/InputDatePicker";

const CompanyRegisterModal = ({ visibleModal, setVisibleModal, id }: any) => {
  const { height, width } = useWindowDimensions();
  const [visibleRegModal, setVisibleRegModal] = useState(false);
  const { data, isLoading, isSuccess, mutate } = usePostRegisterCompanyData();
  const [country, setCountry] = useState<any>();
  const [startDate, setStartDate] = useState(new Date());

  //company register status check
  useEffect(() => {
    if (isSuccess) {
      successMessage("Company register successfully");
      setVisibleModal(false);
    }
  }, [isSuccess]);

  //custom hook rest data
  useEffect(() => {
    return () => {
      setCountry(null);
      reset();
    };
  }, [visibleModal, visibleRegModal]);

  //handle company input data
  const values = useMemo(() => {
    return {
      name: "",
      address: "",
      companyEmail: "",
      noOfEmployees: 0,
      phone: "",
      tenantType: "Tenant",
      tenantCountry: 0,
      zipCode: "",
      noOfProducts: 0,
      industry: "",
    };
  }, [visibleModal, visibleRegModal]);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: values,
    values: values,
  });

  const {
    data: countriesData,
    isSuccess: isCountriesDataSuccess,
    isLoading: isCountriesDataLoading,
  } = useGetCountries();

  //company data submit
  const onSubmit = async (data: any) => {
    data.status = "ACTIVE";
    data.isParent = false;
    data.tenantCountry = country;
    data.tenantType = "Tenant";
    data.dateOfSubscription = startDate;
    mutate(data);
  };

  const countries = useMemo(() => {
    if (isCountriesDataSuccess) {
      return countriesData?.map((c: any) => ({
        key: c.id,
        text: c.country,
        value: c.id,
      }));
    }
  }, [countriesData, isCountriesDataSuccess]);

  //handle loading
  if (isLoading) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  return (
    <>
      <CommonModal
        modalOpenState={visibleModal}
        modalCloseState={() => {
          setVisibleModal(false);
          setCountry(null);
          reset();
        }}
        centered={true}
        customDimmer="customDimmerProduct"
        title="Company Register"
      >
        <TitleView
          title="Company Details"
          CustomTitleViewMain="productRegisterTitle"
        />
        <Grid>
          <Grid.Column computer={16} tablet={16} mobile={16}>
            <>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div
                  style={{
                    height: height * 0.9 - 180,
                    overflowX: "hidden",
                  }}
                >
                  <Grid>
                    <Grid.Column computer={16} tablet={16} mobile={16}>
                      <Grid>
                        <Grid.Column computer={8} tablet={16} mobile={16}>
                          <InputText
                            register={register}
                            errors={errors.name}
                            labelName={"Company Name"}
                            placeholder="Company Name"
                            name="name"
                            required={true}
                            errorMessage={"Please enter company name"}
                          />
                        </Grid.Column>
                        <Grid.Column computer={8} tablet={16} mobile={16}>
                          <InputText
                            register={register}
                            errors={errors.address}
                            labelName={"Address"}
                            placeholder="Address"
                            name="address"
                            required={true}
                            errorMessage={"Please enter address"}
                          />
                        </Grid.Column>

                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveTop"
                        >
                          <InputText
                            register={register}
                            errors={errors.companyEmail}
                            labelName={"Company Email"}
                            placeholder="Company Email"
                            name="companyEmail"
                            required={true}
                            errorMessage={"Please enter company email"}
                          />
                        </Grid.Column>
                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveTop"
                        >
                          <InputText
                            register={register}
                            errors={errors.phone}
                            labelName={"Phone"}
                            placeholder="phone"
                            name="phone"
                            required={false}
                            errorMessage={"Please enter name"}
                          />
                        </Grid.Column>
                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveTop"
                        >
                          <InputText
                            register={register}
                            errors={errors.phone}
                            labelName={"Registration Number"}
                            placeholder="Registration Number"
                            name="registrationNumber"
                            required={false}
                            errorMessage={"Please enter name"}
                          />
                        </Grid.Column>
                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveTop"
                        >
                          <InputText
                            register={register}
                            errors={errors.phone}
                            labelName={"Website"}
                            placeholder="Website"
                            name="website"
                            required={false}
                            errorMessage={"Please enter name"}
                          />
                        </Grid.Column>

                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveTop"
                        >
                          <InputNumber
                            transform={{
                              input: (value: any) =>
                                isNaN(value) || value === 0
                                  ? ""
                                  : value.toString(),
                              output: (e: any) => {
                                return e;
                              },
                            }}
                            control={control}
                            errors={errors.noOfEmployees}
                            name="noOfEmployees"
                            labelName={"No Of Employees"}
                          />
                        </Grid.Column>
                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveTop"
                        >
                          <InputNumber
                            defaultValue={1}
                            transform={{
                              input: (value: any) =>
                                isNaN(value) || value === 0
                                  ? ""
                                  : value.toString(),
                              output: (e: any) => {
                                return e;
                              },
                            }}
                            control={control}
                            errors={errors.noOfEmployees}
                            name="noOfUsers"
                            labelName={"No Of Users"}
                            removeDecimal={true}
                          />
                        </Grid.Column>
                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveTop"
                        >
                          <InputNumber
                            transform={{
                              input: (value: any) =>
                                isNaN(value) || value === 0
                                  ? ""
                                  : value.toString(),
                              output: (e: any) => {
                                return e;
                              },
                            }}
                            control={control}
                            errors={errors.noOfProducts}
                            name="noOfProducts"
                            labelName={"No Of Products"}
                          />
                        </Grid.Column>
                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveTop"
                        >
                          <InputNumber
                            defaultValue={1}
                            transform={{
                              input: (value: any) =>
                                isNaN(value) || value === 0
                                  ? ""
                                  : value.toString(),
                              output: (e: any) => {
                                return e;
                              },
                            }}
                            control={control}
                            errors={errors.noOfProducts}
                            name="noOfSKUS"
                            labelName={"No Of SKUS"}
                            removeDecimal={true}
                          />
                        </Grid.Column>
                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveTop"
                        >
                          <InputText
                            register={register}
                            errors={errors.phone}
                            labelName={"Industry"}
                            placeholder="Industry"
                            name="industry"
                          />
                        </Grid.Column>
                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveTop"
                        >
                          <DropDown
                            labelName="Country"
                            placeholder="Select country"
                            defaultValue={country}
                            currentData={countries}
                            handleChangeState={(e: any, { value }: any) => {
                              setCountry(value);
                            }}
                            loading={isCountriesDataLoading}
                            search
                            key={"tenantCountry"}
                          />
                        </Grid.Column>
                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveTop"
                        >
                          <InputText
                            register={register}
                            errors={errors.zipCode}
                            labelName={"Zip Code"}
                            placeholder="zipCode"
                            name="zipCode"
                          />
                        </Grid.Column>
                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveTop"
                        >
                          <InputDatePicker
                            labelName="Date of Subscription"
                            changeDate={setStartDate}
                            currentSelectedData={startDate}
                          />
                        </Grid.Column>
                      </Grid>
                    </Grid.Column>
                  </Grid>
                </div>

                <div
                  style={{
                    height: height * 0.1,
                  }}
                  className="modalRegisterButtonMain"
                >
                  <div className="modalRegisterFlex">
                    <CustomButton
                      theme="green"
                      title={"Cancel"}
                      customColumnStyle={"cardInsertSaveOther"}
                      buttonOutLine={true}
                      onClick={() => {
                        setVisibleModal(false);
                        reset();
                      }}
                    />
                  </div>
                  <div className="modalRegisterButtonLeft">
                    <CustomButton
                      theme="green"
                      title={"Save"}
                      customColumnStyle={"cardInsertSaveOther"}
                      onClick={handleSubmit(onSubmit)}
                      type={"button"}
                    />
                  </div>
                </div>
              </form>
            </>
          </Grid.Column>
        </Grid>
      </CommonModal>
    </>
  );
};

export default CompanyRegisterModal;
