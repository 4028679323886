import React, { useEffect, useState } from "react";
import { Table, Input, Grid } from "semantic-ui-react";
import Icon from "@mdi/react";
import { mdiPencil, mdiDeleteOutline, mdiContentSave } from "@mdi/js";

import "./packagingTable.scss";
import { calculateTotalWeight } from "../../utils/calculateTotalWeight";
import { DropDown } from "../../../../components/selectDropDown/DropDown";
import { CustomButton } from "../../../../components/button/Button";

interface PackagingTableProps {
  tableData: PackagingTableData[];
  editable?: boolean;
  originalData?: PackagingTableData[];
  setNewPackagingData?: React.Dispatch<
    React.SetStateAction<PackagingData | undefined>
  >;
  field?: PackagingField;
  packagingMaterials: PackagingMaterial[];
  isView?: boolean;
}

const PackagingTable: React.FC<PackagingTableProps> = ({
  tableData,
  editable,
  setNewPackagingData,
  field,
  originalData,
  packagingMaterials,
  isView,
}) => {
  const [editIndex, setEditIndex] = useState<number | undefined>(undefined);
  const [editRow, setEditRow] = useState<PackagingTableData | undefined>(
    undefined
  );
  const [newRow, setNewRow] = useState<PackagingTableData | undefined>();

  useEffect(() => {
    if (editIndex !== undefined) {
      setEditRow(tableData[editIndex]);
    }
  }, [editIndex]);

  const renderNewRow = () => {
    if (!newRow) {
      return null;
    }
    return (
      <Table.Row>
        <Table.Cell>
          <Input
            fluid
            value={newRow.packageName}
            onChange={(e) => {
              if (newRow) {
                setNewRow({ ...newRow, packageName: e.target.value });
              }
            }}
          />
        </Table.Cell>
        <Table.Cell>
          <DropDown
            customGridMain="custom-grid-main"
            placeholder="Packaging Material"
            currentData={packagingMaterials}
            defaultValue={
              packagingMaterials.find((packagingMaterial) => {
                return (
                  Number(packagingMaterial.value) ===
                  Number(newRow.packageMaterial)
                );
              })?.value || null
            }
            customGridColumn={"customGridColomnType"}
            handleChangeState={(e: any, { value }: any) => {
              if (newRow) {
                setNewRow({
                  ...newRow,
                  packageMaterial: value,
                });
              }
            }}
          />
        </Table.Cell>
        <Table.Cell>
          <Input
            fluid
            type="number"
            value={newRow.materialWeight}
            onChange={(e) => {
              if (newRow) {
                setNewRow({
                  ...newRow,
                  materialWeight: parseFloat(e.target.value),
                });
              }
            }}
          />
        </Table.Cell>
        <Table.Cell>
          <Grid>
            <Grid.Column width={8}>
              <div
                onClick={() => {
                  setNewPackagingData &&
                    setNewPackagingData((prev: any) => {
                      if (prev && field) {
                        const newData = [...prev[field]];
                        newData.push(newRow);
                        return calculateTotalWeight({
                          ...prev,
                          [field]: newData,
                        });
                      }
                      return prev;
                    });
                  setNewRow(undefined);
                }}
              >
                <Icon
                  className="table-icon"
                  color="var(--mainColor)"
                  path={mdiContentSave}
                  size={1.2}
                />
              </div>
            </Grid.Column>
            <Grid.Column width={8}>
              <div onClick={() => setNewRow(undefined)}>
                <Icon
                  className="table-icon"
                  color="var(--mainColor)"
                  path={mdiDeleteOutline}
                  size={1.2}
                />
              </div>
            </Grid.Column>
          </Grid>
        </Table.Cell>
      </Table.Row>
    );
  };

  const renderActions = (index: number) => {
    if (!editable || isView) {
      return null;
    }
    return (
      <Table.Cell>
        {editIndex === index ? (
          <div
            onClick={() => {
              setNewPackagingData &&
                setNewPackagingData((prev: any) => {
                  if (prev && field) {
                    const newData = [...prev[field]];
                    newData[index] = editRow;
                    return calculateTotalWeight({
                      ...prev,
                      [field]: newData,
                    });
                  }
                  return prev;
                });
              setEditIndex(undefined);
            }}
          >
            <Icon
              className="table-icon"
              color="var(--mainColor)"
              path={mdiContentSave}
              size={1.2}
            />
          </div>
        ) : (
          <Grid>
            <Grid.Column width={8}>
              <div onClick={() => setEditIndex(index)}>
                <Icon
                  className="table-icon"
                  color="var(--mainColor)"
                  path={mdiPencil}
                  size={1.2}
                />
              </div>
            </Grid.Column>
            <Grid.Column width={8}>
              <div
                onClick={() => {
                  setNewPackagingData &&
                    setNewPackagingData((prev: any) => {
                      if (prev && field) {
                        const newData = [...prev[field]];
                        newData.splice(index, 1);
                        return calculateTotalWeight({
                          ...prev,
                          [field]: newData,
                        });
                      }
                      return prev;
                    });
                }}
              >
                <Icon
                  className="table-icon"
                  color="var(--mainColor)"
                  path={mdiDeleteOutline}
                  size={1.2}
                />
              </div>
            </Grid.Column>
          </Grid>
        )}
      </Table.Cell>
    );
  };

  return (
    <>
      {editable ? (
        <CustomButton
          customColumnStyle="p-0 m-0 packaging-add-button"
          theme="green"
          title="Add New"
          disabled={isView}
          onClick={() => {
            setNewRow({
              packageName: "",
              packageMaterial: "0",
              materialWeight: 0,
            });
          }}
        />
      ) : (
        <div className={isView? "empty-block-view": "empty-block"}>
        </div>
      )}
      <Table
        singleLine
        className={editable ? "editable-table" : "non-editable-table"}
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Packaging name</Table.HeaderCell>
            <Table.HeaderCell>Packaging material</Table.HeaderCell>
            <Table.HeaderCell>Material weight (kg)</Table.HeaderCell>
            {editable && !isView && <Table.HeaderCell>Actions</Table.HeaderCell>}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {tableData.map((item, index) => {
            const isEditing = editIndex === index;
            const originalItem: { [key: string]: any } =
              originalData?.[index] || {};

            const handleInputChange = (field: string, value: any) => {
              if (editRow) {
                setEditRow({ ...editRow, [field]: value });
              }
            };

            const isEdited = (
              field: "materialWeight" | "packageName" | "packageMaterial"
            ) => editable && originalItem[field] !== item[field];

            return (
              <Table.Row key={index}>
                <Table.Cell>
                  {isEditing ? (
                    <Input
                      fluid
                      value={editRow?.packageName || ""}
                      onChange={(e) =>
                        handleInputChange("packageName", e.target.value)
                      }
                    />
                  ) : (
                    <p className={isEdited("packageName") ? "edited" : ""}>
                      {item.packageName}
                    </p>
                  )}
                </Table.Cell>
                <Table.Cell>
                  {isEditing ? (
                    <DropDown
                      customGridMain="custom-grid-main"
                      placeholder="Packaging Material"
                      currentData={packagingMaterials}
                      defaultValue={
                        packagingMaterials.find(
                          (pm) =>
                            Number(pm.value) ===
                            Number(editRow?.packageMaterial)
                        )?.value || null
                      }
                      customGridColumn="customGridColomnType"
                      handleChangeState={(e, { value }) =>
                        handleInputChange("packageMaterial", value)
                      }
                    />
                  ) : (
                    <p className={isEdited("packageMaterial") ? "edited" : ""}>
                      {
                        packagingMaterials.find(
                          (pm) =>
                            Number(pm.value) === Number(item.packageMaterial)
                        )?.text
                      }
                    </p>
                  )}
                </Table.Cell>
                <Table.Cell>
                  {isEditing ? (
                    <Input
                      fluid
                      type="number"
                      value={editRow?.materialWeight || ""}
                      onChange={(e) =>
                        handleInputChange(
                          "materialWeight",
                          parseFloat(e.target.value)
                        )
                      }
                    />
                  ) : (
                    <p className={isEdited("materialWeight") ? "edited" : ""}>
                      {item.materialWeight}
                    </p>
                  )}
                </Table.Cell>
                {renderActions(index)}
              </Table.Row>
            );
          })}

          {renderNewRow()}
        </Table.Body>
      </Table>
    </>
  );
};

export default PackagingTable;
