import { useState, useEffect, useMemo } from "react";
import { Grid, Dimmer, Loader } from "semantic-ui-react";
import { useForm } from "react-hook-form";

import { CustomButton, CommonModal } from "../../../components";
import useWindowDimensions from "../../../hooks/windowsSize";
import { isEmpty } from "lodash";
import { errorView, successMessage } from "../../../helpers/ErrorHandler";

import "./facilityRegister.scss";
import { useCreateFacility } from "../../../api/facility";
import { FACILITY_TYPES } from "../../../config/constants";
import { useGetEnergyType } from "../../../api/common";
import {
  useGetCountries,
  useGetHeatSourceType,
  useGetWaterSourceType,
} from "../../../api/static-data";
import FacilityInfo from "../facilityForms/FacilityInfo";
import FacilityEnergy from "../facilityForms/FacilityEnergy";
import FacilityHeatSource from "../facilityForms/FacilityHeatSource";
import FacilityWaterSource from "../facilityForms/FacilityWaterSource";

const FacilityRegisterModal = ({ visibleModal, setVisibleModal }: any) => {
  const [addFacilityEnergy, setFacilityEnergy] = useState<any>([]);
  const [addFacilityHeat, setFacilityHeat] = useState<any>([]);
  const [addFacilityWater, setFacilityWater] = useState<any>([]);
  const [defaultEnergyType, setDefaultEnergyType] = useState<any>(0);
  const [defaultHeatType, setDefaultHeatType] = useState<any>(0);
  const [defaultWaterType, setDefaultWaterType] = useState<any>(0);
  const [defaultFacilityType, setDefaultType] = useState<any>(
    FACILITY_TYPES[0].value
  );
  const [country, setCountry] = useState<any>();
  const [addDataObject, setAddDataObject] = useState({
    EnergyType: 0,
    Percentage: 0,
    Price: 0,
  });
  const [addHeatDataObject, setAddHeatDataObject] = useState({
    HeatSource: 0,
    Volumes: 0,
    Percentage: 0,
    Price: 0,
    Year: 0,
  });
  const [addWaterDataObject, setAddWaterDataObject] = useState({
    WaterSource: 0,
    Volumes: 0,
    Price: 0,
    Year: 0,
  });

  const { height } = useWindowDimensions();

  //get energy type data
  const { data: energyType, status, fetchStatus, refetch } = useGetEnergyType();
  const {
    data: heatSource,
    status: heatSourceStatus,
    fetchStatus: fetchHeatSource,
    refetch: refetchHeatSource,
  } = useGetHeatSourceType();
  const {
    data: waterSource,
    status: waterSourceStatus,
    fetchStatus: fetchWaterSource,
    refetch: refetchWaterSource,
  } = useGetWaterSourceType();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    getValues,
    setValue,
  } = useForm();

  const {
    formState: { errors: errorsFacilityEnergy },
    handleSubmit: handleSubmitFacilityEnergy,
    setValue: setEnergyValue,
    reset: resetEnergy,
    control,
  } = useForm();

  const {
    formState: { errors: errorsFacilityHeat },
    handleSubmit: handleSubmitFacilityHeat,
    setValue: setHeatValue,
    reset: resetHeat,
    control: controlHeat,
  } = useForm();

  const {
    formState: { errors: errorsFacilityWater },
    handleSubmit: handleSubmitFacilityWater,
    setValue: setWaterValue,
    reset: resetWater,
    control: controlWater,
  } = useForm();

  const {
    data: countriesData,
    isSuccess: isCountriesDataSuccess,
    isLoading: isCountriesDataLoading,
  } = useGetCountries();

  //custom hook create facility
  const { isLoading, mutate, data, reset: resetData } = useCreateFacility();

  useEffect(() => {
    return () => {
      setFacilityEnergy([]);
      setFacilityHeat([]);
      reset();
      resetFacilityEnergys();
      resetEnergy();
      resetHeat();
      resetWater();
    };
  }, [visibleModal]);

  const countries = useMemo(() => {
    if (isCountriesDataSuccess) {
      return countriesData?.map((c: any) => ({
        key: c.id,
        text: c.country,
        value: c.id,
      }));
    }
  }, [countriesData, isCountriesDataSuccess]);

  //Handle close modal status
  const closeModalStatus = () => {
    let value = getValues();
    setVisibleModal(true, value);
  };

  // product facility energy handler
  const removeDataHandler = (index: number) => {
    const newData = addFacilityEnergy;
    newData.splice(index, 1);
    setFacilityEnergy([...newData]);
  };

  // product facility energy handler
  const removeWaterDataHandler = (index: number) => {
    const newData = addFacilityWater;
    newData.splice(index, 1);
    setFacilityWater([...newData]);
  };

  // product facility heat handler
  const removeHeatDataHandler = (index: number) => {
    const newData = addFacilityHeat;
    newData.splice(index, 1);
    setFacilityHeat([...newData]);
  };

  //check product error view
  const checkFacilitySaveCondition = (data: any) => {
    if (isEmpty(data.facilityName.trim())) {
      errorView("Facility name is required");
      return true;
    } else if (!country) {
      errorView("Facility country is required");
      return true;
    } else if (addFacilityEnergy.length <= 0) {
      errorView("Please add facility energy");
      return true;
    } else {
      return false;
    }
  };

  //Reset facility energys
  const resetFacilityEnergys = () => {
    setDefaultEnergyType(0);
    setEnergyValue("Percentage", null);
    setEnergyValue("Price", 0.0);
  };

  //Reset facility heat
  const resetFacilityHeat = () => {
    setDefaultHeatType(0);
    setHeatValue("Percentage", null);
    setHeatValue("Price", 0.0);
    setHeatValue("Volumes", null);
    setHeatValue("Year", null);
  };

  //Reset facility water
  const resetFacilityWater = () => {
    setDefaultWaterType(0);
    setWaterValue("Price", 0.0);
    setWaterValue("Volumes", null);
    setWaterValue("Year", null);
  };

  //add product Energy data
  const addEnergyData = (data: any) => {
    setAddDataObject({
      ...addDataObject,
      EnergyType: energyType?.[defaultEnergyType]?.text || "",
      Percentage: Number(data?.Percentage) ?? 0,
      Price: Number(data?.Price) ?? 0,
    });
    setFacilityEnergy([
      ...addFacilityEnergy,
      {
        ...addDataObject,
        EnergyType: energyType?.[defaultEnergyType]?.text || "",
        Percentage: Number(data?.Percentage) ?? 0,
        Price: Number(data?.Price) ?? 0,
      },
    ]);
    resetFacilityEnergys();
  };

  //add product Heat data
  const addHeatData = (data: any) => {
    setAddHeatDataObject({
      ...addHeatDataObject,
      HeatSource: heatSource?.[defaultHeatType]?.text || "",
      Percentage: Number(data?.Percentage) ?? 0,
      Price: Number(data?.Price) ?? 0,
      Volumes: Number(data?.Volumes) ?? 0,
      Year: Number(data?.Year) ?? 0,
    });
    setFacilityHeat([
      ...addFacilityHeat,
      {
        ...addHeatDataObject,
        HeatSource: heatSource?.[defaultHeatType]?.text || "",
        Percentage: Number(data?.Percentage) ?? 0,
        Price: Number(data?.Price) ?? 0,
        Volumes: Number(data?.Volumes) ?? 0,
        Year: Number(data?.Year) ?? 0,
      },
    ]);
    resetFacilityHeat();
  };

  //add product Heat data
  const addHWaterData = (data: any) => {
    setAddWaterDataObject({
      ...addHeatDataObject,
      WaterSource: waterSource?.[defaultHeatType]?.text || "",
      Price: Number(data?.Price) ?? 0,
      Volumes: Number(data?.Volumes) ?? 0,
      Year: Number(data?.Year) ?? 0,
    });
    setFacilityWater([
      ...addFacilityWater,
      {
        ...addWaterDataObject,
        WaterSource: waterSource?.[defaultHeatType]?.text || "",
        Price: Number(data?.Price) ?? 0,
        Volumes: Number(data?.Volumes) ?? 0,
        Year: Number(data?.Year) ?? 0,
      },
    ]);
    resetFacilityWater();
  };

  //save product data
  const onSubmit = async (data: any) => {
    if (!checkFacilitySaveCondition(data)) {
      let {
        facilityName,
        facilityAddress,
        facilityCity,
        facilityType = defaultFacilityType,
      } = data;
      let facilityData = {
        facilityName,
        facilityAddress,
        facilityCity,
        facilityCountry: country,
        facilityType,
        energy: addFacilityEnergy,
        heatSource: addFacilityHeat,
        waterSource: addFacilityWater,
      };

      mutate(facilityData, {
        onSuccess: () => {
          successMessage("Facility created successfully");
          closeModalStatus();
        },
      });
    }
  };

  //loading view
  if (
    isLoading ||
    (status == "loading" && fetchStatus == "fetching") ||
    (heatSourceStatus == "loading" && fetchHeatSource == "fetching") ||
    (waterSourceStatus == "loading" && fetchWaterSource == "fetching")
  ) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  return (
    <>
      <CommonModal
        modalOpenState={visibleModal}
        modalCloseState={() => {
          closeModalStatus();
          reset();
        }}
        centered={true}
        customDimmer="customDimmerProduct"
        title="Add New Facility"
      >
        <Grid>
          <Grid.Column computer={16} tablet={16} mobile={16}>
            <>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div
                  style={{
                    height: height * 0.9 - 180,
                    overflowX: "hidden",
                  }}
                >
                  <FacilityInfo
                    register={register}
                    errors={errors}
                    country={country}
                    countries={countries}
                    setFacilityValue={setValue}
                    setCountry={setCountry}
                    isCountriesDataLoading={isCountriesDataLoading}
                    FACILITY_TYPES={FACILITY_TYPES}
                    defaultFacilityType={defaultFacilityType}
                    setDefaultType={setDefaultType}
                  />
                  <FacilityEnergy
                    energyType={energyType}
                    defaultEnergyType={defaultEnergyType}
                    setDefaultEnergyType={setDefaultEnergyType}
                    errorsFacilityEnergy={errorsFacilityEnergy}
                    handleSubmitFacilityEnergy={handleSubmitFacilityEnergy}
                    addFacilityEnergyData={addEnergyData}
                    addFacilityEnergy={addFacilityEnergy}
                    currentFacilityData={addFacilityEnergy || []}
                    setDeleteData={removeDataHandler}
                    setVisibleDeleteModal={false}
                    control={control}
                  />
                  <FacilityHeatSource
                    heatType={heatSource}
                    defaultHeatType={defaultHeatType}
                    setDefaultHeatType={setDefaultHeatType}
                    errorsFacilityHeat={errorsFacilityHeat}
                    handleSubmitFacilityHeat={handleSubmitFacilityHeat}
                    addFacilityHeatData={addHeatData}
                    addFacilityHeat={addFacilityHeat}
                    currentFacilityHeatData={addFacilityHeat || []}
                    setDeleteHeatData={removeHeatDataHandler}
                    setVisibleDeleteHeatModal={false}
                    control={controlHeat}
                  />
                  <FacilityWaterSource
                    waterType={waterSource}
                    defaultWaterType={defaultWaterType}
                    setDefaultWaterType={setDefaultWaterType}
                    errorsFacilityWater={errorsFacilityWater}
                    handleSubmitFacilityWater={handleSubmitFacilityWater}
                    addFacilityWaterData={addHWaterData}
                    addFacilityWater={addFacilityWater}
                    currentFacilityWaterData={addFacilityWater || []}
                    setDeleteWaterData={removeWaterDataHandler}
                    setVisibleDeleteWaterModal={false}
                    control={controlWater}
                  />
                </div>

                <div
                  style={{
                    height: height * 0.1,
                  }}
                  className="modalRegisterButtonMain"
                >
                  <div className="modalRegisterFlex">
                    <CustomButton
                      theme="green"
                      title={"Cancel"}
                      customColumnStyle={"cardInsertSaveOther"}
                      buttonOutLine={true}
                      onClick={() => {
                        closeModalStatus();
                        setCountry("");
                        reset();
                      }}
                    />
                  </div>
                  <div className="modalRegisterButtonLeft">
                    <CustomButton
                      theme="green"
                      title={"Save Facility"}
                      customColumnStyle={"cardInsertSaveOther"}
                      buttonOutLine={true}
                      onClick={() => {
                        const values = getValues();
                        setValue("enableNavigate", false);
                        onSubmit(values);
                      }}
                    />
                  </div>
                </div>
              </form>
            </>
          </Grid.Column>
        </Grid>
      </CommonModal>
    </>
  );
};

export default FacilityRegisterModal;
