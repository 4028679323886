import { useEffect, useState } from "react";

import { TitleBar } from "../../components/titleBar/Titlebar";
import "./productSimulation.scss";
import { Dropdown, Grid } from "semantic-ui-react";
// import ImpactCard from "./component/impactCard";
import PackagingSimulationTable from "./component/simulationTable";
import CompareSimulationModal from "./component/compareSimulationModal";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetProductSummary } from "../../api/productSummary";
import {
  useDeleteProductSimulation,
  useGetImpactCategory,
  useGetProductPayload,
  useGetProductSimulations,
} from "../../api/productSimulation";
import { useGetPackagingData as useGetFgPackagingData } from "../../api/farmGate/packaging";
import {
  useGetPackagingTypes,
  useGetPackagingData as useGetPgPackagingData,
} from "../../api/processGate/packaging";
import { useGetWasteManagementWasteTypes } from "../../api/static-data";
import { PACKAGING_LEVELS } from "../../config/constants";
import ConfirmModal from "../../components/confirmViewModal/ConfirmModal";

const IMPACT_CATEGORY: ImpactCategory[] = [
  { key: "1", text: "Packaging", value: "packaging" },
];

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ProductSimulation = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [simulationData, setSimulationData] = useState<SimulationData[]>([]);
  const [salesUnitId, setSalesUnitId] = useState("");
  const [productId, setProductId] = useState("");
  const [impactCategory, setImpactCategory] = useState<string>();
  const [category, setCategory] = useState<ImpactCategory>();
  const [fgPackagingId, setFgPackagingId] = useState<string>();
  const [pgPackagingId, setPgPackagingId] = useState<string>();
  const [packagingData, setPackagingData] = useState<any>({});
  const [gateType, setGateType] = useState<string>("");
  const [editedData, setEditedData] = useState<any>();
  const [editSimulationData, setEditSimulationData] =
    useState<SimulationData>();
  const [isView, setIsView] = useState(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [selectedSimulation, setSelectedSimulation] =
    useState<SimulationData>();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalSimulations, setTotalSimulations] = useState(0);

  // Query params
  const query = useQuery();

  // History
  const navigate = useNavigate();

  const { data: productSummary, isLoading: isProductSummaryLoading } =
    useGetProductSummary({
      productId,
      salesUnitId,
    });
  const { data: categories, isLoading: isCategoryLoading } =
    useGetImpactCategory({
      categoryName: impactCategory,
      salesUnitId,
    });

  const { data: productPayload, isLoading: isProductPayloadLoading } =
    useGetProductPayload({
      salesUnitId,
    });

  const {
    data: packagingMaterials,
    isLoading: isGetPackagingMaterialsLoading,
  } = useGetPackagingTypes();

  const { data: wasteManagements, isLoading: isGetWasteManagementWasteTypes } =
    useGetWasteManagementWasteTypes();

  const {
    data: productSimulations,
    isFetching: isGetProductSimulationsLoading,
    refetch,
  } = useGetProductSimulations({
    pageNumber,
    pageSize,
    productId,
    salesUnitId,
  });

  useEffect(() => {
    refetch();
  }, [pageNumber, pageSize]);

  const {
    mutate: deleteProductSimulation,
    isLoading: isLoadingDeleteProductSimulation,
  } = useDeleteProductSimulation({
    onError: () => {},
    onSuccess: () => {
      setVisibleDeleteModal(false);
      refetch();
    },
  });

  const { data: fgPackaging } = useGetFgPackagingData(fgPackagingId);
  const { data: pgPackaging } = useGetPgPackagingData(pgPackagingId);

  useEffect(() => {
    if (category?.type === "farmGate") {
      setPackagingData(fgPackaging);
    } else if (category?.type === "processGate") {
      setPackagingData(pgPackaging);
    }
  }, [fgPackaging, pgPackaging]);

  useEffect(() => {
    if (!modalOpen) {
      refetch();
    }
  }, [modalOpen]);

  useEffect(() => {
    const salesUnitId = query.get("salesUnitId");
    const productId = query.get("productId");
    if (salesUnitId && productId) {
      setSalesUnitId(salesUnitId);
      setProductId(productId);
    }
  }, []);

  useEffect(() => {
    if (salesUnitId && productId) {
      navigate(
        `/packaging-simulation?salesUnitId=${salesUnitId}&productId=${productId}`
      );
    }
  }, [salesUnitId, productId]);

  useEffect(() => {
    if (productSimulations) {
      setSimulationData(productSimulations.result || []);
      setPageNumber(productSimulations.page);
      setPageSize(productSimulations.pageSize);
      setTotalSimulations(productSimulations.total);
    }
  }, [productSimulations]);

  // const currentImpact = productSummary?.totalImpact.find(
  //   (item: any) => item.key === "climateChange"
  // )?.measure;

  const onEditSimulation = (simulation: SimulationData, isView?: boolean) => {
    if (!simulation) return;
    if (isView) {
      setIsView(true);
    }
    setEditSimulationData(simulation);
    setGateType(simulation.gateType);
    setEditedData(simulation.editedData);
    setImpactCategory(simulation.category?.toLowerCase());
    const packagingId = simulation?.editedData?._id;
    setCategory({
      key: packagingId,
      text: packagingId,
      value: packagingId,
      type: simulation.gateType,
    });
    if (simulation.gateType === "farmGate") {
      setPgPackagingId("");
      setFgPackagingId(packagingId);
    } else if (simulation.gateType === "processGate") {
      setFgPackagingId("");
      setPgPackagingId(packagingId);
    }
    setModalOpen(true);
  };

  const clearData = () => {
    setEditSimulationData(undefined);
    setEditedData(undefined);
    setPackagingData(undefined);
    setGateType("");
    setPgPackagingId(undefined);
    setFgPackagingId(undefined);
    setIsView(false);
    setSelectedSimulation(undefined);
  };

  const isLoading = Boolean(
    impactCategory &&
      (isCategoryLoading ||
        isProductPayloadLoading ||
        isProductSummaryLoading ||
        isGetPackagingMaterialsLoading ||
        isGetProductSimulationsLoading ||
        isGetWasteManagementWasteTypes ||
        isLoadingDeleteProductSimulation)
  );

  return (
    <div>
      <TitleBar
        enableTitle={true}
        titleName={
          (productPayload &&
            `${productPayload?.productName} - ${productPayload?.salesUnitName}`) ||
          ""
        }
        enableCustomButton
        buttonSelectView={false}
        customPageButtonContainer="packagingSimulationButtonContainer"
      />
      <Grid stackable>
        <Grid.Row>
          <Grid.Column width={10}>
            <Grid stackable>
              {/* <Grid.Row columns={3}>
                <Grid.Column>
                  <ImpactCard
                    title="Total Impact Organization"
                    subTitle="kg CO2 eq"
                    currentImpact={currentImpact}
                    simulatedImpact={210000}
                    impactReduction={16}
                    costSavings={16}
                  />
                </Grid.Column>
                <Grid.Column>
                  <ImpactCard
                    title="Total Impact Organization"
                    subTitle="kg CO2 eq"
                    currentImpact={80000}
                    simulatedImpact={40000}
                    impactReduction={50}
                    costSavings={-50}
                  />
                </Grid.Column>
                <Grid.Column>
                  <ImpactCard
                    title="Total Impact Organization"
                    subTitle="kg CO2 eq"
                    currentImpact={0.8}
                    simulatedImpact={0.1}
                    impactReduction={-50}
                    costSavings={-50}
                  />
                </Grid.Column>
              </Grid.Row> */}
              <Grid.Row className="background-white">
                <Grid.Column width={6}>
                  <Dropdown
                    placeholder="Select Impact Category"
                    fluid
                    selection
                    options={IMPACT_CATEGORY}
                    defaultValue={impactCategory}
                    value={impactCategory}
                    onChange={(e, data: any) => {
                      setImpactCategory(data.value);
                    }}
                    loading={isLoading}
                  />
                </Grid.Column>
                <Grid.Column width={6}>
                  <Dropdown
                    placeholder="Select category name"
                    fluid
                    selection
                    options={categories || []}
                    value={category?.value}
                    onChange={(e, data: any) => {
                      const selectedObject = categories?.find(
                        (category) => category.value === data.value
                      );
                      setCategory(selectedObject);
                    }}
                    loading={isLoading}
                  />
                </Grid.Column>
                <Grid.Column width={4}>
                  <button
                    className={
                      impactCategory && category
                        ? "customButtonViewSelect customButtonViewTitle border-none"
                        : "disable-button"
                    }
                    disabled={!impactCategory || !category}
                    onClick={() => {
                      setEditedData(undefined);
                      if (category?.type === "farmGate") {
                        setFgPackagingId(category.value);
                        setPgPackagingId("");
                        setGateType("farmGate");
                      } else if (category?.type === "processGate") {
                        setPgPackagingId(category.value);
                        setFgPackagingId("");
                        setGateType("processGate");
                      }
                      setModalOpen(true);
                    }}
                  >
                    <p className="customButtonTextSelect">Go to Simulation</p>
                  </button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
          <Grid.Column className="impact-flow" width={6}>
            {/* <Segment className="impact-flow-content"></Segment> */}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <PackagingSimulationTable
        data={simulationData}
        onEdit={onEditSimulation}
        onDelete={(simulation: SimulationData) => {
          setSelectedSimulation(simulation);
          setVisibleDeleteModal(true);
        }}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        pageNumber={pageNumber}
        pageSize={pageSize}
        totalSimulations={totalSimulations}
        isGetProductSimulationsLoading={isGetProductSimulationsLoading}
      />
      <CompareSimulationModal
        modalOpen={modalOpen}
        onClose={() => {
          setModalOpen(false);
          clearData();
        }}
        simulatedImpact={210000}
        impactReduction={50}
        costSavings={10}
        packagingData={packagingData}
        productSummary={productSummary}
        productPayload={productPayload}
        gateType={gateType}
        packagingMaterials={packagingMaterials}
        packagingLevels={PACKAGING_LEVELS}
        wasteManagements={wasteManagements}
        editedData={editedData}
        editSimulationData={editSimulationData}
        isView={isView}
      />
      <ConfirmModal
        viewModal={visibleDeleteModal}
        closeModal={() => setVisibleDeleteModal(false)}
        cancel={() => {
          setVisibleDeleteModal(false);
        }}
        approve={() => {
          deleteProductSimulation(selectedSimulation);
        }}
        title="Delete Simulation Data"
        subTitle="Are you sure you want to delete this simulation data?"
      />
    </div>
  );
};

export default ProductSimulation;
