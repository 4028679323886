import { useEffect, useState } from "react";
import { Dimmer, Grid, Loader, Message, Table } from "semantic-ui-react";
import { useLocation, useNavigate } from 'react-router-dom';

import {
  CommonTable,
  DropDown,
  ImageView,
  TitleBar,
  TitleView,
} from "../../components";
import ProgressBar from "../../components/progressBar/ProgressBar";
import productQR from "../../assets/images/productQR.png";
import "./productSummary.scss";
import {
  ProductSummaryTitle,
  ProductSummaryTableCol,
  COMPANY_NAME,
} from "../../config/constants";
import ProductSummaryDetailModal from "./productSummaryDetails/ProductSummaryDetailsModal";
import { useGetProductSummary } from "../../api/productSummary";
import { useGetProductSalesUnits } from "../../api/product";
import { humanize } from "../../utils/utils";
import { images } from "../../assets/images";
import ProductSummaryDetailHistory from "./productSummaryHistory/ProductSummaryDetailHistory";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ProductSummary = () => {
  // State variables
  const [viewProductSummaryDetailsModal, setProductSummaryDetailsModal] =
    useState(false);
  const [productSummaryHistoryModal, setProductSummaryHistoryModal] =
    useState(false);
  const [salesUnitId, setSalesUnitId] = useState<string>();
  const [productId, setProductId] = useState<string>();
  const [searchKey, setSearchKey] = useState("");
  const [productSummaryErrors, setProductSummaryErrors] = useState<string[]>([]);
  const [productSummaryWarnings, setProductSummaryWarnings] = useState<string[]>([])

  // Query params
  const query = useQuery();

  // History
  const navigate = useNavigate();

  // Custom hooks
  const { data: productSalesUnitsData, isLoading: isProductSalesUnitsLoading } =
    useGetProductSalesUnits({ searchKey });

  const { data: productSummary, isLoading: isProductSummaryLoading } =
    useGetProductSummary({
      productId,
      salesUnitId,
    });

  useEffect(() => {
    const salesUnitId = query.get("salesUnitId");
    const productId = query.get("productId");
    if (salesUnitId && productId) {
      setSalesUnitId(salesUnitId);
      setProductId(productId);
    }
  }, []);

  useEffect(() => {
    if (salesUnitId && productId) {
      navigate(
        `/product-summary?salesUnitId=${salesUnitId}&productId=${productId}`
      );
    }
  }, [salesUnitId, productId]);

  // Effect to set validation messages
  useEffect(() => {
    if (productSummary?.errors) {
      setProductSummaryErrors(productSummary.errors);
    }
    if (productSummary?.warnings) {
      setProductSummaryWarnings(productSummary.warnings);
    }
  }, [productSummary]);

  // Loading state check
  if (isProductSummaryLoading) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  // Data for dropdown
  const productSalesUnits: DropdownOption[] = (productSalesUnitsData || []).map(
    ({ productName, salesUnitName, salesUnitId }) => ({
      key: salesUnitId,
      text: `${productName} - ${salesUnitName}`,
      value: salesUnitId,
    })
  );

  // Check if there's no product summary data
  if (!productSummary) {
    return (
      <>
        <DropDown
          labelName="Product Name"
          placeholder="Select Product"
          currentData={productSalesUnits}
          defaultValue={salesUnitId}
          handleChangeState={(e, data) => {
            setSalesUnitId(data.value as string);
            const productId = productSalesUnitsData?.find(
              (productSalesUnit) => productSalesUnit.salesUnitId === data.value
            )?.productId;
            setProductId(productId);
          }}
          onSearchChange={(e, data) => {
            setSearchKey(data.searchQuery);
          }}
          loading={isProductSalesUnitsLoading}
          search
        />
        <h1>Please select your product.</h1>
      </>
    );
  }

  // Destructure product summary data
  const {
    product_name: productName,
    registration_number: registrationNumber,
    product_type: productType,
    product_image_path: productImage,
  } = productSummary?.productDetail || {};

  const { measure: climateChangeMeasure, unit: climateChangeUnit } =
    productSummary?.climateChange || {};

  const cycleStageData: any = productSummary?.cycleStages || {};

  // Load cycle stages
  const cycleStages = Object.entries(cycleStageData).map(([key, value]) => ({
    key,
    value,
  }));

  const totalImpact = productSummary?.totalImpact || [];

  // Load table data
  const loadTableData = () => {
    return totalImpact.map((data: any, index: any) => {
      return (
        <Table.Row className="tbleR" key={index}>
          <Table.Cell>
            <p>{data.indicator}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data.measure}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data.unit}</p>
          </Table.Cell>
          <Table.Cell>
            <Grid>
              <Grid.Column
                computer={16}
                tablet={16}
                mobile={16}
                key={index}
                className="alignRight addViewDetails"
              ></Grid.Column>
            </Grid>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  return (
    <>
      <DropDown
        labelName="Product Name"
        placeholder="Select Product"
        currentData={productSalesUnits}
        defaultValue={salesUnitId}
        handleChangeState={(e, data) => {
          setSalesUnitId(data.value as string);
          const productId = productSalesUnitsData?.find(
            (productSalesUnit) => productSalesUnit.salesUnitId === data.value
          )?.productId;
          setProductId(productId);

        }}
        onSearchChange={(e, data) => {
          setSearchKey(data.searchQuery);
        }}
        loading={isProductSalesUnitsLoading}
        search
      />
      {productSummaryErrors.length > 0 && (
        <Message
          color="red"
          onDismiss={() => {
            setProductSummaryErrors([]);
          }}
        >
          <Message.Header>Errors</Message.Header>
          <Message.List>
            {productSummaryErrors.map((message, index) => (
              <Message.Item key={index}>{message}</Message.Item>
            ))}
          </Message.List>
        </Message>
      )}
      {productSummaryWarnings.length > 0 && (
        <Message
          color="yellow"
          onDismiss={() => {
            setProductSummaryWarnings([]);
          }}
        >
          <Message.Header>Warnings</Message.Header>
          <Message.List>
            {productSummaryWarnings.map((message, index) => (
              <Message.Item key={index}>{message}</Message.Item>
            ))}
          </Message.List>
        </Message>
      )}
      {/* disabling custom button til the end of development */}
      <TitleBar
        enableTitle={true}
        titleName="Product Summary"
        customButtonData={ProductSummaryTitle}
        enableCustomButton
        customPageButtonContainer="productSummaryPageCustomButton"
        customButtonMain="productSummaryButtonOther"
        buttonSelectView={false}
        customButtonAction={(index) => {
          if (index === 6) {
            navigate(`/packaging-simulation?salesUnitId=${salesUnitId}&productId=${productId}`)
          }
          if (index === 7) setProductSummaryHistoryModal(true);
        }}
      />

      <Grid className="backgroundMain productProgressMain">
        <Grid.Column computer={6} tablet={16} mobile={16}>
          <Grid>
            <Grid.Column
              computer={8}
              tablet={16}
              mobile={16}
              className="productDetailsView"
            >
              <div>
                <ImageView
                  addRandomId={false}
                  src={productImage || images.notFound}
                  height={150}
                />
                <div className="productDetailsViewOther">
                  <p className="productNameDetails">{productName}</p>
                  <p className="productCodeDetails">
                    Code:{" "}
                    <span className="productCodeNumberView">
                      {registrationNumber}
                    </span>{" "}
                  </p>
                  <p className="productOtherDetails">{productType}</p>
                </div>
                <div className="productQrDetails">
                  <ImageView addRandomId={false} src={productQR} />
                </div>
              </div>
            </Grid.Column>
            <Grid.Column computer={8} tablet={16} mobile={16}>
              <Grid className="backgroundMain">
                <Grid.Column computer={16} tablet={16} mobile={16}>
                  <TitleView title="Climate change" />
                  <p className="viewNumberOfClimate">
                    {climateChangeMeasure?.toFixed(2)}
                  </p>
                  <p className="viewNumberOfClimateSub">{climateChangeUnit+" per kg"}</p>
                  <div className="climateDetailsView">
                    {COMPANY_NAME}´s third-party validated impact assessment
                    methodology is based on the Environmental Footprint (EF3.0)
                    method that enables PEF and OEF, endorsed by the European
                    Commission.
                  </div>
                  <h5 className="viewBottomClimateDetails" hidden>
                    DQR : 3.61
                  </h5>
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid>
        </Grid.Column>
        <Grid.Column computer={5} tablet={16} mobile={16}>
          <Grid className="backgroundMain">
            <Grid.Column computer={16} tablet={16} mobile={16}>
              <TitleView title="Impact by life cycle stages" />
              {cycleStages.map((data, index) => {
                return (
                  <ProgressBar
                    key={index}
                    imageView={true}
                    // @ts-ignore
                    image={images[data.key]}
                    title={humanize(data.key)}
                    count={data.value === "N/A" ? data.value : `${data.value}%`}
                    percentage={
                      data.value === "N/A" ? 0 : (data.value as number)
                    }
                  />
                );
              })}
            </Grid.Column>
          </Grid>
        </Grid.Column>
      </Grid>
      <CommonTable tableHeaderData={ProductSummaryTableCol}>
        {loadTableData()}
      </CommonTable>
      <ProductSummaryDetailModal
        visibleModal={viewProductSummaryDetailsModal}
        setVisibleModal={() => setProductSummaryDetailsModal(false)}
      />
      <ProductSummaryDetailHistory
        visibleModal={productSummaryHistoryModal}
        salesUnitId={salesUnitId}
        productId={productId}
        setVisibleModal={() => setProductSummaryHistoryModal(false)}
      />
    </>
  );
};

export default ProductSummary;
